.event-card{
    .card{
        border-radius: 20px !important;
        box-shadow: 0px 4px 14px rgba(148, 148, 148, 0.1);
        border: none;
        min-height: 520px;
        padding-bottom: 70px;
        // .progress-bar-container{
        //     .progress{
        //         height: 5px;
        //         margin-bottom: 10px;
        //     }
        // }
        .video-container-inner{
            img{
                border-radius: 14px;
                // margin: 15px;
                width: 100%;
                height: auto;
            }
            .eventCardImage {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            padding: 10px;
            position: relative;
            max-height: 240px;
            overflow-y: hidden;

            .tags-likes{
                position: absolute;
                top:10px;
                width: calc(100% - 40px);
                // padding: 10px;
                margin: 10px;
                .tags-row{
                    position: absolute;
                    top: 0px;
                    width: 100%;
                }
                .fav-container{
                    width:50px;
                    height: 50px;
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    top: -5px;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 100%;
                        height: 23px;
                        cursor: pointer;
                    }
                }
            }
        }
        .card-title{
            margin-bottom: 0;
            color: var(--primary-clr);
            font-size: var(--font-20);
            font-family: var(--font-sbold);
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            margin-right: .5rem;
            min-height: 48px;
        }
        .card-body+.card-body{
            padding-top: 0.25rem;
        }
        .card-body{
            .like
            {
                min-width: 60px;
                display: flex;
                align-items: center;
                img{
                    margin-right: 5px;
                    cursor: pointer;
                    margin-top: -10px;
                }
            }
            .event-time{
                font-size: var(--font-14);
                font-family: var(-font-sbold);
                color: --secondary-clr;
                color: var(--secondary-clr);
            }
            .card-text{
                p{
                    margin-bottom: 0;
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                }
            }
            .live-class-card-footer{
                bottom: 20px;
                left:16px;
                position: absolute;
            }
        }
    }

    .eventCard {
        overflow: hidden;
        height: 105px;
        margin-bottom: 0;
    }
}

