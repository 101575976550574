.iWantItAll {
  padding: 30px 30px 0;
}
.iWantItAllContainer {
  background: #30426d;
  border-radius: 30px;
  padding: 54px 146px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.iWantItAllLogo {
  max-width: 264px;

  a {
    margin-top: 26px;
    background-color: #51ccb8 !important;
  }
}

.iWantItAllText {
  flex-grow: 1;
  margin-left: 146px;

  h2 {
    margin: 0;
    padding: 0;
    font-family: var(--font-base-bold);
    font-style: normal;
    font-size: 50px;
    line-height: 58px;
    color: #fff;
    text-align: left;
  }
}

.iWantItAllTextNumberList {
  list-style: none;
  counter-reset: li;
  color: #fff;
  padding-left: 0;
  margin-bottom: 0;

  li:before {
    content: counter(li) ".";
    display: block;
    color: #4cc3b0;
    margin-right: 20px;
    width: fit-content;
    font-family: var(--font-base-bold);
    font-size: 24px;
  }
  li {
    display: flex;
    counter-increment: li;
    font-family: "Isidora Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #fff;
    margin: 0 0 16px;
  }

  strong {
    font-family: var(--font-base-bold);
  }
}

.iWantItAllSVG {
  position: relative;
  top: -30px;
}

.iWantItAllCurlyLine {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 278px;
}

@media (max-width: 1199.98px) {
  .iWantItAllContainer {
    flex-direction: column;
    padding: 60px;
  }
  .iWantItAllText {
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
    h2 {
      font-size: 30px;
      line-height: 38px;
      text-align: center;
    }
  }
  .iWantItAllTextNumberList {
    text-align: left;
  }

  .iWantItAllSVG {
    width: 130px;
    top: -17px;
  }
  .iWantItAllCurlyLine {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .iWantItAllLogo {
    text-align: center;
    img {
      width: 100%;
      max-width: 201px;
    }
  }
  .iWantItAll {
    padding: 16px 16px 0;
  }
  .iWantItAllContainer {
    padding: 20px;
  }
}
.iWantItAllTextNumberList {
  text-align: left;
}
