.magnetScreensWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: var(--base-font-family);
  max-width: 1440px;
  margin: 0 auto;
}

.magnetScreensHeader {
  min-height: 167px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.magnetScreensMain {
  flex-grow: 1;
}

.magnetScreensFooter {
  padding: 0 25px 25px;

  &__inner {
    background-color: var(--teel);
    padding: 56px 70px 80px;
    border-radius: 19.2521px;
    color: var(--white);
  }

  &__logo {
    margin-right: 25px;
  }

  &__top {
    display: flex;
    flex-grow: 1;
    align-items: center;
    border-bottom: 1px solid var(--white);
    padding-bottom: 12px;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    flex-grow: 1;
  }

  &__social {
    a {
      text-decoration: none;
      margin-left: 26px;
    }
    img {
      width: 50px;
    }
  }

  &__bottom {
    display: flex;
    padding-top: 33px;
  }

  &__copyright {
    flex-grow: 1;
    font-weight: 500;
    font-size: 37px;
    line-height: 40px;
    color: var(--white);
  }

  &__nurturebynaps {
    font-weight: 700;
    font-size: 33.7px;
    line-height: 40px;
    text-decoration: none;
    color: var(--white);
    margin-left: 16px;

    &:hover {
      color: var(--white);
    }
  }
}

@media (max-width: 991.98px) {
  .magnetScreensHeader {
    min-height: 75px;
  }
  .magnetScreensLogo {
    max-width: 99.5px;
    width: 100%;
  }

  .magnetScreensFooter {
    padding: 0;

    &__inner {
      border-radius: 0;
      padding: 35px 39px 28px;
    }

    &__logo {
      width: 85px;
      height: 25px;
      margin-right: 8px;
    }

    &__title {
      font-size: 10px;
      line-height: 12px;
      flex-shrink: 0;
    }

    &__social {
      flex-shrink: 0;
      a {
        text-decoration: none;
        margin-left: 13px;
      }
      img {
        width: 25px;
      }
    }

    &__bottom {
      display: flex;
      padding-top: 9.7px;
    }

    &__copyright {
      flex-grow: 1;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }

    &__nurturebynaps {
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
    }
  }
}

@media (max-width: 575.98px) {
  .magnetScreensFooter {
    &__inner {
      padding: 35px 16px 28px;
    }
  }
}
