@import "../../../global.css";
@import "../../../global-componant.css";
.fav-course-container {
    padding-left: 0;
    padding-right: 0;
    padding: 0 !important;
    .tab_course_content{
        .tab_main_courseContent{
            background: var(--bg-paleyellow-clr);
            h2{
                text-align: left;
            }
        }
    }

    .fav-icon-cont{
        background: #fff;
        border-radius: 50%;
        padding: 15px;
        box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.12);
        width:100px;
        height: 100px;
        img{
            width:50px;
            height: auto;
        }
    }

}

.all-course-container.css-13xfq8m-MuiTabPanel-root{
    padding: 0;
}