.pinkContentBox {
  background-image: url("../../Assets/img/contact-map-bg.svg");
  background-color: var(--pink);
  background-size: cover;
  border-radius: 14px;
  padding: 106px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pinkContentBoxReverse {
  flex-direction: row-reverse;
}

.pinkContentBox-img {
  width: 100%;
  max-width: 438px;
  max-height: 438px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 121px;
  margin-right: 64px;

  &_reverse {
    margin-right: 0;
    margin-left: 64px;
  }
}

.pinkContentBox-textWrapper {
  color: var(--white);
  max-width: 474px;
  width: 100%;
  h3 {
    font-family: "Isidora Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 50px;
  }
  p {
    margin-top: 30px;
    font-family: "Isidora Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
  }
}

@media (max-width: 1199.98px) {
  .pinkContentBox {
    padding: 74px 30px;
  }
}

@media (max-width: 991.98px) {
  .pinkContentBox-img {
    margin-right: 20px;

    &_reverse {
      margin-right: 0;
      margin-left: 20px;
    }
  }
  .pinkContentBox-img {
    max-width: 338px;
    max-height: 338px;
  }
}

@media (max-width: 767.98px) {
  .pinkContentBox {
    flex-direction: column;
    padding: 38px 30px;
  }
  .pinkContentBox-img {
    margin-right: 0;
    max-width: 438px;
    max-height: 438px;
    border-radius: 50px;

    &_reverse {
      margin-right: 0;
      margin-left: 0;
    }
  }
  .pinkContentBox-textWrapper {
    text-align: center;

    h3 {
      font-size: 34px;
      line-height: 42px;
      margin-top: 38px;
    }

    p {
      margin-top: 20px;
    }
  }
}
