.preLoginHero {
  padding-left: 100px;
  max-width: 1380px;
  margin: 0 auto;
  width: 100%;
  min-height: 639px;
  border-radius: 16px;
  background-image: url(../../assets/preLoginHero.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  .heading {
    max-width: 561px;
    font-family: "Isidora Sans bold";
    font-size: 50px !important;
    font-style: normal;
    line-height: 58px;
    color: #fff;
    text-align: left;
    margin: 0;
    padding: 0;
    padding-bottom: 15px;
  }

  p {
    font-family: "Isidora Sans";
    font-size: 26px;
    color: #fff;
    line-height: 44px;
  }

  span {
    font-family: var(--Respondent-Bold);
    font-size: 44px;
    line-height: 44px;
    color: #f5cf70;
  }

  .teal-btn-small {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  @media (max-width: 767.98px) {
    min-height: 500px;
    background-position: 90%;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    padding: 28px 0 41px;

    .preLoginHeroLogo {
      width: 100px;
    }
    .heading {
      font-size: 26px !important;
      line-height: 36px;
      text-align: center;
      max-width: 288px;
      padding-bottom: 5px;
    }
    p {
      font-size: 20px;
      max-width: 190px;
      line-height: 36px;
    }
    span {
      font-size: 36px;
      line-height: 36px;
    }
    .preLoginHeroMobileGrow {
      flex-grow: 1;
    }
  }
}
