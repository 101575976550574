
.login-inner{
    h1{
        padding-bottom: 20px;
    }
}

// .signup-popup{
//     margin: 9% auto;
    
   
// }
.sign-up-popup-sub-title {
    text-align: center;
    font-family: var(--base-font-family);
    font-size: 18px;
    margin: 6px 0 0;
    padding: 0;
    color: #30426d;
}
.sign-pop form{
    padding: 0px 25px;
}
.signup-poupmodal{
    .MuiPaper-rounded {
        border-radius: 16px;
        width: 720px;
        max-width: 100%;
    }
    .MuiTypography-root.MuiTypography-body1{
       
    }
    .MuiDialogContent-root{
        padding: 0;
        overflow-x: hidden;
    }
}


@media screen and (max-width: 767px) {
    .sign-pop form {
        padding: 0px 0;
    }
    h1.heading{
        font-size: 20px !important;
    }
    .checkbox-area label{
        font-size: 13px !important;
    }
}

.MuiInputBase-error-input input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-readOnly {
    border-color: red !important;
}