.chess-grid-promises {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--base-font-family);
  max-width: 774px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &:nth-child(2n + 1) {
    flex-direction: row-reverse;
  }
}

.chess-grid-promises-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  margin-top: 90px;

  .block {
    font-weight: bolder;
  }
}

.chess-grid-promises-point {
  font-size: 50px;
  font-family: var(--font-base-bold);
  font-weight: 500;
}

.chess-grid-promises-description {
  font-family: var(--font-base);
  font-size: 26px;
  line-height: 36px;
  color: #4D4D4D;
}

.chess-grid-promises-icon {
  width: 240px;
  height: 240px;
}

.chess-grid-promises-text {
  width: 100%;
  padding-left: 58px;
}

.chess-grid-promises {
  .chess-grid-promises-row:nth-child(even) {
    flex-direction: row-reverse;
    justify-content: flex-start;

    .chess-grid-promises-text {
      padding-left: 0;
      padding-right: 58px;
      text-align: end;
      max-width: 474px;
    }
  }
  .greenText .chess-grid-promises-point,
  .greenText span {
    color: var(--secondary-clr);
  }
  .redText .chess-grid-promises-point,
  .redText span {
    color: var(--monthly-clr);
  }
  .purpleText .chess-grid-promises-point,
  .purpleText span {
    color: var(--halfyearly-clr);
  }
  .yellowText .chess-grid-promises-point,
  .yellowText span {
    color: var(--orange);
  }
  .brownText .chess-grid-promises-point,
  .brownText span {
    color: var(--brown);
  }
}

@media (max-width: 767.98px) {
  .chess-grid-promises {
    .chess-grid-promises-row {
      flex-direction: column;
      margin-top: 60px;

      .chess-grid-promises-text {
        text-align: center;
        padding: 0;
      }
      .chess-grid-promises-description {
        margin-top: 10px;
        font-size: 16px;
        line-height: 24px;
        max-width: 223px;
        margin-right: auto;
        margin-left: auto;
      }
      .chess-grid-promises-point {
        font-size: 35px;
        margin-top: 20px;
      }
    }
    .chess-grid-promises-row:nth-child(even) {
      flex-direction: column;

      .chess-grid-promises-text {
        text-align: center;
        padding: 0;
      }
    }
  }
}
