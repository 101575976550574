.paymentModalPopUpOverflowBody {
  overflow: hidden;
}
.paymentModalPopUpWindow {
  padding: 0 !important;

  .modal-dialog {
    max-width: 680px !important;
  }

  .modal-content {
    border-radius: 14px;
    background-color: transparent;
  }
}

.paymentModalPopUp {
  padding: 36px 63px 36px 63px;
  box-shadow: 0px 15px 58px rgba(0, 0, 0, 0.13) !important;
  background-color: #fff;
  border-radius: 14px;
  border: none !important;

  @media (max-width: 575.98px) {
    padding: 36px 16px 16px;
  }
}

.paymentModalPopUpTitle {
  font-family: var(--font-base-family);
  color: #30426d;
  font-size: 28px;
  line-height: 36px;
  margin: 0;

  &--topSpace {
    margin-top: 74px;
  }
}

.paymentModalPopUpCardInfo {
  color: #30426d;
  font-family: var(--font-base-family);
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  margin: 16px 0 0;
  padding-bottom: 34px;
  border-bottom: 3px solid #4cc3b0;

  &--strong {
    font-family: var(--font-base-bold);
  }
}

.paymentModalPopUpAddCardForm {
  input {
    border-radius: 6px;
    color: #4d4d4d;
  }

  label {
    font-family: Isidora Sans;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    color: #4d4d4d;
    margin-bottom: 7px;
  }
}

.paymentModalPopUpButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
  padding: 0 13px;

  .paymentModalPopUpButton {
    max-width: 247px;

    &--skip {
      background-color: #4cc3b0 !important;
      border-color: #4cc3b0 !important;

      &:hover {
        color: #4cc3b0 !important;
        background-color: #fff !important;
      }
    }
  }

  @media (max-width: 767.98px) {
    flex-direction: column;
    margin-top: 16px;

    .paymentModalPopUpButton {
      max-width: 100%;
      margin-top: 16px;
    }
  }
}

.paymentModalPopUpCross {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  transition: 0.3s;
  height: 16px;
  &:hover {
      opacity: 0.5;
  }

  svg {
      vertical-align: top;
  }
}