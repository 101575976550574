#exampleModalToggle{
    position: relative;
    .modal-dialog{
        z-index: 9999;
        &::before{
            content:'';
            background: url(../../Assets/img/congratulation-bg.svg);
            width:100px;
            height: 100px;
            height: 100px;
            position: absolute;
            left: 50%;
            top: -50px;
        }
    }
}

.quick-modal{
    .img-container{
        .quick-img-main{
            border-radius: 15px;
            img{
                border-radius: 15px;
                overflow: hidden;
            }
        }
    }

    .quick-ask-modal{
        .quick-img-main{
           &::after{
            content:'';
            background: url('../../Assets/img/Prelogin/pre-baby.png') no-repeat;
            position: absolute;
            left: -57px;
            bottom: -10px;
            width: 110px;
            height: 192px;
            display: inline-block;
            background-size: contain;
           }
        }
    }

    .quick-profile-modal{
        .quick-img-main{
            &::after{
            content:'';
            background: url('../../Assets/img/Prelogin/new-mom.png') no-repeat;
            position: absolute;
            right: -60px;
            bottom: -10px;
            width: 135px;
            height: 135px;
            display: inline-block;
            background-size: contain;
            }
         }
    }

    
    .quick-course-modal{
        .quick-img-main{
            &::after{
                content:'';
                background: url('../../Assets/img/course-icon.png') no-repeat;
                position: absolute;
                left: -60px;
                bottom: -10px;
                width: 130px;
                height: 110px;
                display: inline-block;
                background-size: contain;
            }
         }
    }
    
    .quick-event-modal{
        .quick-img-main{
            &::after{
             content:'';
             background: url('../../Assets/img/hi-fi.png') no-repeat;
             position: absolute;
             right: -57px;
             bottom: -15px;
             width: 130px;
             height: 130px;
             display: inline-block;
             background-size: contain;
            }
         }
    }
}


// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {  }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {  }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {  }

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {  }

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .quick-modal{
        .img-container{
            .quick-img-main{
                margin: 15px;
            }
        }
        .quick-ask-modal{
            .quick-img-main{
               &::after{
                left: -15px;
                bottom: -70px;
               }
            }
        }

        .quick-profile-modal{
            .quick-img-main{
                &::after{
                    right: -40px;
                    bottom: -35px;
                    width: 135px;
                    height: 135px;
                }
            }
        }

        .quick-course-modal{
            .quick-img-main{
                &::after{
                    left: -25px;
                    bottom: -60px;
                }
            }
        }
        .quick-event-modal{
            .quick-img-main{
                &::after{
                    right: -15px;
                    bottom: -50px;       
                }
            }
        }
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 479.98px) { }

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 359.98px) { }