@import "../../global.css";
@import "../../global-componant.css";

.events-detail-container{
    .selected-event-fulldetail{
        .selected-event-video-sec{
            .live-event-footer{
               
                display: flex;
                align-items: center;
                .like{
                    cursor: pointer;
                }
            }
            iframe{
                width:100%;
                height: 360px;
            }
        }
        
        .selected-event-details{
            .event-title{
                font-family: var(--font-sbold);
                font-size: var(--font-h2-34);
                color: var(--primary-clr);
            }
            .live-event-desc-sche{
                background: url('../../Assets/img/liveevents/live-event-des-bg.svg') no-repeat;
                background-size: cover;
                margin-bottom: 15px;
                .event-time{
                    font-family: var(--font-sbold);
                    font-size: var(--base-font-family);
                    color: var(--secondary-clr);
                }
            }
        }
    }
    .recorder-session-ntf-block{
        background: var(--pastel-purple) url(../../Assets/img/liveevents/recorded-session-notify-bg.svg);
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        border-radius: 14px;
        .sec-caption{
            max-width: 600px;
            margin-right: auto;
            margin-left: auto;
            text-align: center;
            h2{
                color: var(--white);
                font-family: var(--base-font-family);
                font-size: var(--font-h2-34);
                margin-bottom: 15px;
                padding-bottom: 0;
            }
            p{
                color: var(--white);
            }
        }

    }

    .Upcoming-event-container{
        background: var(--secondary-clr) url(../../Assets/img/upcoming-events-bg.svg) no-repeat;
        background-position: right bottom;
        border-radius: 14px;
    }
    
    .get-readyf-baby-container{
        background: var(--bg-paleyellow-clr);
        // border-radius: 14px;
        h2{
            text-align: left;
            color: var(--primary-clr);
        }
    }
    .back-pg{
        .css-i4bv87-MuiSvgIcon-root{
            width: 1rem;
            height: 1rem;
            fill:var(--secondary-clr)
        }
        a{
            color: var(--secondary-clr);
            font-family: var(--font-sbold);
            font-size: var(--font-18);
        }
    }

}

@media (max-width: 1399.98px) {  }

@media (max-width: 1199.98px) {  }

@media (max-width: 991.98px) {
    .events-detail-container{
        .selected-event-fulldetail{
            .container{
                padding-left: 0;
                padding-right: 0;
                .selected-event-video-sec{
                    iframe {
                        width: 100% !important;
                        height:190px;
                    }
                }
            }
        }
        .recorder-session-ntf-block{
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: 140%;
        }
    }

   
}

@media (max-width: 767.98px) {
    .margineLR-0{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .paddingLR-0{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .events-detail-container{
        .selected-event-fulldetail{
            .container{
                padding-left: 0;
                padding-right: 0;
                .selected-event-video-sec{
                    iframe {
                        width: 100% !important;
                        height:192px;
                    }
                }
            }
        }
        .Upcoming-event-container{
            background-image: unset;
            border-radius: 0;
        }
    }

    
}

@media (max-width: 575.98px) {
  .events-detail-container{
    .selected-event-fulldetail{
        .container{
            padding-left: 0;
            padding-right: 0;
            .selected-event-video-sec{
                iframe {
                    width: 100% !important;
                    height: 212px;
                }
            }
        }
    }

    .get-readyf-baby-container{
        .container{
            padding-left: 0;
            padding-right: 0;
        }
    }

    .recorder-session-ntf-block{
        //  background: url(no);
        .d-flex.px-5{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    
    .recorder-session-ntf-block.px-5{
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
  }
 
}