.savedCardsField {
    width: 100%;
    background: var(--white);
    border: 1px solid var(--primary-border) !important;
    box-sizing: border-box;
    border-radius: 40px !important;
    height: 50px;
    font-family: var(--base-font-family);
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    align-items: center;
    color: var(--primary-text);
    padding: 0.375rem 1rem;
    appearance: none;
}

.savedCardsFieldDisabled {
    opacity: 0.4;
}

.savedCardsEmpty {
    display: flex;
    justify-content: center;
    margin-top: 14px;
}