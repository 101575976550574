@import "../../global.css";
@import "../../global-componant.css";

.back-btn {
    padding:30px 0px 0px 70px;
 
     button {
         width: 93px;
         height: 38px;
         background: #CAF0EA;
         border: 1px solid #50CCB8;
         border-radius: 41px;
         font-style: normal;
         font-weight: 600;
         font-size: 12px;
         line-height: 14px;
         align-items: center;
         text-align: center;
         letter-spacing: -0.02em;
         color: #50CCB8;
     }
 }
 .forget-passwordpage{
     .forget-head{
         font-family: 'Isidora Sans';
         font-style: normal;
         font-weight: 700;
         font-size: 42px;
         line-height: 56px;
         text-align: center;
         color: #4D4D4D;
         padding-bottom: 35px;
         margin: 0;
     }
     .reset-btn{
         margin-top: 20px;
     }
 }

 .forgot-password-page{
     background: url("../../Assets/img/login/forgot-password-bg.svg") no-repeat;
     background-position: center -75px;
     background-size: cover;
 }
 .mobileback-btn{
     display: none;
     font-style: normal;
     font-weight: bold;
     font-size: 14px;
     line-height: 18px;
     text-decoration-line: underline;
     color: #51CCB8;
     font-family: Isidora Sans;
 }
 
 @media screen and (max-width: 767px) {
     .forget-passwordpage .forget-head{
         font-size: 26px;
     }
     .mobileback-btn{
         display: block;
     }
     .webback-btn{
         display: none;
     }
     .back-btn{
         padding: 25px 0px 0px;
     }
     .set-pass.forget-head{
         padding-top: 20px;
         padding-bottom: 20px;
     }
     .forget-passwordpage .reset-btn{
         margin-bottom: 20px;
     }
 }
 @media (min-width: 200px) and (max-width: 320px) {
     .forget-passwordpage .forget-head{
         font-size: 30px;
     }
 }
 .newPass{
     padding: 0!important;
 }
 .forgot-password-center{
     text-align: center;
 }

 .sent-emailpage{
    height: calc(100vh - 75px);
    p{
        font-family: 'Isidora Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        color: #000000;
        margin: 0;
        padding-bottom: 40px;
    }
}
.pwd-text{
    font-size: 15px;
}