.question {
  position: relative;
  background-color: #fff;
  padding: 24px;
  border-radius: 30px;
  box-shadow: 0 4px 14px hsla(0, 0%, 58%, 0.1);
}

.questionRow {
  display: flex;
  align-items: center;
}

.questionUser {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary-clr);
  font-family: var(--font-sbold);
  font-size: 16px;
  line-height: 18px;
  max-width: 152px;
  width: 100%;
  text-align: center;
  flex-shrink: 0;
  align-self: flex-start;

  &__avatar {
    border-radius: 50px;
    width: 70px;
    height: 70px;
    overflow: hidden;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 2px solid #4cc3b0;
    margin-bottom: 8px;
  }
}

.questionMain {
  flex-grow: 1;
  padding: 0 12px;

  &__date {
    color: #949494;
    font-family: Isidora Sans semiBold;
    font-size: 12px;
  }

  &__message {
    color: var(--primary-clr);
    font-family: var(--base-font-family);
    font-size: 18px;
    line-height: 28px;
    margin-top: 4px;
    overflow: hidden;
    position: relative;
  }

  &__readMore {
    text-decoration: none;
    display: block;
    padding: 0px 10px 3px;
    background-color: #30426d;
    font-family: var(--font-sbold);
    font-size: 15px;
    color: #fff;
    border-radius: 50px;
    width: fit-content;
    transition: 0.3s;
    margin-top: 9px;

    &:hover {
      color: #fff;
      opacity: 0.5;
    }
  }
}

.questionControlsWrapper {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  flex-shrink: 0;
}

.questionControlsLike {
  display: flex;
  align-items: center;
  color: var(--primary-clr);
  font-family: var(--base-font-family);

  button {
    outline: 0;
    border: 0;
    background-color: transparent;
    transition: 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }
}

.questionControlsDelete {
  color: #4cc3b0;
  font-family: var(--base-font-family);
  text-decoration: underline;
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  transition: 0.3s;

  &:hover {
    opacity: 0.5;
  }
}

.questionMobile {
  display: none;
}

.questionTags {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  left: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 3px;

  &__tag {
    color: var(--white);
    padding: 3px 7px;
    border-radius: 3px;
    font-size: var(--font-14);
    font-family: var(--font-sbold);
    line-height: 17px;
    margin-right: 4px;
    white-space: nowrap;
  }
}

@media (max-width: 575.98px) {
  .question {
    padding: 16px 16px 8px 12px;
  }

  .questionUser {
    max-width: 100px;
    font-size: 13px;
    line-height: 15px;

    &__avatar {
      width: 50px;
      height: 50px;
      margin-bottom: 4px;
    }
  }

  .questionMain {
    &__date {
      font-size: 10px;
    }

    &__message {
      font-size: 14px;
      line-height: 18px;
    }

    &__readMore {
      font-size: 12px;
      padding: 0 12px 2px;
    }
  }

  .questionControlsWrapper {
    display: none;
  }

  .questionMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
  }
}
