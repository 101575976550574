@import "../../global.css";
.home{
    height: 100vh;
    // margin-top: 10%;
    // position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
h1{
    color: var(--primary-clr)
}

// .home-text{
//     position: absolute;
//     left: 50%;
//     top: 50%;
// }