.BHHeroWrapper,
.SCHeroWrapper {
  background-image: url(../../Assets/bhHero.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fbf8ef;
  display: flex;
  justify-content: flex-end;
  padding: 115px 51px 51px;
  position: relative;
}

.SCHeroWrapper {
  background-image: url(../../Assets/scHero.png);
}

.BHHeroContent {
  max-width: calc(646px + 131px);
  width: 100%;
  display: flex;
  flex-direction: column;

  &__bhlogo,
  &__scLogo {
    background-image: url(../../Assets/bhLogo.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 545px;
    width: 100%;
    aspect-ratio: calc(545 / 92);
  }
  &__scLogo {
    background-image: url(../../Assets/scMainLogo.svg);
    max-width: 342px;
    aspect-ratio: calc(204 / 47);
  }

  &__text {
    max-width: 646px;
    margin-top: 47px;
    font-family: "Isidora Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #30426d;
  }

  &__strong-text {
    font-family: var(--font-base-bold);
    font-size: 22px;
  }

  &__btn {
    width: fit-content;
    margin-top: 20px;
  }
}

.BHHeroCode {
  padding: 20px 54.5px;
  border-radius: 23px;
  background: #f5cf70;
  width: fit-content;
  align-self: flex-end;

  h2 {
    margin: 0;
    padding: 0;
    font-family: var(--font-base-bold);
    font-style: normal;
    font-size: 95px;
    line-height: 80px;
    color: #30426d;
  }

  p {
    font-family: "Isidora Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 48.542px;
    line-height: 115.47%;
    color: #30426d;
  }
  strong {
    font-family: var(--font-base-bold);
    text-transform: uppercase;
  }
}

@media screen and (max-width: 767px) {
  .BHHeroWrapper {
    background-position: bottom right;
    background-size: 115%;
    padding: 14px 36px 63px;
  }
  .SCHeroWrapper {
    background-position: top right;
    background-size: 100%;
    padding: 38px 36px 63px;
    background-position-y: -16%;
  }
  .BHHeroContent__scLogo {
    display: none;
  }
  .BHHeroContent__scLogoMobile {
    background-image: url(../../Assets/scMainLogo.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    // max-width: 191px;
    width: 100%;
    aspect-ratio: calc(191 / 88);
  }

  .BHHeroContent {
    text-align: center;
    align-items: center;

    &__text {
      margin-top: 53px;
      font-size: 14px;
      line-height: 23px;
      color: #30426d;
    }

    &__strong-text {
      font-family: var(--font-base-bold);
      line-height: 23px;
      font-size: 14px;
    }

    &__btn {
      display: none;
    }
  }

  .BHHeroCode {
    padding: 10px 20px;
    align-self: center;
    text-align: left;

    h2 {
      font-size: 46px;
      line-height: 40px;
    }

    p {
      font-size: 23px;
      line-height: 16px;
      margin-top: 10px;
    }
  }

  .BHHeroContentCheckOut {
    display: none;
  }
}
