@import "global.css";

body{
    font-family: var(--base-font-family);
}

input[type=text], input[type=password],input[type=email],input[type=phone],input[type=number], input[type=tel], textarea{
    background: var(--white);
    border: 1px solid var(--primary-border) !important;
    box-sizing: border-box;
    border-radius: 40px;
    height: 50px;
    font-family: var(--base-font-family);
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    align-items: center;
    color: var(--primary-text);
    padding: .375rem 1rem;
    -webkit-appearance: none;
    appearance: none;
}

textarea{
    min-height: 100px !important;
}
/* input[type=text].form-control, input[type=password].form-control,input[type=email].form-control,input[type=phone].form-control,input[type=number].form-control{
    border: 1px solid var(--primary-border) !important;
} */

select{
    background: var(--white);
    border: 1px solid var(--primary-border) !important;
    box-sizing: border-box;
    border-radius: 40px !important;
    height: 50px;
    font-family: var(--base-font-family);
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    align-items: center;
    color: var(--primary-text);
    padding: .375rem 1rem;
    -webkit-appearance: none;
    appearance: none;
}

input::placeholder{
    /* font-family: Isidora Sans; */
    font-style: normal;
    font-size: var(--font-16);
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #727272;
}

.error-input, input[type=number].error-input, input[type=text].error-input, input[type=password].error-input, input[type=email].error-input, input[type=phone].error-input, .input-errorborder input{
    border: 1px solid red !important;
}

label{
    font-family: var(--font-base-bold);
    color: var(--primary-clr);
    font-weight: var(--font-wt500);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    margin-bottom: 10px;
}

label > .fw-light{
    font-family: var(--base-font-family);
    font-weight: var(--font-wt300) !important;
}

input[type=password]{
    position: relative;
}

.btn:hover{
    border-color: unset;
    border: 0;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: inherit!important;
    background-color: unset !important;
    border-color: unset !important;
}

.primary-blue-btn{
    height: 50px;
    width: 100%;
    background:var(--primary-clr) !important;
    border-radius: var(--br-radius-40) !important;
    border: 2px solid var(--primary-clr);
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    letter-spacing: -0.02em;
    color: #FFFFFF !important;
    cursor: pointer;
    /* margin-bottom: 40px; */
}

.primary-blue-btn:hover{
    background:#fff !important;
    color: var(--primary-clr) !important;
    border: 2px solid var(--primary-clr);
}

.primary-blue-small-btn{
    height: 50px;
    padding: 15px 30px !important;
    background:var(--primary-clr) !important;
    border-radius: var(--br-radius-40) !important;
    border: 2px solid var(--primary-clr) !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 14px !important;
    align-items: center;
    color: #FFFFFF !important;
    min-width: 130px;
    text-decoration: none;
    cursor: pointer;
}

.primary-blue-small-btn:hover{
    background:#fff !important;
    color: var(--primary-clr) !important;
    border: 2px solid var(--primary-clr);
}

.primary-blue-small-btn-40{
    height: 40px;
    padding: 10px 30px !important;
    background:var(--primary-clr) !important;
    border-radius: var(--br-radius-40) !important;
    border: 2px solid var(--primary-clr) !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 14px !important;
    align-items: center;
    color: #FFFFFF !important;
    min-width: 148px;
    text-decoration: none;
    cursor: pointer;
}

.primary-blue-small-btn-40:hover{
    background:#fff !important;
    color: var(--primary-clr) !important;
    border: 2px solid var(--primary-clr);
    font-weight: 600 !important;
}

.primary-outline-btn{
    background:#fff !important;
    color: var(--primary-clr) !important;
    border: 2px solid var(--primary-clr) !important;
    height: 40px;
    font-family: var(--base-font-family);
    font-size: var(--font-16);
    line-height: 14px !important;
    align-items: center;
    padding: 10px 30px !important;
    color: var(--white);
    border-radius: var(--br-radius-40) !important;
    cursor: pointer;
    font-weight: 600 !important;
}

.primary-outline-btn:hover{
    background:var(--primary-clr) !important;
    color:var(--white) !important;
    border: 2px solid var(--primary-clr) !important;
}

.secondary-teal-btn{
    height: 50px;
    background:var(--secondary-clr) !important;
    border-radius: var(--br-radius-40) !important;
    border: 2px solid var(--secondary-clr) !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    letter-spacing: -0.02em;
    color: #FFFFFF !important;
    font-weight: 600 !important;
}

.secondary-teal-btn:hover{
    background:var(--white) !important;
    color: var(--secondary-clr) !important;
    border: 2px solid var(--secondary-clr) !important;
}

.secondary-teal-btn-small{
    height: 40px;
    background:var(--secondary-clr) !important;
    border-radius: var(--br-radius-40) !important;
    border: 2px solid var(--secondary-clr);
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 14px !important;
    align-items: center;
    letter-spacing: -0.02em;
    color: #FFFFFF !important;
    padding: .375rem .75rem;
}

.secondary-teal-btn-small:hover{
    background:var(--white) !important;
    color: var(--secondary-clr) !important;
    border: 2px solid var(--secondary-clr);
}

.btn-small{
    height: 40px;
    border-radius: var(--br-radius-40) !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px !important;
    align-items: center;
    letter-spacing: -0.02em;
    padding: .375rem .75rem !important;
}

.pink-btn-small{
    background:var(--monthly-clr) !important;
    border: 2px solid var(--monthly-clr);
    color: var(--white) !important;
}

.pink-btn-small:hover{
    background:var(--white) !important;
    /* border: 1px solid var(--monthly-clr); */
    color: var(--monthly-clr) !important;

}

.yellow-btn-small{
    background:var(--orange) !important;
    border: 2px solid var(--orange);
    color: var(--white) !important;
}

.yellow-btn-small:hover{
    background:var(--white) !important;
    /* border: 1px solid var(--orange); */
    color: var(--orange) !important;
}

.teal-btn-small{
    background:var(--secondary-clr) !important;
    border: 2px solid var(--secondary-clr);
    color: var(--white) !important;
}

.teal-btn-small:hover{
    background:var(--white) !important;
    /* border: 1px solid var(--secondary-clr); */
    color: var(--secondary-clr) !important;
}

.purple-btn-small{
    background:var(--halfyearly-clr) !important;
    border: 2px solid var(--halfyearly-clr);
    color: var(--white) !important;
}

.purple-btn-small:hover{
    background:var(--white) !important;
    /* border: 1px solid var(--secondary-clr); */
    color: var(--halfyearly-clr) !important;
}

.pink-btn-small-outline{
    background:transparent !important;
    border: 2px solid var(--monthly-clr);
    color: var(--monthly-clr) !important;
}

.pink-btn-small-outline:hover{
    background:var(--monthly-clr) !important;
    /* border: 1px solid var(--monthly-clr); */
    color: var(--white) !important;

}

.teal-btn-small-outline{
    background:transparent !important;
    border: 2px solid var(--secondary-clr) ;
    color: var(--secondary-clr) !important;
}

.teal-btn-small-outline:hover{
    background:var(--secondary-clr) !important;
    color: var(--white) !important;
}

.teal-btn-small-outline{
    background:transparent !important;
    border: 2px solid var(--secondary-clr) ;
    color: var(--secondary-clr) !important;
}

.teal-btn-small-outline:hover{
    background:var(--secondary-clr) !important;
    color: var(--white) !important;
}

.purple-btn-small-outline{
    background:transparent !important;
    border: 2px solid var(--halfyearly-clr) ;
    color: var(--halfyearly-clr) !important;
}

.purple-btn-small-outline:hover{
    background:var(--halfyearly-clr) !important;
    color: var(--white) !important;
}

.h50{
    height: 50px !important;
}


h1{
    /* font-style: normal; */
    font-weight: bold;
    font-size: var(--font-h1-42) !important;
    line-height: 56px;
    text-align: center;
    padding-bottom: 37px;
    color: var(--primary-text);
    /* font-family: Isidora Sans; */
    margin: 0;
}

h2{
    /* font-style: normal; */
    font-weight: bold;
    font-size: var(--font-h2-34);
    line-height: 56px;
    text-align: center;
    padding-bottom: 37px;
    color: var(--primary-clr);
    /* font-family: Isidora Sans; */
    margin: 0;
}

.forget-password a{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline !important;
    color: var(--primary-clr)!important;
   }

.forget-password{
    margin: 1rem 0;
   }

.radio-area .custom-control{
    background: #ffffff;
    border: 1px solid var(--primary-border) !important;
    box-sizing: border-box;
    min-height: 50px;
    padding: .7rem;
    display: inline-block;
    margin: 0px 10px 10px 0px;
    border-radius: 40px;
    line-height: 19px;
    min-width: 100px;
    display: inline-flex;
    align-items: center;
}

.custom-control .form-check {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    min-height: 1rem;
    padding-left: 1.5em;
  }
   
.page-inner-section .form-check-input,  .page-inner-section .form-radio-input{
    min-width: 1.5em !important;
    height: 1.5em !important;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    opacity: 1;
    margin-top: 0;
}

.form-check-input, .form-radio-input {
    width: 1.5em !important;
    height: 1.5em !important;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    opacity: 1;
    margin-top: 0 !important;
}

.radio-area label {
    font-family: Isidora Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #4d4d4d;
    vertical-align: middle;
    cursor: pointer;
    overflow: hidden;
    padding-left: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 0;
    /* float: left; */
  }

  .radio-area h4{
    font-family: var(--font-base-bold) !important;
    color: var(--primary-clr) !important;
    font-weight: var(--font-wt500) !important;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    margin-bottom: 10px;
  }

.page-inner-section .form-check-input:checked{
    background-color: var(--secondary-clr) !important;
    border-color:var(--primary-border) !important;
}

.form-check-input:checked, .form-radio-input:checked{
    background-color: var(--secondary-clr) !important;
    border-color:var(--primary-border) !important;
}

.page-inner-section .form-check-input:focus{
    box-shadow: none !important;
}

.form-check-input:focus, .form-radio-input:focus{
    box-shadow: none !important;
}

h1.heading{
    /* font-weight: bold; */
    font-size: var(---font-h2-34);
    line-height: 56px;
    text-align: center;
    padding-bottom: 37px;
    color: var(--primary-clr);
    }

.have-account {
    margin: 1rem auto 0 auto;
}
    
.have-account p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    /* text-decoration-line: underline; */
    font-family: 'Isidora Sans';
}

.have-account p a{
    text-decoration: underline !important;
    cursor: pointer;
    color: var(--secondary-clr) !important;
    font-family: var(--font-sbold);
}

.have-account p a.bold{
    color: var(--primary-clr) !important;
}

input[type=password] + .eye-passimg,  input[type=text] + .eye-passimg{
    position: absolute;
    top: 50px;
    right: 15px;
    cursor: pointer;
}

.modal-500 .MuiDialog-paper {
    background: #fff;
    box-shadow: 0px 15px 58px rgba(0, 0, 0, 0.13);
    border-radius: 14px;
    width: 500px !important;
    /* height: 697px; */
    }

    /* .radio-area .custom-control {
        background: #ffffff;
        border: 1px solid var(--primary-border) !important;
        box-sizing: border-box;
        height: 50px;
        padding: .375rem 1rem;
        display: inline-block;
        margin: 0px 10px 10px 0px;
        border-radius: 40px;
        line-height: 32px;
        min-width: 115;} */

.strong{
    /* font-weight: bolder; */
    font-family: var(--font-base-bold);
}

.head-bold{
    font-weight: var(--head-bold);
    font-family: var(--font-base-bold);
}

.section-heading{
    font-family: var(--base-font-family);
    color: var(--white);
    font-size: var(--font-h1-42);
    padding-bottom: 30px;
    margin-bottom: 0;
}

.img-responsive{
    max-width: 100%;
    height: auto;
}

.select-noborder{
    width:auto !important;
    border: none !important;
    padding: 5px 35px 5px 0 !important;
    height: 40px;
    -webkit-appearance: none;
    appearance: none;
    /* position: absolute;
    z-index: 9999; */
}

.select-noborder:focus{
    box-shadow: none !important;}

.tags-row .tags{
    color:var(--white);
    padding: 3px 7px;
    border-radius: 3px;
    font-size: var(--font-14);
    font-family: var(--font-sbold);
    margin: 0 10px 10px 0;
    line-height: 17px;
}
.tags-row .tags:first-child{
    margin-left: 0;
}
.tags-row .tag1{
    background: var(--primary-clr);
}
.tags-row .tag2{
    background: #F57D7D;
}
.tags-row .tag3{
    background: #4CC3B0;
}
.tags-row .tag4{
    background: #B693C8;
}
.tags-row .tag5{
    background: #F5CF70;
}

.modal-backdrop{
    opacity: 0.5;
}


p{
    font-family: var(--base-font-family);
    font-size: var(--font-14);
    color: var(--primary-text);
    word-break: break-word;
}

fieldset{
    border: none !important;
}

hr.custom{
    background: url(./Assets/img/signup/hr.svg) no-repeat;
    height: 60px !important;
    display: inline-block;
    width: 100%;
}

hr.grey{
    background: #EEEEEE;
    opacity: 1;
}

hr.grey-50{
    background: #EEEEEE;
    opacity: .25;
}

.back-pg .css-i4bv87-MuiSvgIcon-root{
    width: 1rem;
    height: 1rem;
    fill:var(--secondary-clr);
}
    
.back-pg {
    color: var(--secondary-clr);
    font-family: var(--font-sbold);
    font-size: var(--font-18);
    cursor: pointer;
}

.back-pg:hover{
    /* color: var(--primary-clr); */
    text-decoration: underline;
}

.pale-bg{
    background: var(--bg-paleyellow-clr);
}

.pregress-bar-caption{
    font-family: var(--base-font-family);
    font-size: var(--font-14);
    color: var(--primary-text);
}

.blue-link{
    color:var(--primary-clr);
    text-decoration: underline;
}

.teal-link{
    color:var(--secondary-clr);
    text-decoration: underline;
}

.cursive{
    font-family: var(--Respondent-Bold);
    line-height: 0 !important;
}

.mu-custom-fields .MuiOutlinedInput-root .MuiOutlinedInput-input {
    width: 100% !important;
    padding: 0.375rem 1rem !important;
}


.number-field .MuiFilledInput-root{
    background-color:transparent;
    border-radius: 0;
}

.number-field .MuiFilledInput-root:hover, .number-field .MuiFilledInput-root.active{
    background-color:transparent;
}

.number-field .MuiFilledInput-root::before{
    border-bottom:none !important;
}

.number-field .MuiFilledInput-root::after{
    border-bottom:none !important;
}

.progress-bar-container .progress{
    height: 8px;
    margin: 15px 0;
}

.progress-bar-container .progress-bar{
    background-color:var(--secondary-clr);
}

/* input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
} */

.thing {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
 }
 .profile-progress .MuiBox-root, .profile-progress .MuiBox-root .MuiCircularProgress-root, .profile-progress .MuiBox-root .MuiBox-root {
    width: 100% !important;
    height: 100% !important;
}
.profile-progress {
    width: 70px;
    height: 70px;
}
button.copy-code {
    background: var(--primary-clr);
    margin-top: -4px;
    padding: 8px 6px;
}
.book-download i.fa.fa-download {
    color: var(--primary-clr);
    font-size: 28px;
}
.upcoming label {
    background-color: #DADADA;
    font-size: 14px;
    min-width: 160px;
    text-align: center;
    height: 40px;
    padding: 10px 30px !important;
}

.white{

    color:var(--white);
}

.custom-modalTeal .modal-content{
    text-align: center;
    background: url('Assets/img/congratulation-bg.svg') no-repeat, #4CC3B0!important;
    border-radius: 14px;
    border: none !important;
    padding: 2rem !important;
    background-position: cover;
    position: relative;
}

.custom-modalTeal .modal-content h2 {
    font-family: 'Isidora Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    color:#fff;
    margin-bottom: 0px;
    padding-bottom: 0;
}

.custom-modalTeal .modal-content .gotohome{
    color: var(--white);
    text-decoration: underline;
    padding: 0 15px !important;
    height: unset !important;
    font: var(--font-base-bold);
}

.custom-modalTeal .modal-content .gotohome:hover{
    color: #30426D;
    text-decoration: underline;
    height: unset !important;
}

.custom-modalTeal .modal-header .btn-close{
    position: absolute;
    right: 15px;
    top: 15px;
    background: url('./Assets/img/white-close.svg') no-repeat;
    background-position: center center;
    background-size: 15px;
}

.custom-modalTeal .modal-footer{
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.keep-all{
    word-break:keep-all;
}










/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {  }

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { }

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {  }

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .padding-lt-rt{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {  }

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 359.98px) {
    .radio-area .custom-radio label{
    font-size: 14px;
    }
}

@media (max-width: 319.98px) {
    .radio-area .custom-radio label{
    font-size: 12px;
    }

    .primary-blue-small-btn-40{
        padding: 10px 15px !important;
    }
}