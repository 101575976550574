.preLoginHowWeSupport {
  padding: 120px 0 0;
  .heading3 {
    font-family: "Isidora Sans medium";
    font-size: 42px !important;
    line-height: 44px;
    color: #30426d;
    text-align: left;
    margin: 0;
    padding: 0;
    text-align: center;

    span {
      font-family: "Isidora Sans bold";
    }
  }

  @media (max-width: 767.98px) {
    padding: 60px 0 0;

    .heading3 {
      font-size: 34px !important;
      max-width: 250px;
      margin: 0 auto;
    }
  }
}

.preLoginHowWeSupportList {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1106px;
  width: 100%;
  margin: 25px auto 0;

  @media (max-width: 767.98px) {
    display: none;
  }
}

.preLoginHowWeSupportCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 273px;
  width: 100%;
  margin: 40px;
  min-height: 250px;

  .title,
  .description {
    font-family: "Isidora Sans bold";
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  .description {
    font-family: "Isidora Sans medium";
  }

  @media (max-width: 767.98px) {
    margin: 40px auto 70px;
  }
}
.preLoginHowWeSupportListGrow {
  flex-grow: 1;
}

.preLoginHowWeSupportCarousel {
    display: none;
  .carousel-indicators {
    margin: 0;
    button {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      border: 1px solid transparent;
      background-color: #989898;
      opacity: 1;

      &.active {
        background-color: #4cc3b0;
      }
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }
  @media (max-width: 767.98px) {
    display: block;
  }
}
