.preLoginFAQWrapper {
  max-width: 735px;
  width: 100%;
  padding: 120px 0 77px;
  margin: 0 auto;

  .heading2 {
    font-family: "Isidora Sans bold";
    font-size: 50px !important;
    line-height: 58px;
    color: #30426d;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  @media (max-width: 767.98px) {
    padding: 38px 0 60px;

    .heading2 {
      font-size: 36px !important;
      margin: 0 auto;
    }
  }
}
