.preBabyBootCamp {
  padding: 0 16px;
  max-width: 1071px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preBabyBootCampContent {
  max-width: 429px;
  width: 100%;
  margin-right: 20px;
  flex-shrink: 2;
}

.preBabyBootCampDescription {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
}

.preBabyBootCampBtnLink {
  margin-top: 30px;
  background-color: #f57d7d !important;
  border: solid 1px #f57d7d !important;
}

.preBabyBootCampImage {
  max-width: 509px;
  width: 100%;
  flex-shrink: 1;

  img {
    width: 100%;
  }
}

.preBabyBootCampHead {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;

  h2 {
    margin: 0;
    padding: 0;
    text-align: left;
    font-family: "Isidora Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 58px;
    color: #4d4d4d;
    max-width: 230px;
    padding-bottom: 20px;
  }

  span {
    font-size: 44px;
    line-height: 20px;
    font-family: var(--respondent-medium);
    color: #f57d7d;
  }
}

@media (max-width: 991.98px) {
  .preBabyBootCamp {
    padding: 0 40px;
    flex-direction: column-reverse;
    overflow: hidden;
  }

  .preBabyBootCampImage {
    margin-top: 40px;
    position: relative;
    left: 8%;
  }
}


