.editor-markup-styles {
  color: #4d4d4d;

  p {
    margin: 0;
    color: #4d4d4d;
    font-size: 16px !important;
    line-height: 22px !important;
  }

  strong {
    font-family: var(--font-base-bold);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: start;
    padding: 0;
    margin: 0;
    font-family: var(--font-smedium);
    color: #4d4d4d;
  }

  h1 {
    font-size: 40px !important;
    line-height: 40px !important;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 35px !important;
    line-height: 45px !important;
    margin-bottom: 7px;
  }
  h3 {
    font-size: 30px !important;
    line-height: 40px !important;
    margin-bottom: 4px;
  }
  h4 {
    font-size: 25px !important;
    line-height: 35px !important;
    margin-bottom: 1px;
  }
  h5 {
    font-size: 20px !important;
    line-height: 30px !important;
  }
  h6 {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  img,
  iframe {
    width: 400px !important;
    max-width: 100%;
    background-color: rgb(214, 214, 214);
    // display: block;
  }
}
