.header {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    nav {
        background: #ffffff;
        box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.11);
        height: 92px;
        li.active a {
            font-weight: 900;
            // color: #4d4d4d !important;
        }
        li {
            // padding: 0 0.5rem;
            a.nav-link{
                font-style: normal;
                font-weight: 800;
                font-size: 14px;
                line-height: 18px;
                align-items: center;
                letter-spacing: 0.2px;
                color: #373f41 !important;
                // padding-left: 0 !important;
                // padding-right: 0 !important;
                padding: 5px 15px !important;
                display: block;
                white-space: nowrap;
            }
           
            div.dropdown-menu{
                top:0 !important;
                 min-width: 200px;
                a.dropdown-item, a.nav-link{
                    padding: 5px 15px !important;
                    text-decoration: none;
                    border-left: solid 3px transparent;
                    color: #373f41 !important;
                    font-size: 14px;
                    a{
                        padding: 0 !important;
                        text-decoration: none;
                        color: #373f41 !important;
                        font-size: 14px;
                    }
                }
    
                a.dropdown-item:hover,  a.dropdown-item.active, a.nav-link:hover, a.nav-link.active{
                    background: transparent;
                    border-left: solid 3px var(--secondary-clr);
                    color:var(--secondary-clr);
                    
                    a{
                        color:var(--secondary-clr) !important;
                       
                    }
                }
    
                div.dropdown-menu{
                    border:0 !important;
                    padding: 0  0px!important;
                    position: relative !important;
                    transform: translate(0px, 0px) !important;
                    a{
                        padding: 5px 10px!important;
                        font-weight: 600;
                        text-decoration: none;
                    }
                }
                
                .dropdown-toggle{
                    &::after
                    {
                        content: ' ';
                        width: 0;
                        height: 0;
                        display: inline-block;
                        margin-left: 0.255em;
                        vertical-align: 0.255em;
                        content: "";
                        border-top: 0.3em solid;
                        border-right: 0.3em solid transparent;
                        border-bottom: 0;
                        border-left: 0.3em solid transparent;
                        vertical-align: 0em;
                    }
                }
    
                // div.dropdown-menu{
                //     left:calc(100% - 1px) !important;
                //     transform: translate3d(0px, 0px, 0px) !important;
    
                // }
            }
          
            .dropdown-item.active, .dropdown-item:active{
                background-color:var(--secondary-clr);
                color:var(--white);
            }
            
        }

        .become-flex{
            margin-left:8px;
            margin-right:8px;
        }

        .top-nav-ext-btn{
            margin-left:8px;
        }
    
        .primary-blue-small-btn-40, .primary-outline-btn{
            min-width: none;
            padding: 10px 15px !important;
        }
    }

    .consultSubLink {
        padding-left: 16px;
    }
}

@media (max-width: 1399.98px) {
    .header {
        nav {
            li {
                a.nav-link{
                padding: 10px 8px !important;
                }
            }
        }

        .become-flex{
            margin-left:10px;
            margin-right:10px;
        }

        .top-nav-ext-btn{
            margin-left:10px;
        }

        .user-profile{
            button.MuiButtonBase-root{
                margin-left:5px !important;
            }
        }
    }
       
 }

    

@media (max-width: 1199.98px) {
    .header {
        
        .brand-logo{
            max-width: 170px;
            height: auto;
            width: 100%;
        }
        nav {
            li {
                a.nav-link{
                padding: 10px 4px !important;
                font-size: 13px;
                }
            }

            button.become-member-btn {
                min-width: 0px !important;
                width: auto;
                padding: 0 5px !important;
            }
           
        }

        .become-flex{
            margin-left:4px !important;
            margin-right:2px !important;
            // margin-bottom: 15px;
        }

        .top-nav-ext-btn{
            margin-left:4px !important;
        }

        .user-profile{
            button.MuiButtonBase-root{
                margin-left:0px !important;
            }
        }
        .primary-blue-small-btn-40, .primary-outline-btn{
            font-size:12px;
        }
    }
}

@media screen and (max-width: 991px) {
    .header {
        .navbar-collapse {
            overflow: hidden;
            top:75px;
            position: fixed;
            height: calc(100vh - 0px) !important;
            background: #f8f9fa;
            left: -100vw !important;
            width: 100% !important;
            transition: ease all 0.5s;
            padding: 0;
            margin: 0;
            display: block !important;
            visibility: hidden;
            z-index: 9999;
    
            li {
                // padding: 0 0.5rem;
                a.nav-link{
                    font-size: 18px;
                    padding: 10px 0px !important;
                }
                div.dropdown-menu.show{
                    // top: -40px !important;
                    min-width: unset;
                    position: relative !important;
                    transform: translate3d(0px, 00px, 0px) !important;
                }
            }
        }
        .navbar-collapse.show {
            left: 0 !important;
            transition: ease all 0.5s;
            visibility: visible;
        }
        .navbar-collapse ul{
            padding: 23px 30px 0 23px;
        }

        .top-nav-ext-btn {
            margin-left: 0px !important;
        }
        .become-flex{
            margin-bottom: 15px;
            margin-left: 23px !important;
            margin-top: 10px;
        }
    }
  
    // .header li {
    //     padding: 10px 0px;
    // }
    // .header li a{
    //     font-size: 18px;
    //     color: #000 !important;
    // }
    // .header .login-btn{
    //     font-size: 18px;
    //     color: #000;
    //     text-align: left;
    //     padding: 12px 0;
    //     margin-right: 20px;
    // }

    // .top-nav-ext-btn {
    //     padding: 0 30px 23px 30px;
    // }

    .profile-mobile-view{
        margin-right: 10px;
        margin-left: auto;
    }

    .top-nav-ext-btn{
        padding: 0 30px 23px 23px;
        width: max-content;
        flex-direction: column !important;
    }

    .top-nav-ext-btn.flex-md-row{
        flex-direction: column !important;
        margin-left: 0;
    }

    .startnow-btn {
        margin: 23px 0 0 0 !important;
    }
    // .user-profile{
    //     position: fixed;
    //     right: 80px;
    //     top: 5px;
    //     z-index: 9999;
    //     display: flex;

    //     button.MuiButtonBase-root{
    //         .MuiAvatar-circular {
    //             width: 45px;
    //             height: 45px;
    //         }
    //     }
    // }
}

@media screen and (max-width: 767px) {
    .header nav{
        height: 92px;
        img{
            width: 120px;
        }
        
    }
    .navbar-collapse {
        overflow: hidden;
        top: 85px;
        position: fixed;
        height: calc(100vh - 0px) !important;
        background: #f8f9fa;
        left: -100vw !important;
        width: 100% !important;
        transition: ease all 0.5s;
        padding: 0;
        margin: 0;
        display: block !important;
        visibility: hidden;
        z-index: 9999;
    }
    .navbar-collapse.show {
        left: 0 !important;
        transition: ease all 0.5s;
        visibility: visible;
    }
    .navbar-collapse ul{
        padding: 23px 30px 0 23px;
    }
    .header li {
        padding: 5px 0px;
    }
    .header li a{
        font-size: 18px;
        // color: #000 !important;
    }
    // .header .login-btn{
    //     // margin-left: 32px;
    //     font-size: 18px;
    //     color: #000;
    //     text-align: left;
    //     padding: 12px 0;
    // }
    // .startnow-btn{
    //     margin: 23px 0 0 0 !important;
    
    // }
}



@media screen and (min-width: 768px) and (max-width: 1024px) {
    .header {
        li{
            a{
                padding: 10px 5 !important;
            }
        }
        .primary-blue-small-btn-40, .primary-outline-btn{
            padding: 10px !important;
        }

       
    }
}





@media only screen and (max-width: 768px) and (min-width: 991.98px)  {
    .top-nav-ext-btn{
        flex-direction: column!important;
        width: max-content !important;
    }
}