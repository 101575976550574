.stage-main{
    .stage_baby_feature-main{
        h2{
            font-size: var(--font-h2-34);
        }
        // .parent-right-way-info{
        //     h3{
        //         font-size: var(--font-h2-34);
        //     }
        // }
        .stage_baby_feature{
            border-radius: 123px;
            overflow: hidden;
            img{
                border-radius: 123px;
                overflow: hidden;
            }
        }
        p{
            .cursive{
                font-size: 22px;
                line-height: 10px;
            }
        }
    }

    .stage-offering-header{
        .section-header {
            h2.home-section-title{
                font-size: var(--font-50) !important;
                color:var(--primary-clr);
                font-weight: 400;
            }
        }
    
        .stage-description{
            color: var(--primary-clr);
        }
    }

    .parent-right-way{
        background: url('../../Assets/img/stage/parent-way-bg-wave.svg') var(--primary-clr);
        background-repeat: no-repeat;
        border-radius: 14px;
        background-position: left bottom;
        color: var(--white);
        background-size: cover;
        .registered-nurse-imgsection{
           img{
            border-radius: 123px;
            overflow: hidden;
           }
        }

    }

    .stages-specific-offering{

        .carousel-indicators{
          margin: 2rem auto 0 auto;
          bottom: -25px;
        }
        
        .carousel-indicators  [data-bs-target].active {
          opacity: 1;
          background-color: var(--secondary-clr) !important;
        }
    
        .carousel-indicators [data-bs-target]{
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: var(--grey-shade-3) !important;
        }
    }

    .stages-specific-offering{
        .card{
         display: flex;
         flex-direction: column;
         border-radius: 20px !important;
         border: none !important;
         height: 100%;
         min-height: auto;
         .card-body {
           padding: 1.5rem;
           display: flex;
           flex-direction: column;
         }

         .card-button {
          flex-grow: 1;
          display: flex;
          align-items: flex-end;
         }

         .video-container-inner {
           padding: 0px;
           position: relative;
           width: 100%;
           aspect-ratio: 416 / 241;
           overflow-y: hidden;
           height: auto;
           img {
             border-radius: 14px 14px 0 0;
             height: 100%;
             object-fit: cover;
           }
         }
         .card-title {
           margin-bottom: 0;
           color: var(--primary-clr);
           font-size: var(--font-26);
           font-family: var(--font-sbold)
         }
         .instructor-profile{
           background: #ccc;
           width: 52px;
           height: 52px;
           border-radius: 50%;
           }
        }
    }

    .stage-stretch {
        height: 100%;
    }
}