.tabsWrapper {
  display: flex;
  justify-content: center;
  border: 1px solid #4cc3b0;
  border-radius: 100px;
  overflow: hidden;
}

.tabsRow {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;

  &__tab {
    border: 0;
    outline: 0;
    background-color: #fff;
    border-right: 1px;
    border-color: #4cc3b0;
    border-style: solid;
    padding: 10px 30px;
    font-family: Isidora Sans medium;
    color: #4cc3b0;
    font-size: 16px;

    &--active {
      background-color: #4cc3b0;
      color: #fff;
    }
  }

  &__tab:last-of-type {
    border-right: 0;
  }
}

@media (max-width: 575.98px) {
  .tabsRow {
    &__tab {
      font-size: 12px;
      padding: 10px 10px;
    }
  }
}

@media (max-width: 359.98px) {
  .tabsRow {
    &__tab {
      font-size: 11px;
    }
  }
}
