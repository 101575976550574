.recheckPasswordMain {
  h3,
  p {
    color: var(--white);
  }
}
.recheckPasswordForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recheckPasswordFormItem {
  max-width: 300px;
}

p.recheckPasswordFormItemError {
  text-align: left;
  color: var(--monthly-clr);
  padding: 6px 0;
  margin: 0;
  min-height: 33px;
}
