.ServiceCardMain {
  display: flex;
  flex-direction: column;
  height: 545px;
  border-radius: 20px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 14px rgba(148, 148, 148, 0.1);
}

.ServiceCardPicture {
  width: 100%;
  aspect-ratio: calc(353 / 199);
  object-fit: cover;
  border-radius: 14px;
}

.ServiceCardTitle {
  margin-bottom: 0;
  color: var(--primary-clr);
  font-size: var(--font-20);
  font-family: var(--font-sbold);
  margin-right: 0.5rem;
  margin-top: 20px;
  padding: 0;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ServiceCardDescription {
  word-break: break-word;
  font-family: var(--base-font-family);
  font-size: var(--font-14);
  color: var(--primary-text);
  margin-top: 8px;
  padding: 0;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ServiceCardSeeMore {
  text-decoration: none;
  color: var(--secondary-clr);
  font-family: var(--font-sbold);
  font-size: var(--font-14);
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
    color: var(--secondary-clr);
  }
}

.ServiceCardFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-grow: 1;
  margin-bottom: 10px;
}

.ServiceCardFooterPrice {
  color: var(--primary-clr);
  font-family: var(--font-sbold);
  font-size: var(--font-26);
}
