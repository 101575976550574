.accordionItemWrapper {
  padding: 20px 0;
}
.accordionItemWrapperBorder {
  border-bottom: 1px solid #c4c4c4;
}

.accordionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #30426d;
  font-family: "Isidora Sans bold";
  font-size: 18px;

  button {
    background: transparent;
    border: 0;
    outline: 0;
    width: 41px;
    height: 41px;
    border-radius: 41px;
    border: 1px solid #4cc3b0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    flex-shrink: 0;
    transition: 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }

  @media (max-width: 767.98px) {
    font-size: 16px;
  }
}

.accordionDescription {
  font-family: "Isidora Sans medium";
  color: #4d4d4d;
  font-size: 18px;
  margin: 30px 0;

  button {
    display: inline-block;
    background: transparent;
    border: 0;
    outline: 0;
    color: #4d4d4d;
    text-decoration: underline;
    padding: 0;
    transition: 0.3s;

    &:hover {
      opacity: 0.6;
    }
  }

  @media (max-width: 767.98px) {
    font-size: 16px;
    margin: 18px 0;
  }
}
