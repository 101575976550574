@import "../../global.css";
@import "../../global-componant.css";

.corporate-container{
    font-family: var(--base-font-family);

    .corporate-discription-section{
        .corporate_description{
            p{
                font-size: var(--font-20);
                color:var(--primary-clr);
            }
        }
    }

    .corporate-featured{
        background: url('../../Assets/img/Promises/Registered-nurse-bg-wave.svg') var(--monthly-clr);
        background-repeat: no-repeat;
        border-radius: 14px;
        background-position: left top;
        color: var(--white);
        background-size: cover;
        .registered-nurse-imgsection{
           img{
            border-radius: 123px;
            overflow: hidden;
           }
        }

        .registered-nurse-info{
            h3{
                font-size: 36px;
            }
            p{
                color:var(--white);
            }
        }
    }
   
    .become-main{
        .become-member-container{
            background: url('../../Assets/img/corporate/corporate-program-becomemember.jpg') no-repeat;
            background-position: left center !important;
            background-size: cover;
            border-radius: 14px;
            .become-meber-captions{
                h3{
                    color:var(--white);
                    font-family: var(--base-font-family);
                    font-size: var(--font-h2-34);
                    font-weight: 300;
                    span{
                        display: block;
                        font-weight: 700;
                        color: var(--secondary-clr);
                    }
                }
                p{
                    font-size: var(--font-14);
                    font-weight: 300;
                    color: var(--white);
                    margin: 0;
                }
                ol { 
                    counter-reset: item; 
                    padding-left: 0;
                    li { 
                      display: table;
                      font-size: var(--font-18);
                      color:var(--white);
                      text-align: start;
                      &::before {
                        content: counter(item) ". ";
                        counter-increment: item;
                        color: var(--secondary-clr);
                        padding-right:20px;
                        display: table-cell;
                        font-family: var(--font-sbold);
                      }
                      margin-bottom: 10px;
                    }
                }
                .price-dtls{
                    .pvalue{
                        color:var(--white);
                        font-family: var(--font-sbold);
                        font-size: var(--font-h1-42);
                        font-weight: 900;
                        font-weight: var(--font-wt900);
                    }
                }
            }
            .mob-membership-img-container{
                background: none;
            }
        }
    }
    .ourAudience{
        background: url(../../Assets/img/sponsors/ourAudience.svg) #30426D no-repeat;
        border-radius: 14px;
        background-position: left top;
        color:var(--white);
        h2{
            color: var(--white);
        }
        .statics{
            .count{
                font-size: 67px;
                font-family: var(--font-base-bold);
            }
            .value{
                font-size: 77px;
                font-family:  var(--Respondent-Bold);
            }
            .sm-follorers{
                color:var(--monthly-clr)
            }
            .nurture-member{
                color:var(--halfyearly-clr)
            }
            .email-subscriber{
                color:var(--orange)
            }
            .podcast-download{
                color: var(--quaterly-clr);
            }
            .webinars-view{
                color: var(--pink-lt);
            }
            
        }
        .stats-head{
            margin-top: -50px;
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .corporate-container{
        .become-main{
            padding-left: 0 !important;
            padding-right: 0 !important;
            .become-member{
                .container{
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    .become-member-container{
                        background: none !important;
                        background-size: 100% !important;
                        background-position: left top;
                        border-radius: 0px !important;
                        padding-left: 15px !important;
                        padding-right: 15px !important;
                        .become-meber-captions{
                            // margin-top: 300px;
                            h3{
                                font-size: var(--font-24) !important;
                                margin-top: 0.8rem !important;
                                color:var(--primary-text) !important;
                                text-align: center;
                            }
                            ol { 
                                li { 
                                    color:var(--primary-text);
                                    font-size: var(--font-14);
                                }
                            }
                            
                        }
                        
                        .mob-membership-img-container{
                            background: url('../../Assets/img/corporate/corporate-Become-member-mobile.jpg') no-repeat !important;
                            background-position: center center;
                            height: 500px;
                            background-size: cover !important;
                            border-radius: 15px;
                            overflow: hidden;
                            // h3{
                            //     text-align: center;
                            // }
                        }
                       
                    }
                }
            } 
        }
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .corporate-container{
        .commitments-main {
            .promises-section-title{
                font-size: 2rem;
            }
            .commitment-row 
                .info-img {
                    min-width: unset;
                    max-width: 120px;
                }
        }
        .become-main{
            .become-member{
                .container{
                    .become-member-container{
                        .mob-membership-img-container{
                            height: 400px;
                        }
                    }
                }
            }
        }
    }
}
