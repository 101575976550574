.searchFullWidthMain {
    display: flex;
    width: 100%;
    align-items: center;
    max-width: 100%;
}

.searchFullWidthField {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0 0 0 8px;
    background-color: transparent;
    border: 0;
    outline: 0;
    min-width: 40px;
}


@import "../../global.css";
@import "../../global-componant.css";

.search-container{
    margin: 20px auto;
    background: #F9F9F9;
    padding: 10px 25px !important;
    position: relative;
    border-radius: 30px;
    .filter_para {
        font-family: var(--font-sbold);
        font-weight: var(--font-wt500);
        font-size: var(--font-16);
        line-height: 20px;
        color: var(--primary-clr) !important;
        cursor: pointer;
        flex-shrink: 0;
        display: flex;
        img{
            margin-right: 10px;
        }
    }
    .search_total_div {
        float: right;
        cursor: pointer;
        .total_item {
            font-family: var(--font-sbold);
            font-weight: var(--font-wt500);
            font-size: var(--font-16);
            line-height: 20px;
            text-align: right;
            color: var(--primary-clr);
            margin-right: 10px;
          }
    }
    .cross {
        // background: #e2e5ec;
        // border-radius: 0px 5px 0px 0px;
        width: 39px;
        height: 39px;
        display: inline-block;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 11px;
        text-align: center;
        padding: 7px;
    }
    .filter_content {
        position: absolute;
        top: 49px;
        background: #fff;
        // border: 0.5px solid var(--primary-text);
        box-shadow: 0px 7px 48px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        width: 100%;
        z-index: 99;
        padding: 10px 30px;
        padding-top: 10px;
        left: 0px;
        h3 {
            color:var(--primary-clr) !important;
            font-family: var(--font-sbold);
            font-size: var(--font-26);
        }
        .search_content{
            .search_result {
                color:var(--primary-clr);
                font-family: var(--base-font-family);
                font-size: var(--font-14);
                p{
                    margin-bottom: 0;
                    color:var(--primary-clr);
                }
            }
            .search-overflow {
                max-height: 300px;
                overflow-y: scroll;
                padding-right: 20px;
                
            }
            .result-name-container{
                margin-bottom: 15px;
                border-bottom: 1px solid #CACACA;
            }

            .result-name-container:last-child{
                border-bottom: none;
                margin-bottom: 0;
            }

            .result-name-container:first-child{
                margin-top: 15px;
            }

            .search_result_content {
                // border-bottom: 1px solid #CACACA;
                padding: 0px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                background: #fff;
                border-radius: 10px;
                .search_img {
                    width: 130px;
                    height: 87px;
                    border-radius: 10px;
                    overflow: hidden;
                    display: inline-flex;
                    // img{
                    //     // width:100%;
                    //     // height: auto;
                    // }
                }
                .search_right_detail {
                    padding-left: 20px;
                    display: inline-flex;
                    width: calc(100% - 130px);
                }
            }

            .search_result_content:hover{
                -webkit-box-shadow: 0 0 5px 0 #C4C4C4;
                box-shadow: 0 0 5px 0 #C4C4C4;
                cursor:pointer;
            }
            

            .form-group {
                width: 80%;
                position: relative;
                .form-control.search{
                    padding-left: 20px;
                    background: #F9F9F9;
                    -webkit-appearance: none;
                }
                
                img {
                    position: absolute;
                    right: 20px;
                    top: 13px;
                    z-index: 999;
                    cursor: pointer;
                }
            }

            .reset-search{
                cursor: pointer;
                font-family: var(--base-font-family);
                font-size: var(--font-14);
                color: var(--primary-text);
                word-break: break-word;
                text-decoration: underline;
            }
            
        }
    }
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {  }

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .search-container{
        .filter_content{
            .search_content {
                .form-group {
                    width: 100%;
                    position: relative;
                }
                .search_result{
                    width:100%;
                    p{
                        text-align: left;
                    }
                }
            }
        }
    }
 }

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {  }

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { }

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {  }
   