.askNurseWrapper {
  padding: 16px 16px 0;
}

.askNurseArea {
  margin: 0 auto;
  max-width: 1290px;
  width: 100%;
}

.askNurseMain {
  background-color: #faf8ef;
  margin: 0 16px 24px;
}

.askNurseQuestions {
  margin: 0 auto;
  max-width: 1320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;
}

@media (max-width: 767.98px) {
  .askNurseMain {
    margin: 0 0 24px;
  }
  .askNurseQuestions {
    padding: 0 16px 16px;
  }
}
