.askNurseChatMaxWidth {
  max-width: 1320px;
  margin: 0 auto;
}

.defaultScrollBehavior {
  scroll-behavior: auto !important;
}

.askNurseChatBack {
  border: 0;
  outline: 0;
  background-color: transparent;
  font-family: var(--font-sbold);
  font-size: 18px;
  line-height: 20px;
  color: #30426d;
  display: flex;
  align-items: center;
  position: sticky;
  top: 110px;

  svg {
    margin-top: 4px;
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.askNurseChatPending {
  padding: 10vh 0;
  display: flex;
  justify-content: center;
}

@media (max-width: 767.98px) {
  .askNurseChatArea {
    padding: 24px 16px;
  }
}

.preventAnchorScroll {
  overflow-anchor: none;
}

.askNurseChatTitle {
  font-family: var(--font-sbold);
  font-size: 24px !important;
  line-height: 30px;
  color: #30426d;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}