@import "../../global.css";
@import "../../global-componant.css";

.about-container{
    font-family: var(--base-font-family);
    .about-discription-section{
        .about_description{
            p{
                font-size: var(--font-20);
                color:var(--primary-clr);
            }
        }
    }

    .registered-nurse-main{
        background: url('../../Assets/img/Promises/Registered-nurse-bg-wave.svg') var(--monthly-clr);
        background-repeat: no-repeat;
        border-radius: 14px;
        background-position: left top;
        color: var(--white);
        background-size: cover;
        .registered-nurse-imgsection{
           img{
            border-radius: 123px;
            overflow: hidden;
           }
        }

        .registered-nurse-info{
            h3{
                font-size: 36px;
            }
            p{
                color:var(--white);
            }
        }
    }

    .founder-details{
        .about-section-title {
            font-weight: 500;
            font-size: 42px;
            color: var(--primary-clr);
        }

        .about-founder{
            .founder-pic{
                border-radius: 15px;
                overflow: hidden;
                img{
                    border-radius: 15px;
                    overflow: hidden; 
                }
            }
            .founder-nm{
                font-family: 'Respondent';
                font-style: italic;
                font-weight: 700;
                font-size: 60px;
                line-height: 58px;
                color: var(--primary-text);
            }
            .founder-full-info{
                font-family:var(--base-font-family);
                font-weight: 600;
                font-size: var(--font-18);
                text-transform: uppercase;
                color: var(--secondary-clr)
            }
        }
    }


    .curious-main{
        .read-story{
            background: var(--orange) url('../../Assets/img/Promises/story-sec-main.svg') 50px bottom no-repeat;
            // background: var(--pastel-purple);
            background-repeat: no-repeat;
            // background-position: 0 -10px;
            background-size: cover;
            border-radius: 14px;
            .sec-caption{
                max-width: 710px;
                margin-right: auto;
                margin-left: auto;
                text-align: center;
                h2{
                    color: var(--primary-clr);
                    font-weight: var(--font-wt500);
                    font-size: var(--font-h2-34);
                    margin-bottom: 15px;
                    padding-bottom: 0;
                    span{
                        font-family: var(--font-base-bold);
                    }
                }
                p{
                    color: var(--white);
                }
            }
    
        }
    }
    
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 479.98px) {
    .about-container{
        .curious-main{
            .read-story {
                .sec-caption{
                    h2{
                        line-height: 32px;
                    }
                }
            }
        }
    }
}
