.alert-msg {
    font-size: 16px!important;
    font-weight: 500!important;
    text-align: center;
    color: #50CCB8!important;
}
.alert-body{
    border: 0.2px solid #50CCB8!important;
    background: #50CCB821!important;
    border-radius:6px;
}