.naps-testimonial-row {
    .carousel-caption {
        left: 0;
        right: 0;

        &::before {
            content: '';
            background: url("../../Assets/img/membership-main/quote.svg") no-repeat center center;
            width: 52px;
            height: 42px;
            display: flex;
            margin-bottom: 15px;
            width: auto;
        }

        .testimony-caption {
            font-size: var(--font-20);
            // line-height: 36px;
            color: var(--primary-clr);
        }
    }

    .carousel-indicators {
        margin: 2rem auto 0 auto;
    }

    .carousel-indicators [data-bs-target].active {
        opacity: 1;
        background-color: var(--secondary-clr) !important;
    }

    .carousel-indicators [data-bs-target] {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--grey-shade-3) !important;
    }
}