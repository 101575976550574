.daytime_overnight{
    .pink-txt{
        color:var(--monthly-clr);
    }
    .strong{
        font-weight: 700;
        font-family: var(--font-base-bold);
    }
    .section-header {
        h2.home-section-title{
            font-size: var(--font-50) !important;
            color:var(--primary-clr);
        }
    }

    .registered-nurse-main{
        background: url('../../Assets/img/Promises/Registered-nurse-bg-wave.svg') var(--monthly-clr);
        background-repeat: no-repeat;
        border-radius: 14px;
        background-position: left top;
        color: var(--white);
        background-size: cover;
        .registered-nurse-imgsection{
           img{
            border-radius: 123px;
            overflow: hidden;
           }
        }

        .registered-nurse-info{
            h3{
                font-size: 36px;
            }
            p{
                color:var(--white);
            }
        }
    }

    .about-day-time{
        h3{
            font-size: var(--font-28);
            color:var(--secondary-clr);
            font-family: var(--font-base-bold);
        }
        ol { 
            counter-reset: item; 
            padding-left: 0;
            li { 
                display: table;
                font-size: var(--font-20);
                color:var(--primary-clr);
                &::before {
                content: counter(item) ". ";
                counter-increment: item;
                color: var(--secondary-clr);
                padding-right:25px;
                display: table-cell;
                }
                margin-bottom: 10px;
                }
                a{
                    color:var(--secondary-clr);
                    font-family: var(--font-base-bold);
                    text-decoration: underline
                }
                // a:hover{
                //     text-decoration: underline
                // }
            }
            .about-intro-txt{
                h2{
                font-size: var(--font-28);
                font-family: var(--base-font-family);
                font-weight: 300;
                line-height: 42px !important;
                .cursive{
                font-size: 34px;
                line-height: 20px;
                }
              }
              }
    }

    .offerings{
        .offering-block-content{
            h3{
                p{
                    font-size: var(--font-h1-42) !important;
                    font-family: var(--font-base-bold);
                    .number{
                        font-family: var(--base-font-family);
                    }
                    .content{
                        font-family: var(--font-base-bold);
                    }

                }
            }
        }
        .offer-row{
            position: relative !important;
            overflow: hidden;
            .offering-block-content{
                img{
                    border-radius: 15px;
                    overflow: hidden;
                }
            }
        }
    }
    
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .daytime_overnight{
        .offering-main{
            .offering-block-content{
                h3{
                font-size: 45px;
                }
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .daytime_overnight{
        .offering-main{
            .offering-block-content{
                margin-right: 1rem!important;
                h3{
                font-size: 34px;
                }
            }

            .service-feature-img{
                margin-right: 1rem!important;
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .daytime_overnight{
        .about-intro-txt{
            h2 {
            font-size: 20px;
            line-height: 28px !important;
            .cursive{
                font-size: 26px;
            }
            }
        }
        .section-header {
            h2.home-section-title{
                font-size: var(--font-50) !important;
                color:var(--primary-clr);
                font-weight: 400;
            }
        }

        .become-member{
            .container{
                padding: 0 !important;
                .become-member-container{
                    background-size: cover;
                    text-align: center;
                    padding-left: 30px !important;
                    padding-right: 30px !important;
                }
            }
        }

       
    }
}