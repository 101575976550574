.GiftCardDetailsModalPopUpOverflowBody {
  overflow: hidden;
}
.GiftCardDetailsModalPopUpWindow {
  padding: 0 !important;

  .modal-dialog {
    max-width: 588px !important;
  }

  .modal-content {
    border-radius: 14px;
    background-color: transparent;
  }
}

.GiftCardDetailsModalPopUp {
  padding: 36px;
  box-shadow: 0px 15px 58px rgba(0, 0, 0, 0.13) !important;
  background-color: #fff;
  border-radius: 14px;
  border: none !important;

  @media (max-width: 575.98px) {
    padding: 36px 16px 16px;
  }
}

.GiftCardDetailsModalPopUpCross {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  transition: 0.3s;
  height: 16px;
  &:hover {
    opacity: 0.5;
  }

  svg {
    vertical-align: top;
  }
}

.GiftCardDetailsModalHead {
  display: flex;
  align-items: center;

  .GiftCardDetailsModalHeadTitle {
    color: #30426d;
    font-family: var(--font-base-bold);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.4px;
    flex-grow: 1;
    padding: 0;
  }

  .GiftCardDetailsModalHeadPrice {
    color: #30426d;
    font-family: var(--base-font-family);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.36px;
    flex-grow: 1;
  }
}

.GiftCardDetailsModalDescription {
  color: #000;
  font-family: var(--base-font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.21px;
  border-bottom: 1px solid #d6d6d6;
  padding: 10px 0 10px 60px;
}

.GiftCardDetailsModalForm {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-template-rows: 1fr;
  gap: 10px 20px;
  grid-template-areas: ". .";
}

.GiftCardDetailsModalFormField {
  width: 100%;
}

.GiftCardDetailsModalFormBtnWrapper {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.GiftCardDetailsModalPriceMonthly {
  font-size: 14px;
  line-height: 16px;
  position: relative;
  top: -6px;
}

.GCDBillingModalTitle {
  color: #4d4d4d;
  font-family: var(--base-font-family);
  font-size: 22px;
  font-style: normal;
  line-height: 25px;
  letter-spacing: 0.4px;
  margin-top: 8px;
}

.GCDBilling {
  border-radius: 16px;
  border: 2px solid #30426d;
  overflow: hidden;
  margin-top: 20px;

  &__title {
    background-color: #30426d;
    color: #fff;
    font-family: var(--base-font-family);
    font-size: 20px;
    font-style: normal;
    line-height: 25px;
    letter-spacing: 0.4px;
    padding: 16px 24px;
  }

  &__lineWrapper {
    padding: 16px 24px 0;
  }

  &__line {
    color: #555;
    font-family: var(--base-font-family);
    font-size: 16px;
    font-style: normal;
    display: flex;
    justify-content: space-between;
  }

  &__toPay {
    color: #30426d;
    font-family: var(--font-base-bold);
    font-size: 20px;
    font-style: normal;
    padding: 16px 24px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
  }

  &__price {
    color: #30426d;
    font-family: var(--font-base-bold);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  &__divider {
    margin-top: 16px;
    border-bottom: 1px solid #555;
  }
}

.GCDBillingBtn {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767.98px) {
  .GiftCardDetailsModalDescription {
    padding: 10px;
  }

  .GiftCardDetailsModalForm {
    grid-template-areas: ".";
    grid-template-columns: 1fr;
  }
}
