.textContentContent {
  width: 100%;
}

.textContentDescription {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
}

.textContentBtnLink {
  margin-top: 30px;

  &--yellow {
    background-color: #F5CF70 !important;
    border: solid 1px #F5CF70 !important;
  }
  &--green {
    background-color: #4CC4B0 !important;
    border: solid 1px #4CC4B0 !important;
  }
}

.textContentImage {
  max-width: 509px;
  width: 100%;
  flex-shrink: 1;

  img {
    width: 100%;
  }
}

.textContentHead {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;

  h2 {
    margin: 0;
    padding: 0;
    text-align: left;
    font-family: "Isidora Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 58px;
    color: #4d4d4d;
    padding-bottom: 20px;
  }
}
