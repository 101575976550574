.SendGuide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 81px 49px 0 96px;
}

.SendGuideText {
  max-width: 594px;
  width: 100%;
  font-size: 24.0652px;
  line-height: 39px;
  color: #30426d;
  margin-right: 24px;

  &__title {
    font-size: 33.6912px;
    line-height: 58px;
    font-family: var(--font-base-bold);
    margin-bottom: 60px;
  }
}

.SendGuideForm {
  max-width: 626px;
  width: 100%;
  background-color: #b095c4;
  border-radius: 19.2521px;
  color: #ffffff;
  padding: 45px;

  &__title {
    font-size: 33px;
    line-height: 38px;
    font-family: var(--font-base-bold);
    margin-bottom: 20px;
  }

  &__button {
    height: 48px;
    width: 100%;
    outline: 0;
    border: 0;
    border-radius: 25px;
    background: #30426d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 29.8408px;
    color: #fff;
    font-family: var(--base-font-family);
    margin-top: 50px;
  }

  &__error {
    font-size: 14.9754px;
    margin-top: 4px;
    color: var(--monthly-clr);
  }

  label {
    margin-top: 16px;
    font-size: 22px;
    line-height: 40px;
    font-family: var(--base-font-family);
    color: #fff;
  }

  input {
    border-radius: 2.5px !important;
    height: 45px;
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .SendGuide {
    flex-direction: column;
    padding: 29px 30px 0;
  }

  .SendGuideText {
    text-align: center;
    font-size: 18px;
    line-height: 24px;

    &__title {
      margin-bottom: 29px;
      font-size: 22px;
      line-height: 26px;
    }
  }

  .SendGuideForm {
    margin-top: 35px;

    &__title {
      margin-bottom: 29px;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 0px;
    }

    label {
      margin-top: 8px;
      font-size: 11px;
      line-height: 20px;
      font-family: var(--base-font-family);
      color: #fff;
    }

    input {
      border-radius: 2.5px !important;
      height: 45px;
      width: 100%;
    }
  }
}

@media (max-width: 767.98px) {
  .SendGuideForm {
    padding: 23px;

    input {
      height: 32px;
    }

    &__button {
      font-size: 14.9754px;
      height: 32px;
      margin-top: 20px;
    }
  }
}
