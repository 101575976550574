@import "../../../../global.css";
@import "../../../../global-componant.css";



.btn-container-css {
    position: absolute;
    right: 19px;
    top: 50%;
    transform: translateY(-50%);
}

.btn-container-css.open-btn {
    position: absolute;
    right: 50%;
    top: unset;
    transform: translateX(50%);
    bottom: 25px;
}

.main-stepDiv {
    position: relative;
}

.calender-container + .timeslot-container{
    margin-bottom: 30px;
}

.timeslot-container{
    margin-bottom: 40px;
}

// .user-info{
    // form{
        // label{
        //     font-family: var(--base-font-family);
        //     color: var(--primary-clr);
        //     font-weight: var(--font-wt700);
        //     font-size: 16px;
        //     line-height: 20px;
        //     letter-spacing: -0.02em;
        //     margin-bottom: 10px;
        //     display:block
        // }

        // input[type=text], input[type=password], input[type=text], input[type=textarea], input[type='select']
        // {
        //     background: var(--white);
        //     border: 1px solid var(--primary-border) !important;
        //     box-sizing: border-box;
        //     border-radius: 40px;
        //     height: 50px;
        //     font-family: var(--base-font-family);
        //     font-style: normal !important;
        //     font-weight: 400 !important;
        //     font-size: 16px !important;
        //     line-height: 28px !important;
        //     align-items: center;
        //     color: var(--primary-text);
        //     padding: 0.375rem 1rem;
        //     -webkit-appearance: none;
        //     width:100%;
        //     display: block;
        //     margin-bottom: 1.5rem; 
        // }

        // input[type=textarea]{
        //     min-height: 100px;
        //     border-radius: 10px;
        //     word-wrap: break-word;
        // }

        // input[type='radio']{
        //     width: 1.5em !important;
        //     height: 1.5em !important;
        //     margin-right: 10px;
        //     cursor: pointer;
        //     position: relative;
        //     opacity: 1;
        //     vertical-align: top;
        //     background-color: #fff;
        //     background-repeat: no-repeat;
        //     background-position: center;
        //     background-size: contain;
        //     border: 1px solid rgba(0,0,0,.25);
        //     border-radius: 50%;
        //     margin-bottom: 1.5rem;
        // }

        // input[type='radio']:checked:before {
        //     background:#4CC3B0 !important;
        //     border: #949494 !important;
        // }

        // input[type='checkbox'], input[type='single_checkbox']{
        //     width: 1.5em !important;
        //     height: 1.5em !important;
        //     margin-right: 10px;
        //     cursor: pointer;
        //     position: relative;
        //     opacity: 1;
        //     vertical-align: top;
        //     background-color: #fff;
        //     background-repeat: no-repeat;
        //     background-position: center;
        //     background-size: contain;
        //     border: 1px solid rgba(0,0,0,.25);
        //     -webkit-appearance: none;
        //     border-radius: 4px;
        //     margin-bottom: 1.5rem;
        //     float: left;
        // }
        
        // input[type=checkbox] + label{
        //     display: inline-block;
        //     margin-right: 20px;
        // }

        // input[type='checkbox']:checked {
        //     background:#4CC3B0 url("../../../../Assets/img/checkbox-check.svg") no-repeat center center;
        //     border: #949494 !important;
        //     margin-bottom: 0.5rem !important;
        // }
        
    // }
// }

.coupon-applied {
    background: var(--white);
    border-radius: 6px;
    margin: 30px;
    padding: 15px;
    border: solid 1px var(--secondary-clr);
      h6 {
          font-family: 'Isidora Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 34px;
          color:var(--secondary-clr);
          display: inline-block;
          margin: 0;
      }

      a.remove-link {
          display: inline-block;
          // float: right;
          font-family: 'Isidora Sans';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          text-align: right;
          letter-spacing: 0.025em;
          text-decoration-line: underline;
          color:var(--primary-clr);
          font-weight: 1000;
          text-decoration: underline;
          cursor: pointer;
      }

      p {
          font-family: 'Isidora Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #4D4D4D;
          padding: 0;
          text-align: left;
          span{
          font-family: 'Isidora Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.025em;
          color: #30426D;
          }
      }
      .remove{
        cursor: pointer;
    }
  }
  