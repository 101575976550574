.askQuestionWrapper {
  background-color: #4cc3b0;
  border-radius: 14px;
  padding: 24px;
  display: flex;
  justify-content: center;

  .strong {
    font-family: var(--font-base-bold);
  }
}

.askQuestionRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1290px;
  width: 100%;

  &__icon {
    margin-right: 8px;
  }

  &__title {
    max-width: 170px;
    flex-shrink: 1;
    padding: 0 20px;
    font-family: Isidora Sans;
    font-size: 50px;
    text-align: center;
    color: #fff;

    img {
      display: none;
      width: 50px;
      margin-right: 24px;
    }
  }

  &__text {
    flex-shrink: 1;
    max-width: 613px;
    padding: 0 20px;

    p {
      color: #fff;
      font-family: Isidora Sans;
      font-size: 14px;
    }
  }

  &__btn {
    white-space: nowrap;
    width: fit-content;
    flex-shrink: 0;
  }
}

@media (max-width: 991.98px) {
  .askQuestionRow {
    flex-direction: column;

    &__icon {
      display: none;
    }

    &__title {
      max-width: 100%;

      img {
        display: inline-block;
      }

      span {
        white-space: nowrap;
      }
    }

    &__text {
      padding: 16px 0 0;
    }
  }
}

@media (max-width: 575.98px) {
  .askQuestionRow {
    &__title {
      font-size: 34px;
    }
  }
}
