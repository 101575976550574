.section-header {
    h2.home-section-title {
        font-size: 40px !important;
    }
}

.blog-section {

    .blog-card {
        .blog-img {
            border-radius: 16px;
            overflow: hidden;
            height: 200px;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            display: inline-block;
        }

        .blog-content {
            span.creation-date {
                color: var(--primary-border);
                font-size: 14px;
                opacity: .5;
            }

            h6 {
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                color: var(--primary-clr);
                font-size: 18px !important;
                font-family: var(--font-base-bold);
                min-height: 44px;
            }

            .blog-summary {
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                min-height: 63px;
                // color:var(--primary-clr);
                // font-size: 18px !important;
                // font-family: var(--font-base-bold);
                // min-height: 44px; 
            }

            a {
                text-decoration: underline;
                color: var(--primary-clr);
                font-family: var(--font-base-bold);
            }
        }
    }
}