.filter {
  border-radius: 100px;
  background: #f9f9f9;
  padding: 10px 16px;
  display: flex;
  position: relative;

  &__btn {
    border: 0;
    outline: 0;
    background-color: transparent;
    font-family: var(--font-sbold);
    font-size: var(--font-16);
    line-height: 20px;
    color: var(--primary-clr);
    white-space: nowrap;

    img {
      margin-right: 8px;
    }
  }

  &__search {
    border: 0;
    outline: 0;
    background-color: transparent;
    background-color: transparent;
    font-family: Isidora Sans;
    font-size: var(--font-16);
    line-height: 20px;
    flex-grow: 1;
  }
}

.filterModal {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 7px 48px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 24px;

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    border: 0;
    outline: 0;
    background-color: transparent;
  }

  &__preloader {
    display: flex;
    justify-content: center;
    padding: 16px;
  }

  &__title {
    color: var(--primary-clr);
    font-family: Isidora Sans semibold;
    font-size: 26px;
  }

  &__listTitle {
    font-family: var(--font-base-bold);
    font-size: 18px;
    color: var(--primary-clr);
  }

  &__filterOptions {
    display: flex;
    flex-wrap: wrap;
  }

  &__btn {
    background-color: #30426d;
    border: solid 2px #30426d;
    border-radius: 50px;
    padding: 8px 20px;
    color: #fff;
    font-family: Isidora Sans;
    font-size: 16px;
    transition: 0.3s;

    &:hover {
      background-color: #fff;
      color: #30426d;
    }

    &--reset {
      background-color: #fff;
      color: #30426d;
      margin-right: 10px;
      &:hover {
        background-color: #30426d;
        color: #fff;
      }
    }

    &:disabled {
      background-color: #c3c3c3;
      color: #fff;
      border-color: #c3c3c3;
    }
  }

  &__radio {
    display: inline-block;
    min-width: 250px;
    margin-bottom: 8px;
  }

  &__input {
    left: 0;
    top: 0;
  }

  &__label {
    color: #4d4d4d;
    cursor: pointer;
    font-family: Isidora Sans;
    font-size: 16px;
  }
}
