.card{
    border-radius: 20px !important;
    box-shadow: 0px 4px 14px rgba(148, 148, 148, 0.1);
    border: none !important;
    min-height: 545px;
    .card-body{
        padding: 1rem 1rem 0 1rem;
    }
    .progress-bar-container{
        .progress{
            height: 5px;
            margin-bottom: 10px;
        }
    }
    .buy-now{
        background: var(--primary-clr);
        border-radius: 50px;
        min-width: 130px;
        color: var(--white);
        font-size: var(--font-16);
        font-family: var(--font-sbold);
        border: solid 2px var(--primary-clr);
        border: none;
        height: 40px;
        font-weight: 400;
    }

    .buy-now:hover{
        background: var(--white);
        color: var(--primary-clr);
        border: solid 2px var(--primary-clr);
    }
    .video-container-inner{
        padding: 10px;
        position: relative;
        height: 240px;
        overflow-y: hidden;
        img{
            border-radius: 14px;
            // margin: 15px;
            width: 100%;
            height: auto;
        }
        
        .tags-likes{
            position: absolute;
            top:10px;
            width: calc(100% - 40px);
            // padding: 10px;
            margin: 10px;
            .tags-row{
                position: absolute;
                top:0px;
                width: 100%;
            }
            .fav-container{
                width:50px;
                height: 50px;
                border-radius: 50%;
                position: absolute;
                right: 0;
                top: -5px;
                background: #FFFFFF;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 100%;
                    height: 23px;
                    cursor: pointer;
                }
            }
        }
    }
    .card-title{
        margin-bottom: 0;
        color: var(--primary-clr);
        font-size: var(--font-20);
        font-family: var(--font-sbold);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        margin-right: .5rem;
        min-height: 48px;
    }
    .card-body+.card-body{
        padding-top: 0.25rem;
    }
    .card-body{
        .like
        {
            min-width: 60px;
            display: flex;
            align-items: center;
            img{
                margin-right: 5px;
                cursor: pointer;
                margin-top: -10px;
            }
        }
        .event-time{
            font-size: var(--font-14);
            font-family: var(-font-sbold);
            color: --secondary-clr;
            color: var(--secondary-clr);
        }
        .card-text{
            margin-bottom: 0;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }

        // .card-footer-con{
        //     bottom: 20px;
        //     left:20px;
        //     position: absolute;
        // }
        .view-details{
            margin: 10px 0;
            color: var(--secondary-clr);
            font-family: var(--font-sbold);
            font-size: var(--font-14);
            cursor: pointer;
        }
        .course-price{
            color: var(--primary-clr);
            font-family: var(--font-sbold);
            font-size: var(--font-26);
        }
        .card-footer-con{
            position: absolute;
            left: 15px;
            bottom: 20px;
            width: calc(100% - 30px);
        }
    }
    .progress-bar-container{
        .progress {
            height: 5px;
            margin-bottom: 10px;
            .progress-bar{
                background: var(--secondary-clr);
            }
        }
        .progress-status{
            color: var(--secondary-clr);
        }
        .pregress-bar-caption{
            font-family: var(--base-font-family);
            font-size: var(--font-14);
            color: var(--primary-text);
        }
    }
  
}