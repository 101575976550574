@import "../../global.css";
@import "../../global-componant.css";

.footer{
    background: var(--primary-clr);
    padding: 5rem 0px;
    border-radius: 14px;
    .footerExplore li{
        cursor: pointer;

        a {
            text-decoration: none;
            color: inherit;
        }
    }
    .footer-flex-container{
        // flex-direction: column;
        h4{
            font-family: var(--font-sbold);
            font-size: 14px;
            color: var(--secondary-clr);
            margin-bottom: 20px;
            letter-spacing: 1px;
        }
        .address{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #FFFFFF;
            margin: 0;
            width: 170px;
            max-width: 100%;
        }

        ul{
            padding: 0;
            margin-bottom: 0;
            li{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.035em;
                text-transform: capitalize;
                color: #FFFFFF;
                // display: inline-block;
                padding-right: 30px;
            }
        }
        .stay-loop{
            input{
                background: transparent;
                border-radius: 22px;
                width: 240px;
                height: 46px;
                display: inline-block;
                border: solid 1px #fff;
                margin-bottom: 10px;
                color:#fff;
            }
            ::placeholder{
                color:#fff
            }
            button{
                background: var(--secondary-clr);
                border-radius: 41px;
                width: 104px;
                height: 47px;
                margin-left: 5px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                align-items: center;
                letter-spacing: -0.02em;
                color: #FFFFFF;
                border:solid 2px transparent;
            }
            button:hover, button.active{
                background: var(--white);
                border: solid 2px var(--secondary-clr);
                color:var(--secondary-clr);
            }
        }
        .social-icons{
            img{
                margin-right: 17px;
            }
        }

        ul{
            padding: 0;
            // margin-top: 55px;
            // margin-bottom: 38px;
            li{
                font-style: normal;
                font-weight: 500;
                // font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.035em;
                text-transform: capitalize;
                color: #FFFFFF;
                // display: inline-block;
                padding-right: 30px;
            }
        }
    }
    .bottom{
        p{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            // text-align: right;
            letter-spacing: 0.035em;
            text-transform: capitalize;
            color: #FFFFFF;
            margin: 0 !important;
        }
        .privacy-policy{
            margin-right: 1rem !important;
            a{
                color:var(--white);
                text-decoration: none;
            }
            a:hover{
                color:var(--white);
                text-decoration:underline;
            }
        }
    }
    hr.ft-hr{
        margin: 20px 0px 20px 0px;
        border-top: 1px solid #FFFFFF;
    }
   
}

.footer.pre-login-ft{
    background: var(--secondary-clr);
    .footer-flex-container{
        h4{
            color:var(--white);
        }
        .stay-loop{
            input{
                border:solid 1px var(--white) !important;
            }
            button{
                background: var(--white);
                color:var(--secondary-clr);
                border:solid 2px transparent;
            }
            button:hover, button:active{
                background: var(--secondary-clr);
                color: var(--white);
                border:solid 2px var(--white);
            }
        }

         
    }
}


// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) { 
   
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
   
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
    .footer{
        .footer-flex-container{
            ul{
                li{
                    padding-right: 0px ;
                }
            }
            .stay-loop{
                .text-container{
                    flex-direction: column !important;
                }
                input{
                    width:100% !important;
                    margin-bottom: 15px;
                    
                }
                
            }
        }
    }
    
}


@media (max-width: 767.98px) {
    .footer {
        padding: 3rem 1rem;
      
        .footer-flex-container{
            flex-direction: column;
            .block1{
                flex-direction: row !important;
                justify-content: space-between ; 
            }

            .block{
                h4{
                    margin-bottom: 10px;
                }
                margin-bottom: 20px;
            }

            .block4{
                .stay-loop{
                    width:100%;
                    input{
                        width:100% !important;
                        margin-bottom: 15px;
                    }
                    button{
                        margin-left: 0;
                        width:100%;
                    }
                }
            }
        }
        .bg-white{
            margin-left: 15px;
            margin-right: 15px;
        }
        .bottom{
            padding-left: 1rem !important;
            padding-right: 2rem !important;
            .footer-inner{
                flex-direction: column-reverse;
                align-items: start;
                .d-flex{
                    flex-direction: column-reverse;
                    align-items: start;
                }
                p{
                    margin: 5px 0 !important;
                }
            }
        }
    }
}


@media (max-width: 575.98px) { 
 
}
