@import "../../global.css";
@import "../../global-componant.css";

.event-container {
    background: var(--secondary-clr) no-repeat;
    background-position: right bottom;
    border-radius: 14px;
    h2{
        color:#fff;
        padding-bottom: 15px;
    }
}

.suggested-consult{
    height:auto;
    min-height: unset;
    .card{
        min-height: unset;
        .video-container-inner {
            padding: 10px;
            position: relative;
            height: 206px;
            overflow-y: hidden;
            border-radius: 14px;
          }
    }
    .img-container{
        border-radius: 14px;
        overflow:hidden;
        height: 195px;
    }
}
.profileContainer{
    padding-top: 1rem;
    padding-bottom: 1rem;
    .profile-header-container{
        background: var(--primary-clr);
        border-radius: 14px;
        color:var(--white);
        padding: 0 15px;
        .profile-progress-info{
           background: var(--white);
           border-radius: 14px;
           position: relative;
           .progress-msg{
            font-family: var(--base-font-family);
            font-size: 14px;
            line-height: 22px;
            color: #000000;
            z-index: 99;
            a{
                color:var(--secondary-clr);
                font-weight: bolder;
                text-decoration: none;
            }
            .card{
                min-height: auto;
                .MuiTypography-root{
                    font-size: 14px;
                    line-height: 22px;
                    color: var(--black);
                    font-family: var(--base-font-family);
                }
                .profile-steps{
                    border-top: solid 1px var(--primary-border);
                    max-width: inherit;
                    .MuiListItem-root.completed{
                        SVG{
                            fill:#4CC3B0 !important;
                            // path{
                            //     fill:var(-secondary-clr) !important;
                            // }
                        }
                        .MuiListItemText-root{
                            color: #4CC3B0;
                        }
                        
                    }
                    .MuiListItem-root{
                        .MuiListItemText-root{
                            padding-left: 0;
                        }
                        .MuiListItemButton-root{
                            padding: 3px 0 3px 3px;
                        }
                    }
                    
                }
                
            }
           }
        }
        .profile-becomemember-info{
            background: var(--secondary-clr);
            border-radius: 14px;
            position: relative;
            color: var(--white);
            padding: 26px;
            .member-title{
                h4{
                    font-weight: var(--font-sbold);
                    font-weight: 700;
                    font-size: 1.3rem;
                }
            }
            .member-price{
                .value{
                    font-weight: var(--font-sbold);
                    font-weight: 700;
                    font-size: 1.3rem;
                }
            }
        }
    }

    .activity-container{
        .activity-info-main{
            background: var(--white);
            border-radius: 14px;
            .activity-topic{
                color: var(--primary-clr);
                text-decoration: underline;
            }
            .navigateTo{
                color: var(--secondary-clr);
                text-decoration: underline;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .activity-info-main + .activity-info-main{
        margin-top: 20px;
    }

    .course-container-main{
        background:var(--white);
        border-radius: 14px;
        p{
            margin-bottom: .5rem;
        }
        .course-thumbnail{
            min-width: 262px;
            img{
                border-radius: 10px;
            }
        }
        .course-name{
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            font-family: var(--font-sbold);
        }
    }

    .profile-container-main{
        background:var(--white);
        border-radius: 14px;
        p{
            margin-bottom: .5rem;
        }
        .profile-section-thumbnail{
            min-width: 262px;
            width:30%;
            img{
                border-radius: 10px;
                max-height: 200px;
            }
        }
        .profile-section-detail{
            width:70%;
            .btn{
                min-width: 160px;
            }

            .sec-description{
                margin-bottom: 0;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            .profile-services-info{
                .section-time-container{
                    display: flex;
                    flex-direction: column;
                }
                ul{
                    list-style-type: none;
                    padding: 0;
                    margin: 10px 0 0 0;
                    // li{

                    // }
                }
            }
        }
        .section-title{
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            font-family: var(--font-sbold);
            color: var(--primary-clr);
        }
        .pregress-bar-caption{
            color:var(--secondary-clr);
        }
        .section-time-container{
            // font-weight: bold;
            font-family: var(--font-base-bold);
            color: var(--secondary-clr);
        }

        .senderinfo{
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #F57D7D;
            font-family: var(--base-font-family);
            .gift-received-date{
                word-break: keep-all;
                width: max-content;
            }
        }

        .coupencode-container{
            width:85%;
            .coupon-code-text{
                background: #fff;
                border: dashed 1px #4D4D4D !important;
                border-radius: 30px;
                height: auto;
                width: 350px;
                font-size: 12px;
                padding: .5rem 1rem;
                text-transform: uppercase;
            }
            .coupon-code-text:focus-visible {
                outline: none;
            }
            .copy-code{
                position: absolute;
                right: 15px;
                top: 11px;
                color: #4CC3B0;
                text-decoration: underline;
                font-weight: 600;
                cursor: pointer;
            }
        }

        
    }

    h5.section-title{
        font-family: var(--font-base-bold);
        font-size: 14px;
        color: var(--primary-clr);
    }

    .your-membership-main{
        .your-membership{
            .ur-membership-container{
                background: var(--pink);
                border-radius:14px;
                p{
                    color: var(--white);
                }
                .plan-price{
                    font-family: var(--font-base-bold);
                    font-size: 44px;

                }
            }
            
        }
        .upgrade-membership{
            .upgrade-msg{
                font-family: var(--base-font-family);
                font-size: 20px;
                color: var(--primary-clr);
            }
        }
    }

    // .upgrade-membership{
    //     .member_plan {
    //         border-radius: 10px;
    //         height: 100%;
    //         background: #fff;
    //         .plan_head{
    //           margin-bottom: 15px;
    //           border-radius: 10px 10px 0px 0px;
    //           text-align: left;
    //           h3{
    //             .plan-nm{
    //                 color:var(--white);
    //                 font-size: 26px;
    //                 font-weight: 500;
    //                 line-height: 24px;
    //                 // padding: 1.5rem 0 1.5rem 30px;
    //                 margin-right: 5px;
    //                 }
    //                 color:var(--white);
    //                 font-weight: 600;
    //                 font-size: 24px;
    //                 line-height: 24px;
                   
    //           }
    //         }
    //         .Plan-price-main{
    //           padding-bottom:15px;
    //           margin-bottom: 15px;
    //           border-bottom: solid .5px var(--chat-bubble-sender);
    //           min-height: 112px;
    //           .pCost-old{
    //             font-family: var(--base-font-family);
    //             font-size: var(--font-h2-34);
    //             text-decoration: line-through;
    //             color:var(--primary-clr);
    //             margin-right: 5px;
    //             font-weight: var(--font-wt300);
    //           }
    //           .pCost{
    //             font-family: var(--base-font-family);
    //             font-size: var(--font-h2-34);
    //             color:var(--primary-clr);
    //             font-weight: var(--font-wt900);
    //           }
    //           .membership-cycle{
    //             font-family: var(--base-font-family);
    //             font-size: var(--font-16);
    //             line-height: 50px;
    //             margin-left: 5px;
    //           }
    //           .plan-price-Total{
    //             font-family: var(--base-font-family);
    //             color:var(--primary-text);
    //             font-size: var(--font-14);
    //           }
    //         }
    //         p {
    //           font-family: var(--base-font-family);
    //         //   font-weight: var(--font-wt900);
    //           font-size: var(--font-16);
    //           line-height: 22px;
    //           color: #4D4D4D;
    //           padding: 0px 0px;
    //         }

    //         .plan_content {
    //             // padding: 10px 30px;
    //             .plan-details-list{
    //               margin-bottom: 65px;
    //               p{
    //                 ul{
    //                   li{
    //                     margin:10px 0;
    //                     // background:url('../../Assets/img/login/membership-bullets.svg') no-repeat;
    //                     padding-left: 2rem;
    //                     line-height: 30px;
    //                     font-weight: normal;
    //                     word-break: keep-all;
    //                   }
    //                   padding-left: 0;
    //                 }
    //               }
    //             }
    //           }
    //         .monthly-dtls-list{
    //           padding: 0 !important;
    //           li{
    //             padding: 10px 0;
    //             font-family: var(--base-font-family);
    //             display: table;
    //             color: var(--primary-text);
    //             img{
    //               margin-right: 10px;
    //               // display: table-column;
    //               // monthy-planfloat: left;
    //             }
    //             p{
    //               font-weight: normal;
    //               margin: 0;
    //             }
    //           }
    //         }
    //     }
    //     .plan-option1{
    //         border: 1px solid var(--quaterly-clr);
    //         position: relative;
    //         .plan-saving{
    //             color:var(--quaterly-clr);
    //         }
    //         .plan_head {
    //             background: var(--quaterly-clr);
    //             }
    //         .btn_trial{
    //             background: var(--quaterly-clr);
    //             width: auto;
    //             border:solid 2px var(--quaterly-clr);
    //             border-radius: 14px;
    //         }
    //         .btn_trial:hover{
    //             background: var(--white);
    //             width: auto;
    //             border:solid 2px var(--quaterly-clr);
    //             color:var(--quaterly-clr);
    //         }
    //     }

    //     .plan-option2{
    //         border: 1px solid var(--halfyearly-clr);
    //         position: relative;
    //         .plan-saving{
    //         color:var(--halfyearly-clr);
    //         }
    //         .plan_head {
    //         background: var(--halfyearly-clr);
    //         }
    //         .btn_trial{
    //         background: var(--halfyearly-clr);
    //         width: auto;
    //         border:solid 2px var(--halfyearly-clr);
    //         border-radius: 14px;
    //         }
    //     }
    // }

    .payment-main{
        .payment-details-container{
            background: var(--white);
            border-radius: 10px;
            
            .payment-method{
                div:first-child{
                    flex-grow: 1;
                    width:10%
                }
                div:nth-child(2){
                    flex-grow: 2;
                    width:30%;
                }
                div:nth-child(3){
                    flex-grow: 2;
                    width:20%;
                }
                div:nth-child(4){
                    flex-grow: 1;
                    width:15%;
                }
                div:nth-child(5){
                    flex-grow: 1;
                    width:15%;
                }
                div:nth-child(6){
                    flex-grow: 1;
                    width:10%;
                }
                a{
                    color:var(--secondary-clr);
                    font-weight: bold;
                }
            }
        }
    }

    .payment-history-details{
        .payment-history-container{
            background: var(--white);
            border-radius: 10px;
            a{
                color:var(--secondary-clr);
                font-weight: bold;
            }
            div:first-child{
                width:45%
            }
            div:nth-child(2){
                width:20%
            }
            div:nth-child(3){
                width:20%
            }
            div:nth-child(4){
                width:15%;
                text-align: right;
            }
        }
    }

    .earn-consult-container{
        background: var(--white);
        border-radius: 10px;
        .progress-bar-container{
            .profile-competion-msg{
                p{
                    color:#000;
                    span{
                        color:var(--secondary-clr);
                        font-weight: bolder;
                    }
                }
            }
            border-bottom: solid 1px #EEEEEE;
        }

        .profile-completion-container{
            .profile-steps{
                .MuiListItem-root.completed{
                    SVG {
                        fill: #4CC3B0 !important;
                    }
                }
                .MuiListItemText-root {
                    padding-left: 0;
                }
            }
            a{
                color: var(--secondary-clr);
                font-weight: bolder;
            }
        }
    }

    .invite-friend-container{
        .invite-friend-inner{
            width:570px; 
            margin: 0 auto;
        }
    }

    .offer-container-main{
        .offer-container{
            .container-inner{
                padding: 25px 25px;
                border-radius: 10px;
                position: relative;
            }
            .coupon-container{
                background: var(--white);
                border: dashed 1px #4D4D4D;
                border-radius: 50px;
                padding: 15px 30px;
            }

            .discounted-offer{
                position: absolute;
                background: var(--primary-clr);
                border-radius: 10px 0 0 0;
               
                right: -10px;
                top: 25px;
                .discounted-offer-inner{
                    padding: 15px 10px 10px 10px;
                    color:#fff;
                    font-size: 12px;
                    font-family: var(--base-font-family);
                    span.value{
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 12px;
                    }
                }
                &::after{
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-top: solid 10px #121e3a;;
                    border-left: solid 0px transparent;
                    border-right: solid 10px transparent;
                    position: absolute;
                    right: 0;
                }
            }
           
            .offer-info{
                color:var(--white);
                .offer-title{
                    font-weight: 600;
                    font-size: 28px;
                    color:var(--white);
                }
                p{
                    color:var(--white);
                }
            }
    
            .coupon-container{
                .coupon-value{
                    font-size: 16px;
                    text-transform: uppercase;
                }
    
                a{
                    color: var(--secondary-clr);
                    font-weight: bolder; 
                }
            }
    
        }

       
        .offer1{
            background: var(--pastel-purple);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .offer2{
            background: var(--secondary-clr);
        }

        .offer3{
            background: var(--pink);
        }
    }

    .profile-container{
        background: var(--white);
        border-radius: 10px;
    }

    .parent-ul{
        li{
            position: relative;
            a{
                font-size: 18px;
                color: var(--primary-clr);
                font-family: var(--font-base-bold);
                text-decoration: none;
            }
            margin-bottom: 10px;
            ul > *{
                // position: absolute;
                display: none;
                opacity: 0;
                // transition: ease-in-out opacity 1s;
            }
            ul{
               li{
                a{
                    font-weight: bold;
                    
                }
                :hover{
                    color:var(--secondary-clr);
                }
               }
               
            }
        }
        :hover{
        color:var(--secondary-clr);
        ul > *{
            display: block;
            opacity: 1;
            // padding-left: 20px;
            }
            ul{
                li{
                    a{
                        font-size: 14px;
                        color: var(--primary-text);
                        margin-bottom: 0;
                        font-family: var(--base-font-family);
                    }
                    a:hover, a.active{
                        font-family: var(--font-base-bold);
                        &::before{
                            text-align : center;
                            font-size : 1em;
                            color: #4CC3B0;
                            content: "—";
                            margin-right: 5px;
                            // margin-left: -20px;
                            
                        }
                    }
                    
                }
                :last-child{
                    margin-bottom: 0; 
                }
                margin-top: 5px;
            }
        }
        li.active{
            color:var(--secondary-clr)
        }
        .notification{
            background: #F57D7D;
            color:var(--white);
            border-radius: 50%;
            width:15px;
            height:15px;
            padding: 5px 8px;
            font-size: 12px;
        }
    }// navigation css

    .sidebar-nav{
        li{
            button{
                font-size: 18px;
                color: var(--secondary-clr);
                font-family: var(--font-base-bold);
                text-decoration: none;
                padding: 0;
                text-align: start;
            }
            button.collapsed{
                color: var(--primary-clr);
            }
            button.btn-check:focus+.btn, .btn:focus{
                box-shadow: none;
            }
            .collapsible-nav{
                margin-top: 5px;
                li {
                    // position: relative;
                    margin-bottom: 10px;
                    a{
                        font-size: 14px;
                        color: var(--primary-text);
                        margin-bottom: 0;
                        font-family: var(--base-font-family);
                        text-decoration: none;
                        
                    }
                    a:hover, a.active{
                        font-family: var(--font-base-bold);
                        &::before{
                            text-align : center;
                            font-size : 1em;
                            color: #4CC3B0;
                            content: "—";
                            margin-right: 5px;
                            // margin-left: -20px;
                            
                        }
                    }
                }
            }
            .notification{
                background: #F57D7D;
                color: var(--white);
                border-radius: 50%;
                width: 15px;
                height: 15px;
                padding: 5px 5px;
                font-size: 12px;
                // position: absolute;
                margin: 5px 0 0 5px;
                line-height: 8px;
                text-align: center;
            }
        }
    }

    .profile-section-bottom{
        h2{
            font-family: var(--base-font-family);
            font-weight: 600;
            font-size: var(--font-h1-42);
        }
        .seperator{
            margin-top: 50px;
            border-top:solid 1px #ccc;
            padding-top: 50px;
        }
    }

    .download-icon{
        cursor: pointer;
    }
}



.become-member-container{
    background: url(../../Assets/img/course/become-member.png) no-repeat;
    // background-size: cover;
    border-radius: 14px;
    background-size: cover;
    .become-meber-captions{
        h3{
            color:var(--white);
            font-family: var(--base-font-family);
            font-size: var(--font-h2-34);
            font-weight: 300;
            span{
                display: block;
                font-weight: 700;
                color: var(--secondary-clr);
            }
        }
        p{
            font-size: var(--font-14);
            font-weight: 300;
            color: var(--white);
            margin: 0;
            .strong{
                font-weight: bold;
                color:var(--white);
            }
        }
        .price-dtls{
            .pvalue{
                color:var(--white);
                font-family: var(--font-sbold);
                font-size: var(--font-h1-42);
                font-weight: 900;
                font-weight: var(--font-wt900);
            }
        }
        .become-member-pointers{
            ol{
                padding: 0;
            }
            li{
                list-style-type: none;
                
                .li-counter{
                    font-size: 16px;
                    color:var(--secondary-clr) !important; 
                    font-family: var(--font-base-bold);
                    padding-right: 10px;
                    display: table-cell;
                }
                p{
                    font-size: 14px;
                    display: table;
                }
            }
        }
    }
}

.sidebar-nav{
    // background: #fff;
    border-radius: 14px;
    padding: 10px !important;
    height: auto;
    overflow: visible;
    position: relative;
    li{
        // padding-left: 10px;
        position: relative;
    }
}

.reschedule-note{
    background: rgba(76, 197, 178, 0.1);
    border:#4CC3B0;
    color:#4CC3B0;
    padding: 15px;
    border-radius: 15px;
}

.ProfileUserWrapper {
    max-width: 1300px;
    margin: 0 auto;
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .profile-header-container{
        .profile-progress-info{
            .progress-ms{
                position: relative !important;
            }
        }
    } 
    .profile-progress-info{
        .progress-msg{
            position: relative !important;
        }
    }

    .upgrade-membership{
        .member_plan{
            width: 100% !important;
        }
    }

    .payment-main{
        .payment-details-container{
            width: 340px;
            overflow-x: scroll;
            .payment-method{
                width:700px !important;
            }
        }
    }

    .payment-history-container{
        width: 340px;
        overflow-x: scroll;
        .payment-history-list {
            width:700px !important;
        }
    }

    .offer-container-main{
        .offer-container{
            width:100% !important;
        }
    }

    .invite-friend-container{
        .invite-friend-inner{
            width:100% !important;
        }
    }

    .profileContainer{
        .your-membership-main{
            .your-membership{
                .ur-membership-container{
                    .plan-price {
                        font-size: 22px;
                    }
                }
            }
        }
        .invite-friend-container{
            .invite-friend-inner {
                width: 100% !important;
            }
        }
        .btn-conatainer{
            width:100%;
            .book-download{
                display: flex;
                justify-content: space-between;
            }
        }
    }

    
 
}


// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .paddingLR-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .profile-header-container{
        .profile-progress-info{
            .progress-msg{
                position: relative !important;
            }
        }
    } 

    .profileContainer{
        .your-membership-main{
            .your-membership{
                .ur-membership-container{
                    .plan-price {
                        font-size: 30px;
                    }
                }
            }
        }
        
        // .invite-friend-container {
        //     .invite-friend-inner {
        //         width: 505px !important;
        //     }
        // }
    }

    .profileContainer{
        .profile-container-main{
            .profile-section-thumbnail{
                width:100%;
                text-align: start !important;
            }
            .profile-section-detail{
                width:100%;
                .coupencode-container {
                    width: 100%;
                }
            }
            .btn-conatainer{
                margin-left: 0 !important;
                margin-right: 0 !important;
                .book-download{
                    display: flex;
                    button + button{
                        margin-left: 15px;
                    }
                }
            }
            .upcoming{
                width: 100%;
                display: flex;
                // justify-content: space-between;
                label + button{
                    margin-left: 10px;
                }
            }
            

        }
        .sidebar-nav{
            background: #fff;
            li {
                button {
                    font-size: 13px;
                }
            }
        }
    }

    // .service-container{
    //     .service-header{
    //         .service-header-container{
    //             .service-banner-img{
    //                 order:1;
    //                 border-radius: 14px 14px 0 0;
    //                 min-height: 400px;
    //             }
    //             .service-caption-container{
    //                 order:2;
    //                 border-radius: 0 0 14px 14px;
    //             }
    //         }
    //     }
    //     .consult-more-info{
    //         .service-btn-container{
    //             .service-btn{
    //                 max-width: 320px;
    //             }
    //         }
    //     }
    // }

    .offer-container-main .offer-container {
        width: 100% !important;
    }
}

@media (max-width: 991.98px) and (min-width:767.99px) { 
.profileContainer{
    .profile-header{
        padding-left: 0 !important;
        padding-right: 0 !important;
        .user-profie-img{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    .profile-container-main{
        .profile-container-inner{
            flex-direction: column !important;
            align-items: flex-start !important;
            padding: 15px;
            .profile-section-detail{
                margin: 15px 0 !important;
                width:100%;
                align-items: start !important;
            }
            
            .gift-info-all{
                flex-direction: column !important;
            }
            .btn-conatainer{
                margin-left: 0 !important;
                margin-right: 0 !important;
                .book-download{
                    display: flex;
                    button + button{
                        margin-left: 15px;
                    }
                }
            }
            .section-time-container{
                width:100% !important;
            }
            .coupencode-container{
                width:100%;
            }
            .profile-section-thumbnail{
                min-width: 170px;
            }
            .upcoming{
                width: 100%;
                display: flex;
                // justify-content: space-between;
                label + button{
                    margin-left: 10px;
                }
            }
            
        }
    }
    
    }
   

}


// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
    .invite-friend-container {
        .invite-friend-inner {
            width: 505px !important;
        }
    }

    .offer-container-main{
        .offer-container{
            .offer-info{
                .offer-title {
                    font-weight: 600;
                    font-size: 24px !important;
                }
            }
        }
    }

    // .service-container{
    //     .consult-more-info{
    //         .service-btn-container{
    //             .service-btn{
    //                 min-width: 320px;
    //             }
    //         }
    //     }
    // }

    .profileContainer{
        .profile-container-main{
            .profile-container-inner{
                flex-direction: column !important;
                align-items: flex-start !important;
            }
            
            .profile-section-detail{
                flex-direction: column !important;
                // align-items: flex-start;
                .btn-conatainer
                {
                margin-left: 0 !important;
                margin-right: auto !important
                }
                .profile-sec-info{
                    .section-time-container{
                        width:100% !important;
                    }
                }
            }
        }
        .sidebar-nav{
            li {
                button {
                    font-size: 16px;
                }
            }
            // li.tier1::after{
            //     width: 30px;
            //     display: flex;
            //     height: 30px;
            //     justify-content: flex-end;
            //     content: '';
            //     color: #30426d;
            //     background: url('../../Assets/img/icons/Chevron Down.svg') no-repeat;
            //     position: absolute;
            //     right: -5px;
            //     top: 0px;
            // }
        }
        // .sidebar-nav.mobile-nav-close{
        //     height: 40px;
        //     overflow: hidden;
        // }

        // .sidebar-nav.mobile-nav-open {
        //     height: auto;
        // }
    }
    
 }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
    .profileContainer {
        .profile-container-main {
            .profile-section-thumbnail {
                min-width: 180px !important;
            }
            .profile-section-detail{
                .gift-info-all{
                    flex-direction: column !important;
                }
            }
        }
    }

   
 }

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) { 
    
 }






