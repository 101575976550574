.messageEditorSticky {
  position: sticky;
  top: calc(100dvh - 16px);
  width: calc(100% - 16px * 2);
  margin: 0 16px;

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.messageEditor {
  width: 100%;
  border-radius: 13px;
  background-color: #eeeeee;
  padding: 16px;
  display: flex;
  align-items: flex-end;

  &__send {
    margin-left: 12px;
    flex-shrink: 0;
    outline: 0;
    border: 0;
    background-color: #30426d;
    width: 43.8px;
    height: 43.8px;
    border-radius: 50px;
    overflow: hidden;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }

    &--disabled {
      background-color: #929292;
      &:hover {
        opacity: 1;
        cursor: default;
      }
    }
  }

  .ql-picker-item {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.13);
  }
  .ql-toolbar.ql-snow {
    border: 0;
    transition: 0.3s;
    max-height: 0;
    overflow: hidden;
    padding: 0;
  }
  .ql-container.ql-snow {
    border: 0;
    background-color: #fff;
    border-radius: 13px;
    max-height: 43.88px;
    transition: 0.3s;
  }
  .ql-container .ql-editor {
    max-height: 43.88px;
    transition: 0.3s;
    overflow: hidden;
  }
  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    top: auto;
    bottom: 100%;
  }

  .ql-formats {
    margin-right: 0 !important;
  }

  &__editor {
    flex-grow: 1;
    width: 100%;

    &--focused {
      .ql-toolbar.ql-snow {
        padding: 0 0 16px 0;
        max-height: 100px;
        overflow: visible;
      }
      .ql-container.ql-snow {
        max-height: 200px;
      }

      .ql-container .ql-editor {
        max-height: 200px;
        overflow: auto;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .messageEditorSticky {
    top: calc(100dvh);
    width: 100%;
    margin: 0;
  }
  .messageEditor {
    padding: 8px 16px;
    width: 100%;
    margin: 0;
    border-radius: 0;
    flex-direction: column;
    align-items: end;

    &__send {
      margin-left: 4px;
      margin-top: 8px;
      border-radius: 13px;
      width: 35px;
      height: 35px;
    }
  }
}

@media (max-width: 359.98px) {
  .messageEditor {
    padding: 8px;
  }
}
