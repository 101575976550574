.congratsPopUpWindow {
    .modal-dialog {
        max-width: 500px !important;
    }

    .modal-content {
        border-radius: 14px;
        background-color: transparent;
    }
}

.congratsPopUp {
    position: relative;
    text-align: center;
    background: url("../../Assets/img/login/congratulation-bg.svg"),
        var(--secondary-clr) !important;
    box-shadow: 0px 15px 58px rgba(0, 0, 0, 0.13) !important;
    border-radius: 14px;
    border: none !important;
    background-position: center;
    padding: 66px 64px;

    h3,
    h4,
    p {
        font-family: "Isidora Sans";
        font-style: normal;
        color: var(--step-txt-clr);
        padding: 0;
        margin: 0;
    }

    h3 {
        font-weight: 600;
        font-size: 34px;
        line-height: 42px;
    }

    h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-top: 30px;
    }

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 30px;
    }

    .primary-blue-small-btn-40 {
        min-height: 47px;
        width: 100%;
        margin-top: 30px;
    }
}

.congratsPopUpCross {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    transition: 0.3s;
    height: 16px;
    &:hover {
        opacity: 0.5;
    }

    svg {
        vertical-align: top;
    }
}

@media (max-width: 767.98px) {
    .congratsPopUp {
        padding: 33px;
    }
}

@media (max-width: 575.98px) {
    .congratsPopUp {
        padding: 33px 16px ;
    }
}