.bumpMonthFreeWrapper {
  max-width: 1178px;
  width: 100%;
  margin: 120px auto 0;
}

.bumpMonthFree {
  width: 100%;
  border-radius: 14px;
  background: #b693c8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 102px 57px 102px;

  .priceBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .heading2 {
    margin-right: 65px;
    max-width: 438px;
    width: 100%;
    span {
      color: #30426d;
    }
  }
  .heading2,
  .priceAmount {
    font-family: "Isidora Sans medium";
    font-size: 50px !important;
    line-height: 58px;
    text-align: left;
    color: #fff;
    padding: 0;

    span {
      font-family: "Isidora Sans bold";
    }
  }
  .title {
    font-family: "Isidora Sans bold";
    font-size: 18px;
    font-style: normal;
    color: #30426d;
  }

  @media (max-width: 991.98px) {
    padding: 36px 14px 58px;
    flex-wrap: wrap;
    flex-direction: column;

    .heading2 {
      font-size: 34px !important;
      line-height: 42px;
      text-align: center;
      max-width: 315px;
      margin: 0;
    }

    .priceBox {
      width: fit-content;
      flex-direction: column;
      align-items: center;
      margin-top: 23px;
    }

    .priceBoxPrices {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .priceAmount {
      margin-top: 7px;
      margin-bottom: 23px;
      text-align: center;
      font-size: 42px !important;
    }
  }
}
