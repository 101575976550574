.btn-container-css {
    position: absolute;
    right: 19px;
    top: 50%;
    transform: translateY(-50%);
}

.btn-container-css.open-btn {
    position: absolute;
    right: 50%;
    top: unset;
    transform: translateX(50%);
    bottom: 25px;
}

.main-stepDiv {
    position: relative;
}

.calender-container + .timeslot-container{
    margin-bottom: 30px;
}

.timeslot-container{
    margin-bottom: 40px;
}
// .btn-container-css button{
//     margin-bottom: -13px;
// }
.loader-wash{
    display: flex;
    align-items: center;
    justify-content: center;
}
// .user-info{
//     form{
//         label{
//             font-family: var(--base-font-family);
//             color: var(--primary-clr);
//             font-weight: var(--font-wt700);
//             font-size: 16px;
//             line-height: 20px;
//             letter-spacing: -0.02em;
//             margin-bottom: 10px;
//             display:block
//         }

//         input[type=text], input[type=password], input[type=text],input[type=textarea] 
//         {
//             background: var(--white);
//             border: 1px solid var(--primary-border) !important;
//             box-sizing: border-box;
//             border-radius: 40px;
//             height: 50px;
//             font-family: var(--base-font-family);
//             font-style: normal !important;
//             font-weight: 400 !important;
//             font-size: 16px !important;
//             line-height: 28px !important;
//             align-items: center;
//             color: var(--primary-text);
//             padding: 0.375rem 1rem;
//             -webkit-appearance: none;
//             width:100%;
//             display: block;
//             margin-bottom: 1.5rem; 
//         }

//         input[type=textarea]{
//             min-height: 100px;
//             border-radius: 10px;
//             word-wrap: break-word;
//         }

//         input[type='radio']{
//             width: 1.5em !important;
//             height: 1.5em !important;
//             margin-right: 10px;
//             cursor: pointer;
//             position: relative;
//             opacity: 1;
//             vertical-align: top;
//             background-color: #fff;
//             background-repeat: no-repeat;
//             background-position: center;
//             background-size: contain;
//             border: 1px solid rgba(0,0,0,.25);
//             -webkit-appearance: none;
//             border-radius: 50%;
//             margin-bottom: 1.5rem;
//             // accent-color: #4CC3B0;
//         }

//         input[type='radio']:checked {
//             background:#4CC3B0 url("../../../../Assets/img/Ellipse-white.svg") no-repeat center center;
//             border: #949494 !important;
//         }

//         input[type=radio] + label{
//             display: inline-block;
//             margin-right: 20px;
//         }
        

//         // input[type='radio']:checked{
//         //     background-image: url("../../../../Assets/img/Ellipse-white.svg") no-repeat center center;
//         // }
//         // input[type="checkbox"]:checked + label:after{
//         //     background: #4CC3B0 !important;
//         //     border-color: #949494 !important;
//         // }
            
//         input[type='checkbox']{
//             width: 1.5em !important;
//             height: 1.5em !important;
//             margin-right: 10px;
//             cursor: pointer;
//             position: relative;
//             opacity: 1;
//             vertical-align: top;
//             background-color: #fff;
//             background-repeat: no-repeat;
//             background-position: center;
//             background-size: contain;
//             border: 1px solid rgba(0,0,0,.25);
//             -webkit-appearance: none;
//             border-radius: 4px;
//             margin-bottom: 1.5rem;
//         }
        
//         input[type=checkbox] + label{
//             display: inline-block;
//             margin-right: 20px;
//         }

//         input[type='checkbox']:checked {
//             background:#4CC3B0 url("../../../../Assets/img/checkbox-check.svg") no-repeat center center;
//             border: #949494 !important;
//         }

//         input:focus-visible{
//             outline: none;
//         }
//     }
// }