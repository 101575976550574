@import "../../global.css";
@import "../../global-componant.css";

.sponsorship-container{
    .sponsorship-discription-section{
        .sponsor-section-title {
            font-weight: 500;
            font-size: 42px;
            color: var(--primary-clr);
            .strong{
                font-weight: 700;
                font-family: var(--font-base-bold);
            }
        }
        .sponsor_description{
            p{
                font-size: 20px;
            }
        }
    }
    .all-sponsor-container{
        .all-sponsor{
            .sponsor-logo-container{
                width:20%;
                padding: 1rem 0;
            }
        }
    }
    .required{
        color:red;
        margin-left: 5px;
    }


    .our-audience{
        background: url(../../Assets/img/sponsors/ourAudience.svg) #30426D no-repeat;
        border-radius: 14px;
        background-position: left top;
        color:var(--white);
        h2{
            color: var(--white);
        }
        .statics{
            .count{
                font-size: 67px;
                font-family: var(--font-base-bold);
            }
            .value{
                font-size: 77px;
                font-family:  var(--Respondent-Bold);
            }
            .sm-follorers{
                color:var(--monthly-clr)
            }
            .nurture-member{
                color:var(--halfyearly-clr)
            }
            .email-subscriber{
                color:var(--orange)
            }
            .podcast-download{
                color: var(--quaterly-clr);
            }
            .webinars-view{
                color: var(--pink-lt);
            }
            
        }
        .stats-head{
            margin-top: -50px;
        }
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .sponsorship-container {
        .all-sponsor-container{
            .all-sponsor {
                .sponsor-logo-container {
                    width: 50%;
                }
            }
        }
    }
}


// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .sponsorship-container {
        .all-sponsor-container{
            .all-sponsor {
                .sponsor-logo-container {
                    width: 50%;
                }
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
    
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) { 
   
}
