.service-container{
    .accordionSection {
        max-width: 700px;
        margin: 0 auto;
    }
    .link{
        color: blue;
        text-decoration: underline;
        cursor: pointer;
    }
    .service-header{
        .service-header-container{
            border-radius: 14px;
            overflow: hidden;
            .srv-col{
                background: var(--primary-clr);
                border-radius: 14px;
            }
            
            .service-content{
                color:var(--white);
                .right-angle{
                    .MuiSvgIcon-root{
                        fill:#4CC3B0 !important
                    }
                }
                .service-name{
                    color:var(--white);
                    text-align: left;
                    font-family: var(--base-font-family);
                    font-size: 32px !important;
                    line-height: 38px;
                    margin-bottom: 0 !important;
                    padding: 0;
                }
                .service-name + .survice-subtitle{
                    margin-top: 15px;
                    margin-bottom: 15px;;
                    color: var(--secondary-clr);
                    font-size: 18px;
                    font-family: var(--font-sbold);
                }
                .service-description{
                    padding-top: 15px;
                    border-top:solid 1px #fff;
                    h3{
                        color:var(--secondary-clr);
                        font-family: var(--font-sbold);
                        font-size: 28px;
                        font-weight: 700;
                    }
                    p{
                        color:var(--white);
                    }
                }

            }

            .service-banner-img-wrapper {
                padding: 24px 34px 24px 0;
            }

            .service-banner-img{
                // background-size: contain;
                // background-repeat: no-repeat;
                // background-position: center center;
                min-height: 100%;

                img {
                    object-fit: contain;
                    height: 100%;
                    max-width: 100%;
                    max-height: 600px;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
       
        .service-information{
            display: grid; 
            grid-template-columns: 160px 1fr;
            grid-template-rows: 1fr; 
            gap: 0px 8px; 
            grid-template-areas: ". .";

            .info-left{
                .pri-text{
                    color:var(--secondary-clr);
                    font-size: var(--font-16);
                    font-family: var(--font-sbold);
                }
                
            }
            ul{
                list-style-type: disc;
                padding-left:1rem
            }
        }
        .pri-text{
            color:var(--secondary-clr);
            font-size: var(--font-16);
            font-family: var(--font-sbold);
        }
            
    }
    
    
    .consult-more-info{
        h3{
            font-family: var(--font-base-bold);
            font-size: 24px;
            color:var(--secondary-clr);
            font-weight: 700;
        }
        p{
            margin-bottom: .5rem;
            .link{
                color: var(--secondary-clr);
                font-weight: bolder;
                letter-spacing: 0;
            }
        }
        ul{
            list-style-type:disc;
            li{
                margin-bottom: 5px;
            }
        }

        .topic-cover{
            ul{
                color:var(--primary-text);
                padding-left: 1rem;
                li{
                    font-size: var(--font-18);
                    color: var(--primary-text);
                }
            }
        }

        .service-btn-container{
            .service-btn{
                width: 100%;
                border-radius: var(--br-radius-40) !important;
                font-style: normal;
                font-weight: 600 !important;
                font-size: 20px;
                line-height: 26px;
                text-align: left;
                color: #FFFFFF !important;
                cursor: pointer;
                max-width: 370px;
                padding: 1.2rem;
                min-width: 370px;
                flex-shrink: 0;
                position: relative;
                letter-spacing: 1px;
                .consult-price{
                    // position:absolute;
                    // right:20px;
                    font-size: 26px;
                    font-family: var(--font-base-bold);
                    
                    span.small{
                        font-size: 14px;
                        font-family: var(--base-font-family);
                    }
                }
            }
        }

        .btn-container-right{
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            cursor: pointer;
            
        }

        .service-primary-container{
            .service-btn{
                background: var(--primary-clr) !important;
                border: 2px solid var(--primary-clr);
            }
            .btn-container-right{
                color:var(--primary-clr);
                .gift-caption{
                    color:var(--primary-clr);
                }
            }
            .service-btn:hover{
                background: var(--white) !important;
                color:var(--primary-clr) !important;
            }
            .btn-container-right:hover{
                text-decoration: underline;
            }
        }

        .service-secondary-continer{
            .service-btn{
                background: var(--pastel-purple) !important;
                border: 2px solid var(--pastel-purple);
            }
            .btn-container-right{
                color:var(--pastel-purple);
                .gift-caption{
                    color:var(--pastel-purple);
                }
            }
            .service-btn:hover{
                background: var(--white) !important;
                color:var(--pastel-purple) !important;
            }
            .btn-container-right:hover{
                text-decoration: underline;
            }
        }
        
        .service-tertiary-continer{
            .service-btn{
                background: var(--secondary-clr) !important;
                border: 2px solid var(--secondary-clr);
            }
           
            .service-btn:hover{
                background: var(--white) !important;
                color:var(--secondary-clr) !important;
            }
            .btn-container-right:hover{
                text-decoration: underline;
            }
        }

        .service-btn-pri-container {
            .btn-container-right{
                color:var(--primary-clr);
                .gift-caption{
                    color:var(--primary-clr);
                }
            }
        }

      
        .service-btn-sec-container {
            .btn-container-right{
                color:var(--pastel-purple);
                .gift-caption{
                    color:var(--pastel-purple);
                }
            }
        }

        .service-btn-ter-container{
            .btn-container-right{
                color:var(--secondary-clr);
                .gift-caption{
                    color:var(--secondary-clr);
                }
            }
        }
    }
    p{
        font-size: var(--font-18);
    }
}

.become-member-container{
    background: url(../../Assets/img/course/become-member.png) no-repeat;
    // background-size: cover;
    border-radius: 14px;
    background-size: cover;
    .become-meber-captions{
        h3{
            color:var(--white);
            font-family: var(--base-font-family);
            font-size: var(--font-h2-34);
            font-weight: 300;
            span{
                display: block;
                font-weight: 700;
                color: var(--secondary-clr);
            }
        }
        p{
            font-size: var(--font-14);
            font-weight: 300;
            color: var(--white);
            margin: 0;
            .strong{
                font-weight: bold;
                color:var(--white);
            }
        }
        .price-dtls{
            .pvalue{
                color:var(--white);
                font-family: var(--font-sbold);
                font-size: var(--font-h1-42);
                font-weight: 900;
                font-weight: var(--font-wt900);
            }
        }
        .become-member-pointers{
            ol{
                padding: 0;
            }
            li{
                list-style-type: none;
                
                .li-counter{
                    font-size: 16px;
                    color:var(--secondary-clr) !important; 
                    font-family: var(--font-base-bold);
                    padding-right: 10px;
                    display: table-cell;
                }
                p{
                    font-size: 14px;
                    display: table;
                }
            }
        }
    }
}








// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {  }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .service-container {
        .consult-more-info {
            .service-btn-container{
                max-width: 370px;
                width: 100%;
                
                .service-btn{
                    font-size: 18px;
                    min-width: 275px;
                    width: 100%;
                    padding: 1rem;
                    .consult-price {
                        font-size: 22px;
                        .small{
                            margin-right: 0.25rem !important;
                        }
                    }
                }
            }
        }
        .service-header {
            .service-information{
                grid-template-columns: 1fr; 
                grid-template-areas: ".";
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .service-container{
        
        .service-btn-sec-container {
            .service-btn-sec-container-inner-flex{
                flex-direction: column !important;
            }
        }
        .service-header {
            .service-header-container{
                .service-banner-img-wrapper {
                    padding: 12px 24px;
                    padding-bottom: 0;
                }
                .service-content{
                    .service-name{
                        line-height: 38px;;
                    }
                }
                
            }
        }

        .service-header {
            .service-header-container{
                .service-banner-img {
                    img {
                        border-radius: 14px 14px 0 0 !important;
                    }
                }
            }
            
        }
    }
   
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
    .service-container {
        .service-header {
            .service-header-container{
                .service-banner-img {
                    img {
                        border-radius: 14px 14px 0 0 !important;
                    }
                }
            }
            
        }
        .consult-more-info {
            .service-btn-container{
                .service-btn{
                    // min-width: 300px;
                }
            }
        }
    }
 }

 // X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {  }



.serviceIncludedInMembershipModal {
    &__buttonsWrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    &__refuseBtn {
      background-color: #b693c8 !important;
      border-color: #b693c8 !important;
  
      &:hover {
        color: #b693c8 !important;
      }
    }
  
    @media (max-width: 450px) {
      &__buttonsWrapper {
        flex-direction: column;
  
        .btn {
          width: 100% !important;
        }
      }
    }
  }