.tinyLabel {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 0;
}

.notesLandingPagesSubmitBtn {
    min-width: 170px;
}

.notesLandingPagesSubmitModalTitle {
    font-size: 30px;
    line-height: 40px;
    color: #fff;
}