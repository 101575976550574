.become-main{
    .become-member-container{
        background: url(../../Assets/img/become-member.png) no-repeat;
        background-position: right center !important;
        background-size: cover;
        border-radius: 14px;
        .become-meber-captions{
            h3{
                color:var(--white);
                font-family: var(--base-font-family);
                font-size: var(--font-h2-34);
                font-weight: 300;
                span{
                    display: block;
                    font-weight: 700;
                    color: var(--secondary-clr);
                }
            }
            p{
                font-size: var(--font-14);
                font-weight: 300;
                color: var(--white);
                margin: 0;
            }
            ol { 
                counter-reset: item; 
                padding-left: 0;
                li { 
                  display: table;
                  font-size: var(--font-18);
                  color:var(--white);
                  text-align: start;
                  &::before {
                    content: counter(item) ". ";
                    counter-increment: item;
                    color: var(--secondary-clr);
                    padding-right:20px;
                    display: table-cell;
                    font-family: var(--font-sbold);
                  }
                  margin-bottom: 10px;
                }
            }
            .price-dtls{
                .pvalue{
                    color:var(--white);
                    font-family: var(--font-sbold);
                    font-size: var(--font-h1-42);
                    font-weight: 900;
                    font-weight: var(--font-wt900);
                }
            }
        }
        .mob-membership-img-container{
            background: none;
        }
    }
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
   
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
   
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
   
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .become-main{
        padding-left: 0 !important;
        padding-right: 0 !important;
        .become-member{
            .container{
                padding-left: 0 !important;
                padding-right: 0 !important;
                .become-member-container{
                    background: none !important;
                    background-size: 100% !important;
                    background-position: left top;
                    border-radius: 0px !important;
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                    .become-meber-captions{
                        // margin-top: 300px;
                        h3{
                            font-size: var(--font-24) !important;
                            margin-top: 0.8rem !important;
                            color:var(--primary-text) !important;
                            text-align: center;
                        }
                        ol { 
                            li { 
                                color:var(--primary-text);
                                font-size: var(--font-14);
                            }
                        }
                        
                    }
                    
                    .mob-membership-img-container{
                        background: url('../../Assets/img/Become-member-mobile.jpg') no-repeat !important;
                        background-position: center center;
                        height: 500px;
                        background-size: cover !important;
                        border-radius: 15px;
                        overflow: hidden;
                        // h3{
                        //     text-align: center;
                        // }
                    }
                   
                }
            }
        } 
    }
}

 // X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .become-main{
        .become-member{
            .container{
                .become-member-container{
                    .mob-membership-img-container{
                        height: 400px;
                    }
                }
            }
        }
    }
}

@media (max-width: 479.98px) {
    .become-main{
        .become-member{
            .container{
                .become-member-container{
                    .mob-membership-img-container{
                        height: 350px;
                    }
                }
            }
        }
    }
}