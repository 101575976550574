.startChatModalWindow {
  .modal-dialog {
    max-width: 700px !important;
  }

  .modal-content {
    border-radius: 14px;
    background-color: transparent;
  }
}

.startChatModal {
  position: relative;
  box-shadow: 0px 15px 58px rgba(0, 0, 0, 0.13) !important;
  background-color: #fff;
  border-radius: 14px;
  border: none !important;
  padding: 24px;

  .title,
  .paragraph {
    font-family: "Isidora Sans";
    font-style: normal;
    color: #4d4d4d;
    padding: 0;
    margin: 0;
    text-align: center;

  }

  title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  .paragraph {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    margin-top: 10px;
  }

  .strong {
    font-family: Isidora Sans medium;
  }

  .primary-blue-small-btn-40 {
    min-height: 47px;
    width: fit-content;
    margin-top: 24px;
  }
}

.startChatModalCross {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  transition: 0.3s;
  height: 16px;
  &:hover {
    opacity: 0.5;
  }

  svg {
    vertical-align: top;
  }
}

@media (max-width: 575.98px) {
  .startChatModal {
    padding: 0;
    overflow: hidden;

    &__content {
      padding: 16px 16px 0;
    }
  }
}

.preventScroll {
  overflow: hidden;
}