.brightHorizonsMainWrapper {
  background-color: #fbf8ef;
  width: 100%;
}
.brightHorizonsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: var(--base-font-family);
  max-width: 1440px;
  margin: 0 auto;
}

.brightHorizonsHeader {
  background-color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 38px;
  padding-right: 50px;
}

.brightHorizonsLogo {
  display: flex;
  align-items: center;

  &__logo {
    display: block;
    margin-right: 10px;
  }

  &__title {
    font-family: "Isidora Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #636363;
  }
}

.brightHorizonsWrapperTitle {
  padding: 104px 16px 81px;
  width: 100%;
  font-family: "Isidora Sans";
  margin: 0 auto;
  font-style: normal;
  font-size: 50px;
  line-height: 58px;
  color: #30426d;
  text-align: center;
  box-sizing: border-box;

  strong {
    font-family: var(--font-base-bold);
  }
}

.brightHorizonsWrapperFooterContainer {
  padding: 0 30px 30px;
  max-width: 1380px;
  margin: 0 auto;
}

.brightHorizonsWrapperFooter {
  padding: 0 80px;
  background: #4cc4b0;
  border-radius: 14px;
}

.brightHorizonsWrapperFooterLoggedIn {
  background: #30426d;
}

@media (max-width: 767.98px) {
  .brightHorizonsWrapperTitle {
    padding: 40px 16px 30px;
    font-size: 30px;
    line-height: 38px;
  }
  .brightHorizonsHeader {
    height: 66px;
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
  }
  .brightHorizonsLogo__title {
    display: none;
  }
  .brightHorizonsLogo {
    margin-top: 6px;
  }
  .brightHorizonsLogo__logo {
    svg {
      width: 117px;
    }
  }

  .brightHorizonsHeader__btn {
    padding: 10px 10px !important;
    font-size: 14px;
  }
}

@media (max-width: 1199.98px) {
  .brightHorizonsWrapperFooter {
    padding: 0;
  }
  .brightHorizonsWrapperFooterContainer {
    padding: 0 16px 16px;
  }
}
