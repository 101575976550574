.confirmDeleteModalWindow {
  .modal-dialog {
    max-width: 350px !important;
    margin: 0 auto;
  }

  .modal-content {
    border-radius: 14px;
    background-color: transparent;
  }
}

.confirmDeleteModal {
  position: relative;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 15px 58px rgba(0, 0, 0, 0.13) !important;
  border-radius: 14px;
  border: none !important;
  background-position: center;
  padding: 24px;

  .title,
  .description {
    font-family: "Isidora Sans";
    font-style: normal;
    padding: 0;
    margin: 0;
  }

  .title {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
}

.confirmDeleteModalCross {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  transition: 0.3s;
  height: 16px;
  &:hover {
    opacity: 0.5;
  }

  svg {
    vertical-align: top;
  }
}

.confirmDeleteModalButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 24px;

  .primary-blue-small-btn-40 {
    min-height: 47px;
    min-width: 110px;
  }

  .primary-blue-small-btn-40.white {
    background-color: white !important;
    border: 2px solid #000 !important;
    color: #000 !important;
    &:hover {
      border: 2px solid var(--primary-clr) !important;
      color: var(--primary-clr) !important;
    }
  }
}
