@import "../../global.css";
@import "../../global-componant.css";

.unswaddled{
    &::before {
        content: '';
        background-color: #FC8E8A;
        width: 35%;
        height: 95%;
        position: absolute;
        z-index: -1;
        top: 0;
        border-radius: 14px;
        left: -20px;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        background-image: url("../../Assets/img/stage/unswaddledVector.svg");
        width: 100%;
        height: 60%;
        top: 50px;
        background-repeat: no-repeat;
        z-index: -1;
        background-size: contain;
    }

    .row{
        &::before {
            content: '';
            position: absolute;
            background-image: url("../../Assets/img/stage/tune.svg");
            width: 13%;
            height: 40%;
            right: 0;
            background-repeat: no-repeat;
            top: -50px;
            background-size: contain;
        }
    }
    .unswaddled-content{
        color:var(--white);
        h2{
            color:var(--primary-clr);
            font-size: var(--font-50);
        }
        p.cursive{
            font-family: var(--respondent-medium);
            font-size: var(--font-h2-34);
            line-height: 34px;
            color:var(--pink)
        }
    }

}

@media (max-width: 767.98px) {
    .unswaddled{
        color:#fff;
        &::before {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0px;
        }

        &::after {
            top: 0;
        }

        .row{
            &::before {
                background-image: none;
            }
        }
         

        .unswaddled-img{
            img{
                width: 100% !important;
            }
        }

        .unswaddled-content{
            color:var(--white);
            h2{
                color:var(--white) !important;
                width:100% !important;
                font-size: var(--font-h2-34);
            }
            p.cursive, p{
                color:var(--white);
            }
            .pink-btn-small, .pink-btn-small-outline{
                width:80% !important;
            }
            .pink-btn-small{
                background: var(--white) !important;
                color:var(--monthly-clr) !important; 
                margin-bottom: 20px;
                border:solid 2px transparent; 
            }
            .pink-btn-small:hover{
                background: var(--monthly-clr) !important;
                color:var(--white) !important;
                border:solid 2px var(--monthly-clr);
            }

            .pink-btn-small-outline{
                background: var(--monthly-clr) !important;
                color:var(--white) !important; 
                border:solid 2px var(--white); 
            }
            .pink-btn-small-outline:hover{
                background: var(--white) !important;
                color:var(--monthly-clr) !important;
                border:solid 2px var(--monthly-clr);
            }
        }
    }
}