.heroBannerMain {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 14px;
  overflow: hidden;
  aspect-ratio: 1380 / 639;
  display: flex;
  align-items: center;
}

.heroBannerTitle,
.heroBannerHorizontalTitle {
  font-family: var(--base-font-family);
  font-weight: 500;
  font-size: 3.472vw !important;
  line-height: 4.027vw;
  color: var(--white);
  text-align: left;
  margin-left: 7.361vw;
  padding: 0;
  span {
    font-family: var(--font-base-bold);
  }
}



@media (max-width: 767.98px) {
  .heroBannerMobile.heroBannerMain {
    aspect-ratio: 343 / 500;
    align-items: flex-start;
    br {
      display: none;
    }
  }
  .heroBannerHorizontalTitle {
    font-size: 5.76vw !important;
    line-height: 5.8vw;
  }
  .heroBannerMobile .heroBannerTitle {
    font-size: 6.93vw !important;
    line-height: 7.9vw;
    width: 100% !important;
    margin-left: 0;
    padding: 0 8vw 0;
    text-align: center;
  }

  .heroBannerTitle {
    min-height: 45vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
