.commonModalWindowWindow {
  .modal-dialog {
    max-width: 500px !important;
  }

  .modal-content {
    border-radius: 14px;
    background-color: transparent;
  }
}

.commonModalWindow {
  position: relative;
  text-align: center;
  background-color: white !important;
  box-shadow: 0px 15px 58px rgba(0, 0, 0, 0.13) !important;
  border-radius: 14px;
  border: none !important;
  background-position: center;
  padding: 40px;

  p {
    font-family: "Isidora Sans medium";
    font-size: 16px;
    color: #000;
    margin: 0 !important;
  }

  .primary-blue-small-btn-40 {
    min-height: 47px;
    width: fit-content;
    margin-top: 16px;
  }

  .primary-blue-small-btn-40.white {
    background-color: white !important;
    border: 2px solid white !important;
    color: var(--primary-clr) !important;
    &:hover {
      border: 2px solid var(--primary-clr) !important;
    }
  }
}

.commonModalWindowCross {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  transition: 0.3s;
  height: 16px;
  &:hover {
    opacity: 0.5;
  }

  svg {
    vertical-align: top;
  }
}

@media (max-width: 767.98px) {
  .commonModalWindow {
    padding: 40px 16px;
  }
}


