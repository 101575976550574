.preLoginIncluded {
  padding: 120px 0;
  background-color: #fbf8ef;

  .heading2 {
    max-width: 561px;
    font-family: "Isidora Sans bold";
    font-size: 50px !important;
    line-height: 58px;
    color: #30426d;
    text-align: center;
    margin: 0 auto;
    padding: 0 0 15px 0;
  }
  .mainDescription {
    font-family: var(--Respondent-Bold);
    font-size: 50px;
    font-style: normal;
    color: #4cc3b0;
    text-align: center;
  }

  @media (max-width: 767.98px) {
    padding: 40px 0 25px;

    .heading2 {
      font-size: 34px !important;
      line-height: 42px;
    }

    .mainDescription {
      font-size: 44px;
      line-height: 40px;
    }
  }

  // list
  .preLoginIncludedList {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1440px;
    width: 100%;
    margin: 65px auto 0;

    @media (max-width: 767.98px) {
      margin: 25px auto 0;
    }
  }

  .preLoginIncludedJump {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 373px;
    padding-left: 29px;
    margin: 15px;

    .heading3 {
      font-family: "Isidora Sans bold";
      font-size: 42px !important;
      line-height: 44px;
      color: #30426d;
      text-align: left;
      margin: 0;
      padding: 0;
      max-width: 231px;
    }
    .description {
      font-family: "Isidora Sans medium";
      font-size: 18px;
      line-height: 26px;
      color: #4d4d4d;
      max-width: 231px;
      margin-top: 20px;
    }
    .teal-btn-small {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    @media (max-width: 767.98px) {
    align-items: center;
    padding-left: 0;
    
    .heading3 {
        font-size: 26px !important;
        line-height: 44px;
        max-width: 100%;
        text-align: center;
      }
      .description {
        font-size: 16px;
        max-width: 100%;
        text-align: center;
      }
    }
  }

  .preLoginIncludedCard {
    background-color: #fff;
    width: 373px;
    border-radius: 20px;
    overflow: hidden;
    margin: 15px;

    img {
      object-fit: cover;
      width: 100%;
      aspect-ratio: 373/178;
    }
    .preLoginIncludedContent {
      padding: 24px 28px;
      position: relative;

      .preLoginIncludedBadge {
        position: absolute;
        top: 0;
        transform: translateY(-40%);
        color: #fff;
        background-color: #f57d7d;
        font-family: "Isidora Sans medium";
        font-size: 14px;
        line-height: 14px;
        border-radius: 3px;
        padding: 3px 10px 5px 10px;
      }
    }
    .preLoginIncludedTitle {
      color: #30426d;
      font-family: "Isidora Sans bold";
      font-size: 20px;
      line-height: 24px;
    }
    .preLoginIncludedDescription {
      color: #4d4d4d;
      font-family: "Isidora Sans medium";
      font-size: 14px;
      line-height: 22px;
    }
    .preLoginIncludedPrice {
      color: #f57d7d;
      font-family: "Isidora Sans medium";
      font-size: 14px;
      line-height: 24px;

      span {
        font-family: "Isidora Sans bold";
      }
    }
  }
}
