.image-crop-modal .image-crop {
    padding-top: 0px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    position: relative;
}

.image-crop-modal .modal-dialog{
  width:300px;
  margin: 0 auto;
}

.image-crop-modal .modal-content{
  border-radius: 15px !important;
  overflow: hidden;
  border: solid 1px #ccc;
}

.image-crop-modal .image-cropper{
    height: 300px;
}

.image-crop-modal .label-container{
  background: #fff;
  width: 80%;
}

.image-crop-modal .label-container .label-inner .css-187mznn-MuiSlider-root
{
 color:#4CC3B0;
}

.image-crop-modal .ctrl {
    width: 100%;
    background: #fff;
    z-index: 999;
}

.image-crop-modal .ctrl div{
    padding: 5px 0;
    margin: 0 auto;
}

.image-crop-modal .App {
    background-color: #c4c4c4;
    font-family: 'Helvetica Neue', sans-serif;
  }
  
.image-crop-modal .App-header {
    padding-top: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
  }
  
.image-crop-modal button {
    z-index: 2;
    cursor: pointer;
    width: 100%;
    background-color: #30426d;
    color: #fff;
    padding: 10px 0;
    border: none;
  }
  
  
  
.image-crop-modal .cropped-image {
    height: 600px;
    width: auto;
  }
  
.image-crop-modal .cropped-image-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
.image-crop-modal ._coverImage-holder  {
    padding: 25px 40px;
    background-color:black;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  
.image-crop-modal .container {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
.image-crop-modal  .crop-container {
    height: 600px;
    width: 600px;
  }
  
.image-crop-modal .controls {
    display: flex;
    flex-direction: column;
    width: 600px;
    position: absolute;
    bottom: -15px;
  }