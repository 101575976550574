.preLoginHowContactUs {
  max-width: 976px;
  width: 100%;
  margin: 0 auto;
  min-height: 146px;
  border-radius: 14px;
  background: #b693c8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  h3 {
    font-family: "Isidora Sans bold";
    font-size: 26px !important;
    line-height: 50px;
    text-align: center;
    color: #fff;
    padding: 0;
    position: relative;
    z-index: 1;
  }

  button {
    position: relative;
    z-index: 1;
  }

  .preLoginHowContactUsLeftSvg {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .preLoginHowContactUsRightSvg {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  @media (max-width: 767.98px) {
    min-height: 201px;

    .preLoginHowContactUsLeftSvg {
      width: 100px;
      transform: rotate(-24deg);
      left: -30px;
      bottom: -23px;
    }
    .preLoginHowContactUsRightSvg {
      width: 180px;
    }
  }
}
