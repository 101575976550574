@import "../../global.css";
@import "../../global-componant.css";

.Podcast-container {
  .podcast-discription-section {
    .podcast-section-title {
      font-weight: 500;
      font-size: 42px;
      color: var(--primary-clr);
      .strong {
        font-weight: 700;
        font-family: var(--font-base-bold);
      }
    }
    .podcast_description {
      p {
        font-size: 20px;
        color: var(--primary-text);
        a {
          font-size: 20px;
          color: var(--primary-text);
          cursor: pointer;
        }
      }
    }
  }
  .required {
    color: red;
    margin-left: 5px;
  }

  .MuiInputBase-input {
    padding-left: 16px !important;
  }
}
.podcast-custom-list {
  list-style-type: disc;
  padding-left: 20px;
}
.podcast-label-text-normal {
  font-family: var(--base-font-family);
}
