.askNurseChatMessage {
  display: flex;
  align-items: flex-start;

  &--toRight {
    flex-direction: row-reverse;
  }
}

.askNurseChatAvatar {
  border-radius: 50px;
  width: 50px;
  height: 50px;
  overflow: hidden;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid #4cc3b0;
  flex-shrink: 0;
}

.askNurseChatMain {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 74px;

  &--toRight {
    align-items: flex-end;
    margin-right: 24px;
    margin-left: 74px;
  }

  &__useName {
    font-family: Isidora Sans;
    color: #4d4d4d;
    font-size: 16px;
  }

  &__message {
    margin-top: 8px;
    background: #daf5f0;
    border-radius: 5px 30px 30px 30px;
    color: #4d4d4d;
    padding: 20px 30px 10px;
    width: fit-content;
    max-width: 1000px;
    font-family: var(--base-font-family);
    font-size: var(--font-14);
    word-break: break-word;

    &--toRight {
      // text-align: right;
      border-radius: 30px 5px 30px 30px;
      background: #eee;
    }
  }

  &__date {
    margin-top: 3px;
    font-family: Isidora Sans;
    color: #4d4d4d;
    font-size: 12px;
  }

  &__delete {
    color: #4cc3b0;
    outline: 0;
    border: 0;
    background-color: transparent;
    font-family: var(--base-font-family);
    font-size: 14px;
    transition: 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }
}

@media (max-width: 767.98px) {
  .askNurseChatMain {
    display: flex;
    flex-direction: column;
    margin: 0 16px;

    &--toRight {
      margin: 0 16px;
    }
  }
}
