.preLoginWrapper {
  padding-left: 16px;
  padding-right: 16px;
}

.preLoginWrapperColor {
  background-color: #fbf8ef;
  padding-bottom: 90px;

  @media (max-width: 767.98px) {
    padding-bottom: 0;
  }
}

.preLoginDescription {
  background-color: #fff;
  max-width: 840px;
  margin: 0 auto;
  padding: 95px 0 124px;
  text-align: center;
  color: #30426d;
  font-family: "Isidora Sans medium";
  font-size: 34px;
  line-height: 44.7px;

  @media (max-width: 767.98px) {
    font-size: 18px;
    line-height: 26px;
    padding: 30px 0 36px;
    max-width: 300px;
  }
}
