@import "../../../global.css";
@import "../../../global-componant.css";

.Lactationconsult-main{
    .lactationHero {
        max-width: 600px;
        border-radius: 60px;
        width: 100%;
    }
    .offering-main{
        .offering-block-content{
            h3{
                font-size: var(--font-50);
                font-family: var(--font-sbold);
                color:var(--primary-text);
                margin-bottom: 1.2rem;
                span.offer-number{
                    font-family: var(--base-font-family);
                    color: var(--secondary-clr);
                }
            }
        }
        .service-feature-img{
            border-radius: 14px;
            overflow: hidden;
            img{
                border-radius: 14px;
            }
        }
    }

    .you-get{
        .you-get-row{
            // flex-basis: 200px;
            // flex-direction: column
            .you-get-block{
                // flex: 1 1 1 1 1 1;
                flex-basis: 33%;
                max-width: 245px;
            }
        }
    }
  
    .we-seen{
        .all-seen-row{
            // .seen-container-main{
                background: url("../../../Assets/img/homepage/seen-left.svg") left 100px no-repeat;
            // }
        }

        .section-header{
            .cursive{
                line-height: 80px !important;
            }
        }
            
        h2 span:after {
            content: '';
            bottom: -10px;
            width: 87%;
            height: 4px;
            background-color: #B693C8;
            position: absolute;
            left: 7%;
        }
        .we-seen-p{
            font-size: var(--font-24);
            color:var(--primary-clr);
        }
        
        .container-main{
            .seen-container-main{
                position: relative;
                .section-header {
                    h2.home-section-title{
                        font-size: var(--font-80) !important;
                    }
   
                }
                &::after{
                    content: '';
                    background: url('../../../Assets/img/Prelogin/right-wave.svg');
                    width:300px;
                    height: 300px;
                    display: inline-block;
                }
            }
        }
        
    }

    .home-section-description{
        color: #30426d;

        a {
            color: var(--secondary-clr);
            font-family: var(--font-base-bold);
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .Lactationconsult-main{
        .offering-main{
            .offering-block-content{
                h3{
                font-size: 45px;
                }
            }
        }
        .we-seen {
            .all-seen-row{
                background: url("../../../Assets/img/homepage/seen-left.svg") left -30px top 63px no-repeat;
                background-size: 400px !important;
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .Lactationconsult-main{

        .offering-main{
            .offering-block-content{
                margin-right: 1rem!important;
                h3{
                font-size: 34px;
                }
            }

            .service-feature-img{
                margin-right: 1rem!important;
            }
        }

        .we-seen {
            .all-seen-row{
                background: url("../../../Assets/img/homepage/seen-left.svg") left -30px top 63px no-repeat;
                background-size: 300px !important;
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .Lactationconsult-main{
        .you-get-mobile{
            .carousel img {
              width: unset;
            }
      
            .carousel-indicators{
                margin: 2rem auto 0 auto;
            }
            
            .carousel-indicators  [data-bs-target].active {
                opacity: 1;
                background-color: var(--secondary-clr) !important;
            }
      
            .carousel-indicators [data-bs-target]{
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: var(--grey-shade-3) !important;
            }
        }
       
        .we-seen {
            .all-seen-row{
                background: url("../../../Assets/img/homepage/seen-left.svg") left -30px top 63px no-repeat;
                background-size: 200px !important;
                
                p.font-80{
                font-size: 48px !important;
                line-height: 46px;
                padding: 0 50px !important;
                }
            }
        }
    }
 }

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .Lactationconsult-main{


        .we-seen {
            .all-seen-row{
                    background: url("../../../Assets/img/homepage/seen-left.svg") left -30px top 63px no-repeat;
                    background-size: 200px !important;
                    p.font-80{
                        font-size: 40px !important;
                        display: block !important;
                        padding: 0 0px !important;
                        line-height: 54px !important;
                    }
            }
        }
    }

}

@media (max-width: 479.98px) {
    .Lactationconsult-main{

        .we-seen {
            .all-seen-row{
                background: url("../../../Assets/img/homepage/seen-left.svg") left -65px top 63px no-repeat;
                background-size: 200px !important;
                p.font-80{
                    font-size: 36px !important;
                    display: block !important;
                    padding: 0 0px !important;
                    line-height: 38px !important;
                }
            }
        }
    }
}


@media (max-width: 359.98px) {
    .Lactationconsult-main{
        .we-seen {
            .all-seen-row{
                background: url("../../../Assets/img/homepage/seen-left.svg") left -40px top 95px no-repeat;
                background-size: 115px !important;
                p.font-80{
                    font-size: 32px !important;
                    display: block !important;
                    line-height: 40px !important;
                }
            }
        }
    }
}