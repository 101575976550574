@import "../../global.css";
@import "../../global-componant.css";

.promises-container {
  font-family: var(--base-font-family);

  .registered-nurse-main {
    background: url("../../Assets/img/Promises/Registered-nurse-bg-wave.svg")
      var(--monthly-clr);
    background-repeat: no-repeat;
    border-radius: 14px;
    background-position: left top;
    color: var(--white);
    background-size: cover;
    .registered-nurse-imgsection {
      img {
        border-radius: 123px;
        overflow: hidden;
      }
    }

    .registered-nurse-info {
      h3 {
        font-size: 36px;
      }
      p {
        color: var(--white);
      }
    }
  }

  .promises-section-title {
    font-weight: 500;
    font-size: 42px;
    color: var(--primary-clr);
  }

  .curious-main {
    .read-story {
      background: var(--orange)
        url("../../Assets/img/Promises/story-sec-main.svg") 50px bottom
        no-repeat;
      // background: var(--pastel-purple);
      background-repeat: no-repeat;
      // background-position: 0 -10px;
      background-size: cover;
      border-radius: 14px;
      .sec-caption {
        max-width: 600px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        h2 {
          color: var(--primary-clr);
          font-family: var(--base-font-family);
          font-size: var(--font-h2-34);
          margin-bottom: 15px;
          padding-bottom: 0;
          line-height: 40px;
        }
        p {
          color: var(--white);
        }
      }
    }
  }
}
