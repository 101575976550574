.unswaddledPodcast {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
}

.unswaddledPodcastMelodySvgLayer {
  position: absolute;
  right: -26px;
  top: 0;
}

.unswaddledPodcastLeftWrapper {
  min-height: 619px;
  max-width: 728px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.unswaddledPodcastLayer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 63%;
  background-color: #f57d7d;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 14px;
  svg {
    width: 71%;
  }
}

.unswaddledPodcastImage {
  max-width: 476px;
  width: 100%;
  object-fit: contain;
  position: relative;
  z-index: 1;
  border-radius: 12px;
}

.unswaddledPodcastTextWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 16px 16px;
}

.unswaddledPodcastContent {
  max-width: 473px;
}
.unswaddledPodcastTitle {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 58px;
  color: #30426d;
  text-align: left;
  max-width: 393px;
  padding: 0;
  strong {
    font-family: var(--font-base-bold);
  }
}

.unswaddledPodcastRespondent {
  margin-top: 20px;
  font-size: 50px;
  line-height: 40px;
  color: #f57d7d;
  font-family: var(--Respondent-Bold);
}

.unswaddledPodcastP {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
  color: #4d4d4d;
}

.unswaddledPodcastButtons {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
}

.unswaddledPodcastButtonAsk {
  margin-right: 16px;
}

.unswaddledPodcastButtonAsk,
.unswaddledPodcastButtonSubscribe {
  padding: 0 20px;
  background: #f57d7d;
  border-radius: 50px;
  border: #f57d7d solid 1px;
  background-color: #f57d7d;
  color: #fff;
  font-family: Isidora Sans medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  height: 40px;
  margin-top: 16px;
  text-decoration: none;
  transition: 0.3s;
  width: fit-content;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  &:hover {
    opacity: 0.5;
    color: #fff;
  }
}
.unswaddledPodcastButtonSubscribe {
  background-color: transparent;
  color: #f57d7d;

  &:hover {
    color: #f57d7d;
  }
}

.unswaddledPodcastSocial {
  width: 141px;
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
  margin-top: 52px;
  a {
    text-decoration: none;
    &:hover {
      opacity: 0.5;
    }
  }
}

@media (max-width: 767.98px) {
  .unswaddledPodcast {
    background-color: #f57d7d;
    flex-direction: column;
    padding-bottom: 28px;
  }
  .unswaddledPodcastMelodySvgLayer {
    display: none;
  }
  .unswaddledPodcastLeftWrapper {
    height: fit-content;
    padding: 21px 16px 0;
    min-height: fit-content;
  }
  .unswaddledPodcastLayer {
    display: none;
  }
  .unswaddledPodcastImage {
    max-width: 100%;
  }

  .unswaddledPodcastContent {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .unswaddledPodcastTitle {
    font-size: 34px;
    line-height: 42px;
    max-width: 255px;
    margin-top: 42px;
    color: #fff;
    text-align: center;
  }
  .unswaddledPodcastRespondent {
    font-size: 36px;
    color: #fff;
    line-height: 30px;
    text-align: center;
  }
  .unswaddledPodcastP {
    margin-top: 16px;
    font-size: 16px;
    text-align: center;
    color: #fff;
    line-height: 24px;
  }
  .unswaddledPodcastButtonAsk {
    margin-top: 32px;
    max-width: 243px;
    width: 100%;
  }
  .unswaddledPodcastButtonAsk {
    background: #fbf8ef;
    color: #f57d7d;
    width: 100%;
    max-width: 100%;
    margin-right: 0;

    &:hover {
      color: #f57d7d;
    }
  }
  .unswaddledPodcastButtonSubscribe {
    background: #f57d7d;
    width: 100%;
    color: #fbf8ef;
    border: #fbf8ef solid 1px;

    &:hover {
      color: #fbf8ef;
    }
  }
}

@media (max-width: 1199.98px) {
  .unswaddledPodcastMelodySvgLayer {
    display: none;
  }
}
