.preLoginIsForYouWrapper {
  max-width: 1169px;
  width: 100%;
  padding: 0 0 0;
  margin: 0 auto;
}

.preLoginIsForYouContent {
  max-width: 1008px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .footerDescription {
    font-family: "Isidora Sans medium";
    font-size: 12px;
    color: #4d4d4d;
    padding: 0 15px;
    margin: 0;

    .openModalBtn {
      font-family: "Isidora Sans bold";
      color: #4cc3b0;
      background: none;
      border: 0;
      outline: 0;
      cursor: pointer;
      display: inline-block;
      padding: 0;
      margin: 0;
      font-size: inherit;
    }
  }

  @media (max-width: 767.98px) {
    .footerDescription {
      margin-top: 12px;
      padding: 0;
      order: -1;
    }
  }
}

.preLoginIsForYouMembership {
  display: flex;

  @media (max-width: 767.98px) {
    flex-direction: column;
  }
}

.preLoginIsForYouMembershipCard {
  border-radius: 14px;
  overflow: hidden;
  background-color: #fff;
  flex-grow: 1;
  width: 100%;
  margin: 30px 15px;

  &__head {
    background-color: #f57d7d;
    min-height: 110px;
    width: 100%;
    padding: 19px 36px 28px;
    position: relative;
    font-family: "Isidora Sans medium";
    font-size: 26px;
    color: #fff;
    text-align: left;
    display: flex;
    justify-content: space-between;

    span {
      font-family: "Isidora Sans bold";
    }

    &--green {
      background-color: #4cc3b0;
    }

    svg path {
      stroke: #fbf8ef;
    }
  }
  @media (max-width: 767.98px) {
    margin: 20px 0 0;
    background-color: transparent;

    &__head {
      background-color: transparent;
      color: #f57d7d;

      svg path {
        stroke: #f57d7d;
      }

      &--green {
        background-color: transparent;
        color: #4cc3b0;

        svg path {
          stroke: #4cc3b0;
        }
      }
    }
  }
}

.preLoginIsForYouMembershipList {
  padding: 7px 30px 28px 60px;
  list-style: none;
  counter-reset: item;
  margin: 0;

  li {
    counter-increment: item;
    margin-top: 16px;
    position: relative;
    color: #4d4d4d;
    font-family: "Isidora Sans medium";
    font-size: 16px;
  }
  li:before {
    content: counter(item) ".";
    color: #f57d7d;
    font-family: "Isidora Sans medium";
    font-size: 16px;
    line-height: 24px;
    margin-right: 10px;
    display: block;
    position: absolute;
    left: -30px;
  }

  &--green {
    li:before {
      color: #4cc3b0;
    }
  }

  @media (max-width: 767.98px) {
    padding: 7px 32px 36px 60px;
  }
}

.preLoginIsForYouMembershipDivider {
  display: none;
  @media (max-width: 767.98px) {
    border-bottom: 1px solid #c0c0c0;
    display: block;
  }
}
