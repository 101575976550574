.sponsorsIconsWrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 32px 78px;
  grid-template-areas: ". . . . . .";
  max-width: 1134px;
  margin: 0 auto;
}
.sponsorsIcon {
  max-width: 200px;
  width: 100%;
}

@media (max-width: 991.98px) {
  .sponsorsIconsWrapper {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 32px 78px;
    grid-template-areas: ". . .";
  }
}

@media (max-width: 575.98px) {
  .sponsorsIconsWrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 16px 16px;
    grid-template-areas: ". . ";
  }
}
