.memberPopUpTitle {
    line-height: 34px;
    padding-bottom: 0;
}

.memberPopUpParagraph {
    font-family: var(--base-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-text);
}