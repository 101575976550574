@import "../../global.css";
@import "../../global-componant.css";

.contact-container{
    .contact-discription-section{
        .contact-section-title {
            font-weight: 500;
            font-size: 42px;
            color: var(--primary-clr);
            .strong{
                font-weight: 700;
                font-family: var(--font-base-bold);
            }
        }
        .contact_description{
            p{
                font-size: 20px;
            }
        }
    }

    .contact-map-info{
        .bg-container{
            background: #F57D7D url('../../Assets/img/contact-map-bg.svg');
            background-position: left top;
            background-repeat: no-repeat;
            border-radius: 14px;
            .map-container{
                border-radius: 14px;
                overflow: hidden;
            }
        }
        .office-info{
            height: 100%;
            color: var(--white);
            justify-content: center;
            font-family: 'Isidora Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 33px;
            h3{
                font-family: 'Isidora Sans';
                font-style: normal;
                font-weight: 800;
                font-size: 2.8rem;
            }
        }
    }
    .required{
        color:red;
        margin-left: 2px;
    }
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { }


// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
    
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) { 
   
}
