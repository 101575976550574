.AllServicesLayerTitle {
  position: relative;
  padding: 104px 16px 81px;
  width: 100%;
  font-family: "Isidora Sans";
  margin: 100px auto 0;
  font-style: normal;
  font-size: 50px;
  line-height: 58px;
  color: #30426d;
  text-align: center;
  box-sizing: border-box;
  max-width: 748px;
  strong {
    font-family: var(--font-base-bold);
  }
}
.AllServicesLayerCurlyLayer {
  position: absolute;
  left: -364px;
  top: 0;
  z-index: 1;
  width: 558px;
  top: 40px;

  @media (max-width: 1199.98px) {
    display: none;
  }
}

.AllServicesCoursesCurly {
  font-family: var(--respondent-medium);
  font-size: 100px;
  color: #50ccb8;
  transform: rotate(-6deg);
  margin-top: 40px;
  font-weight: 400;

  @media (max-width: 767.98px) {
    font-size: 50px;
    margin-top: 15px;
  }
}

.AllServicesList {
  max-width: 1179px;
  width: 100%;
  margin: 40px auto 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: ". . .";
  justify-items: center;

  > div {
    max-width: 385px;
  }

  @media (max-width: 1260px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
    max-width: 776px;
  }

  @media (max-width: 820px) {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
    max-width: 100%;
    padding: 0 16px;
    gap: 16px 0px;
  }
}
