@import "../../global.css";
@import "../../global-componant.css";

.live-webinar-container{
    .livewebinar-discription-section{
        .livewebinar-section-title {
            font-weight: 500;
            font-size: 42px;
            color: var(--primary-clr);
            .strong{
                font-weight: 700;
                font-family: var(--font-base-bold);
            }
        }
        .livewebinar_description{
            p{
                font-size: 20px;
            }
        }
    }
    .required{
        color:red;
        margin-left: 5px;
    }

    .live-event-calender{
        background: var(--pastel-purple);
        border-radius: 9px;

        // input[type="text"]{
        //     background: transparent;
        //     border:0 !important;
        //     text-align: center;
        //     font-size: 20px !important;
        //     color: var(--white);
        // }

        .text-slider{
            background-color: #B693C8;
            color:#fff;
            border-radius: 9px;

            .carousel .control-prev.control-arrow:before{
                content: '<';
                border: none;
                width: 30px;
                height: 30px;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .carousel .control-next.control-arrow:before{
                content: '>';
                border: none;
                width: 30px;
                height: 30px;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .carousel.carousel-slider .control-arrow {
                top: 50%;
                color: #fff;
                font-size: 26px;
                bottom: unset;
                padding: 0;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: solid 1px #fff;
                border-radius: 4px;
                margin: 0;
                // opacity: 1;
            }

            .carousel.carousel-slider .control-arrow.control-next{
                transform: translate(-50%, -50%);
            }

            .carousel.carousel-slider .control-arrow.control-prev{
                transform: translate(50%, -50%);
            }
        }

        .carousel-root{
            .slider-wrapper{
                ul{
                    li{
                    font-size: 30px;
                    margin: 15px 0;
                    }
                }
            }
            .thumbs-wrapper{
                margin: 0;
                display: none;
            }
            .carousel-status{
                display: none;
            }
            .carousel.carousel-slider{
                .control-arrow:hover {
                    background: none;
                }
            }
        }
    }

    .live-event-calender{

    }
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { }


// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
    
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) { 
   
}


