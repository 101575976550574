.judgmentFreeLayerTextWrapper {
  position: relative;
}
.judgmentFreeLayerText {
  position: absolute;
  left: calc(100% + 10px);
  font-family: var(--respondent-medium);
  color: #f57d7d;
  white-space: nowrap;
  font-style: italic;
  font-size: 72px;

  @media (max-width: 1199.98px) {
    position: relative;
    bottom: 20px;
    left: 0;
    font-size: 72px;
    display: inline-block;
  }

  @media (max-width: 767.98px) {
    font-size: 42px;
    bottom: 14px;
  }

  @media (max-width: 460px) {
    bottom: 0;
  }
}

.judgmentFreeSupport {
  padding: 0 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 102px 102px;
  grid-template-areas: ". .";
  justify-items: center;
  * {
    box-sizing: border-box;
  }
}

.judgmentFreeSupportAlignToEnd {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.judgmentFreeSupportAlignToStart {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.judgmentFreeSupportText {
  max-width: 449px;
}

.judgmentFreeSupportImgWrapper {
}

.judgmentFreeSupportImgGroupWrapper {
  width: 100%;
  max-width: 427px;
  max-height: 308px;
  object-fit: contain;
  position: relative;

  .judgmentFreeSupportImgGroup {
    width: 100%;
  }

  .judgmentFreeSupportImgLayer {
    width: 47%;
    position: absolute;
    left: -23%;
    top: 60%;
  }
}

.judgmentFreeSupportImgSleepWrapper {
  width: 100%;
  max-width: 507px;
  object-fit: contain;
  position: relative;

  .judgmentFreeSupportImgSleep {
    width: 100%;
  }

  .judgmentFreeSupportImgLayer {
    width: 37%;
    position: absolute;
    left: -18%;
    top: -18%;
  }
}

@media (max-width: 991.98px) {
  .judgmentFreeSupport {
    padding: 0 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: fit-content fit-content fit-content fit-content;
    gap: 50px 16px;
    grid-template-areas:
      "judgmentFreeSupport-a"
      "judgmentFreeSupport-b"
      "judgmentFreeSupport-d"
      "judgmentFreeSupport-c";
    justify-items: center;
    .judgmentFreeSupport-a {
      grid-area: judgmentFreeSupport-a;
    }
    .judgmentFreeSupport-b {
      grid-area: judgmentFreeSupport-b;
    }
    .judgmentFreeSupport-c {
      grid-area: judgmentFreeSupport-c;
    }
    .judgmentFreeSupport-d {
      grid-area: judgmentFreeSupport-d;
    }
  }

  .judgmentFreeSupportAlignToEnd,
  .judgmentFreeSupportAlignToStart {
    justify-content: center;
  }

  .judgmentFreeSupportImgGroupWrapper {
    .judgmentFreeSupportImgLayer {
      left: auto;
      right: 0;
      bottom: 0;
      // top: 40%;
    }
  }
  .judgmentFreeSupportImgSleepWrapper {
    .judgmentFreeSupportImgLayer {
      top: auto;
      left: auto;
      right: 0;
      bottom: -30px;
    }
  }
}

@media (max-width: 1199.98px) {
  .judgmentFreeSupportImgGroupWrapper {
    .judgmentFreeSupportImgLayer {
      top: 50%;
    }
  }
}
