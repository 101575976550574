@import "../../global.css";
@import "../../global-componant.css";

.our-story {
  font-family: var(--base-font-family);

  .about-discription-section {
    .about_description {
        max-width: 1038px;
      font-size: 26px;
      color: var(--primary-clr);

      .respondent {
        font-size:  34px;
      }
    }
  }

  .registered-nurse-main {
    background: url("../../Assets/img/Promises/Registered-nurse-bg-wave.svg")
      var(--monthly-clr);
    background-repeat: no-repeat;
    border-radius: 14px;
    background-position: left top;
    color: var(--white);
    background-size: cover;
    .registered-nurse-imgsection {
      img {
        border-radius: 123px;
        overflow: hidden;
      }
    }

    .registered-nurse-info {
      h3 {
        font-size: 36px;
      }
      p {
        color: var(--white);
      }
    }
  }

  .founder-details {
    .about-section-title {
      font-weight: 500;
      font-size: 42px;
      color: var(--primary-clr);
    }

    .about-founder {
      .founder-pic {
        border-radius: 15px;
        overflow: hidden;
        img {
          border-radius: 15px;
          overflow: hidden;
        }
      }
      .founder-nm {
        font-family: "Respondent";
        font-style: italic;
        font-weight: 700;
        font-size: 60px;
        line-height: 58px;
        color: var(--primary-text);
      }
      .founder-full-info {
        font-family: var(--base-font-family);
        font-weight: 600;
        font-size: var(--font-18);
        text-transform: uppercase;
        color: var(--secondary-clr);
      }
    }
  }

  .curious-main {
    .read-story {
      background: var(--orange)
        url("../../Assets/img/Promises/story-sec-main.svg") 50px bottom
        no-repeat;
      // background: var(--pastel-purple);
      background-repeat: no-repeat;
      // background-position: 0 -10px;
      background-size: cover;
      border-radius: 14px;
      .sec-caption {
        max-width: 710px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        h2 {
          color: var(--primary-clr);
          font-weight: var(--font-wt500);
          font-size: var(--font-h2-34);
          margin-bottom: 15px;
          padding-bottom: 0;
          span {
            font-family: var(--font-base-bold);
          }
        }
        p {
          color: var(--white);
        }
      }
    }
  }
}
// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  // .about-container{
  //     .mob-banner {
  //         background-image: url("../../Assets/img/about/aboutus_mobile.jpg");
  //         background-size: cover;
  //         background-position: center center;
  //         background-repeat: no-repeat;
  //         align-items: flex-start !important;
  //         .banner-content-box {
  //             h1 {
  //                 font-size: 26px !important;
  //                 text-align: center;
  //                 width: 100%;
  //             }
  //         }
  //     }
  // }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 479.98px) {
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 359.98px) {
}
