
.cardMethodWrapper {
  max-width: 520px;
}

.cardMethodMenu {
  position: absolute;
  top: 16px;
  right: 10px;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  :hover {
    cursor: pointer;
    opacity: 0.5;
    outline: 0;
  }
}

.cardMethodRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 415px;
  margin-top: 47px;
}

.cardMethodLabel {
  font-family: Isidora Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: var(--primary-text);
}

.cardMethodValue {
    font-family: Isidora Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: var(--primary-text);
    margin-top: 6px;
  }

  .isPrimary {
    border: 2px solid #4CC4B0;
  }

  .primaryCard {
    color: #4CC4B0;
    position: absolute;
    bottom: 3px;
    right: 1.5rem;
  }