.prelogin{
    .promote-insurance {
        padding: 30px 0 20px;
        line-height: 40px;
        a {
            font-family: var(--font-base-bold);
            &:hover {
                color: var(--secondary-clr);
            }
        }
    }
    
    .pink-txt{
        color:var(--monthly-clr);
    }
    .strong{
        font-weight: 700;
        font-family: var(--font-base-bold);
    }
    .section-header {
        h2.home-section-title{
            font-size: var(--font-50) !important;
            color:var(--primary-clr);
        }
    }
    section.banner {
        background-image: url("../../Assets/img/Prelogin/prelogin-home.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-size: 100%;
        border-radius: 14px;
        overflow: hidden;
        aspect-ratio: 1380 / 636;
        min-height: auto;
        // h1 {
        //     text-align: left;
        //     font-weight: var(--font-wt700);
        //     width: 210px;
        //     padding-bottom: 0;
        //     color:var(--white)
        // }

        // .banner-content-box{
        //     .cursive{
        //         line-height: 36px !important;
        //     }
        // }
    }

    .service{
        .home-section-title{
            font-size: 42px;
            color: var(--primary-clr);
            font-family: var(--base-font-family);
            margin: 0;
        }
       .carousel-services{
            .carousel-indicators{
                margin: 2rem auto 0 auto;
            }
            
            .carousel-indicators  [data-bs-target].active {
                opacity: 1;
                background-color: var(--secondary-clr) !important;
            }
        
            .carousel-indicators [data-bs-target]{
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: var(--grey-shade-3) !important;
            }
       }
       
    }

    .offerings{
        .offering-block-content{
            h3{
                p{
                    font-size: var(--font-h1-42) !important;
                    font-family: var(--font-base-bold);
                    .number{
                        font-family: var(--base-font-family);
                    }
                    .content{
                        font-family: var(--font-base-bold);
                    }

                }
            }
            .bestseller{
                font-size: 42px !important;
            }
        }
        .offer-row{
            position: relative !important;
            overflow: hidden;
            .offering-block-content{
                img{
                    border-radius: 15px;
                    overflow: hidden;
                }
            }
            &::after {
                content: '';
                position: absolute;
                // background-image: url("../../Assets/img/Prelogin/bootcamp.png");
                background-repeat: no-repeat;
            }
        }
        .row.offer-row:nth-child(2){
            &::after {
                background-image: url("../../Assets/img/Prelogin/bootcamp.png");
                right: 30px;
                top: 250px;
                width: 308px;
                height: 160px;
                background-size: 80%;
            }
        }
        .row.offer-row:nth-child(3){
            &::after {
                background-image: url("../../Assets/img/Prelogin/new-mom.png");
                top: 250px;
                width: 188px;
                height: 188px;
                background-size: 90%;
                left:5px;
            }
        }
        .row.offer-row:nth-child(4){
            &:after {
                background-image: url("../../Assets/img/Prelogin/lactation-const-icon.png");
                top: 210px;
                width: 220px;
                height: 258px;
                background-size: 85%;
                right: 70px;
            }
        }
        .row.offer-row:nth-child(5){
            &::after {
                background-image: url("../../Assets/img/Prelogin/day-time-icon.png");
                top: 250px;
                width: 188px;
                height: 188px;
                background-size: 90%;
                left:5px;
            }
        }
        .row.offer-row:nth-child(6){
            &:after {
                background-image: url("../../Assets/img/Prelogin/sleep-consult-icon.png");
                top: 140px;
                width: 158px;
                height: 210px;
                background-size: 85%;
                right: 40px;
            }
        }
        .row.offer-row:nth-child(7){
            &::after {
                background-image: url("../../Assets/img/Prelogin/live-event-icon.png");
                top: 234px;
                width: 188px;
                height: 188px;
                background-size: 90%;
                left: 45px;
            }
        }
    }

   

    .we-seen{
        .all-seen-row{
            // .seen-container-main{
                background: url("../../Assets/img/homepage/seen-left.svg") left 100px no-repeat, url("../../Assets//img/homepage//seen-right.svg") right -330px bottom 240px no-repeat;
            // }
        }

        .section-header{
            .cursive{
                line-height: 80px !important;
            }
        }
            
        h2 span:after {
            content: '';
            bottom: -10px;
            width: 87%;
            height: 4px;
            background-color: #B693C8;
            position: absolute;
            left: 7%;
        }
        .we-seen-p{
            font-size: var(--font-24);
            color:var(--primary-clr);
        }
        
        .container-main{
            .seen-container-main{
                position: relative;
                .section-header {
                    h2.home-section-title{
                        font-size: var(--font-80) !important;
                    }
                }
                &::after{
                    content: '';
                    background: url('../../Assets/img/Prelogin/right-wave.svg');
                    width:300px;
                    height: 300px;
                    display: inline-block;
                }
            }
        }
        
    }
  
    .unswaddled{
        &::before {
            content: '';
            background-color: #FC8E8A;
            width: 35%;
            height: 95%;
            position: absolute;
            z-index: -1;
            top: 0;
            border-radius: 14px;
            left: -20px;
        }
    
        &::after {
            content: '';
            position: absolute;
            left: 0;
            background-image: url("../../Assets/img/Prelogin/unswaddledVector.svg");
            width: 100%;
            height: 60%;
            top: 50px;
            background-repeat: no-repeat;
            z-index: -1;
            background-size: contain;
        }

        .row{
            &::before {
                content: '';
                position: absolute;
                background-image: url("../../Assets/img/homepage/tune.svg");
                width: 13%;
                height: 40%;
                right: 0;
                background-repeat: no-repeat;
                top: -50px;
                background-size: contain;
            }
        }
        .unswaddled-content{
            color:var(--white);
            h2.unswaddled-heading {
                color:var(--primary-clr);
                font-size: var(--font-50);
            }
            p.cursive{
                font-family: var(--respondent-medium);
                font-size: var(--font-h2-34);
                line-height: 34px;
                color:var(--pink)
            }
        }

    }

    section.corporate {
        .corporate-container {
            .row{
                .corporate-container-main{
                background-image: url("../../Assets/img/Prelogin/corporat.png");
                min-height: 600px;
                background-repeat: no-repeat;
                background-size: 100%;
                border-radius: 14px;
                overflow: hidden;
                }
            }
        }

        .corporate-program{
            position: absolute;
            bottom: 0%;
            left: 50%;
            width:65%;
            transform: translate(-50%, 0);
        }

        // .corporate-program-main{
        //     position: absolute;
        //     bottom: 20px;
        // }
        
        
    }

    .blog-section{
        .blog-card{
            .blog-img {
                border-radius: 16px;
                overflow: hidden;
                height: 200px;
                width: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                display: inline-block;
            }
            .blog-content {
                span.creation-date {
                    color: var(--primary-border);
                    font-size: 14px;
                    opacity: .5;
                }
    
                h6 {
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    color:var(--primary-clr);
                    font-size: 18px !important;
                    font-family: var(--font-base-bold);
                    min-height: 44px;
                }
                .blog-summary{
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    min-height: 63px;
                    // color:var(--primary-clr);
                    // font-size: 18px !important;
                    // font-family: var(--font-base-bold);
                    // min-height: 44px; 
                }
                a {
                    text-decoration: underline;
                    color: var(--primary-clr);
                    font-family: var(--font-base-bold);
                }
            }
        }
    }


    .sponsor-container {
        background: url("../../Assets/img/Prelogin/Sponsor-main.svg") no-repeat left -3px top -40px;
        background-size: 375px;
        
        .all-sponsor-container {
            .all-sponsor {
                .sponsor-logo-container {
                    width: 20%;
                    padding: 1rem 0;
                }
            }
        }
    }
    
}


.green {
    color: #4CC3B0;
}
.pink {
    color: #FC8E8A;
}
.pink-border {
    border: 2px solid #FC8E8A;
    border-radius: 50px;
}
.btn-bg-pink {
    background-color: #FC8E8A;;
}
.btn-bg-orange {
    background-color: #F5CF70;;
}
.btn-bg-green {
    background-color: #4CC3B0;
}
.orange {
    color: #F5CF70;
}
.lavender {
    color: #B693C8;
}

.blue-text {
    color: #30426D;
}
.blue-bg {
    background-color: #30426D;
}
.dark-grey {
    color: #4D4D4D;
}

.spoiler p {
    font-size: 20px;
}
// .perfect-parent p, section.services h2, span.number, section.we-seen h2, section.corporate h2, .corporate-program p, section.unswaddled .unswaddled-content h2, section.blog h2, section.sponser h2 {
//     font-family: 'Isidora-Sans-light';
// }
// .perfect-parent p span, section.services h2 span, section.we-seen h2 span, section.corporate h2 span, section.unswaddled .unswaddled-content h2 span, .blog-content a, section.blog h2 span, section.sponser h2 span {
//     font-family: 'Isidora-Sans-bold';
// }
.perfect-parent{
    p{
        font-family: var(--base-font-family);
        font-size: 1.5rem;
        .doesnt-exist{
            font-family: var(--font-base-bold);
        }
    }
}

.parents-content {
    p {
        font-size: 14px;
        color: #4D4D4D;
        
    }
   
}
.spoiler:before {
    content: '';
    background-image: url("../../Assets/img/Prelogin/border-top.svg");
    width: 100%;
    height: 5px;
    position: absolute;
    top: 0;
    background-repeat: no-repeat;
}
.spoiler:after {
    content: '';
    background-image: url("../../Assets/img/Prelogin/border-btm.svg");
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: 0;
    background-repeat: no-repeat;
}

.service-block{
    img{
        border-radius: 74px;
        overflow: hidden;
        cursor:pointer
    }
    img:hover{
        box-shadow: 0px 4px 14px rgba(148,148,148,0.4);
        -webkit-box-shadow: 0px 4px 14px rgba(148,148,148,0.4);
        -moz-box-shadow: 0px 4px 14px rgba(148,148,148,0.4);
    }
}

.service-block:nth-child(odd) {
    margin-bottom: 50px;
}

.btn-radius {
    border-radius: 50px;
}

sup {
    font-size: 1.2rem;
    top: -20px;
}
section.we-seen h2 span:after {
    content: '';
    bottom: -10px;
    width: 87%;
    height: 4px;
    background-color: #B693C8;
    position: absolute;
    left: 7%;
}
// section.we-seen p.content {
//     font-size: 1.85rem;
//     font-family: 'Isidora-Sans-light';
// }
// section.unswaddled:before {
//     content: '';
//     background-color: #FC8E8A;
//     width: 35%;
//     height: 95%;
//     position: absolute;
//     z-index: -1;
//     top: 0;
//     border-radius: 14px;
//     left: -20px;
// }
// section.unswaddled:after {
//     content: '';
//     position: absolute;
//     left: 0;
//     background-image: url("../../Assets/img/Prelogin/unswaddledVector.png");
//     width: 100%;
//     height: 60%;
//     top: 50px;
//     background-repeat: no-repeat;
//     z-index: -1;
//     background-size: contain;
// }
section.unswaddled {
    z-index: 1;
}
section.sponser:before {
    content: '';
    position: absolute;
    background-image: url("../../Assets/img/Prelogin/sponserVector.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 28%;
    height: 48%;
    top: -8.4%;
    left: 0;
    z-index: 0;
}


.logo-items {
    flex: 0 0 20%;
}



// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
    .prelogin{

        .offerings{
            .offering-block-content{
                h3{
                    p{
                        font-size: var(--font-h1-42) !important;
                        font-family: var(--font-base-bold);
                        .number{
                            font-family: var(--base-font-family);
                        }
                        .content{
                            font-family: var(--font-base-bold);
                        }
    
                    }
                }
                .bestseller{
                    font-size: 42px !important;
                }
            }
            .offer-row{
                position: relative !important;
                overflow: hidden;
                &::after {
                    content: '';
                    position: absolute;
                    // background-image: url("../../Assets/img/Prelogin/bootcamp.png");
                    background-repeat: no-repeat;
                }
            }
            .row.offer-row:nth-child(2){
                &::after {
                    // background-image: url("../../Assets/img/Prelogin/bootcamp.png");
                    right: 7px;
                    top: 260px;
                    width: 284px;
                    height: 160px;
                    background-size: 85%;
                }
            }
            .row.offer-row:nth-child(3){
                &::after {
                    // background-image: url("../../Assets/img/Prelogin/new-mom.png");
                    top: 240px;
                    width: 188px;
                    height: 188px;
                    background-size: 85%;
                    left: 25px;
                }
            }
            .row.offer-row:nth-child(4){
                &:after {
                    // background-image: url("../../Assets/img/Prelogin/lactation-const-icon.png");
                    top: 205px;
                    width: 220px;
                    height: 205px;
                    background-size: 80%;
                    right: 30px;
                }
            }
            .row.offer-row:nth-child(5){
                &::after {
                    // background-image: url("../../Assets/img/Prelogin/day-time-icon.png");
                    top: 148px;
                    width: 258px;
                    height: 255px;
                    background-size: 90%;
                    left: 5px;
                }
            }
            .row.offer-row:nth-child(6){
                &:after {
                    // background-image: url("../../Assets/img/Prelogin/sleep-consult-icon.png");
                    top: 150px;
                    width: 158px;
                    height: 210px;
                    background-size: 90%;
                    right: 40px;
                }
            }
            .row.offer-row:nth-child(7){
                &::after {
                    // background-image: url("../../Assets/img/Prelogin/live-event-icon.png");
                    top: 225px;
                    width: 188px;
                    height: 188px;
                    background-size: 90%;
                    left: 40px;
                }
            }
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .prelogin{
        .we-seen {
            .all-seen-row{
                background: url("../../Assets/img/homepage/seen-left.svg") left -30px top 63px no-repeat, url("../../Assets//img/homepage//seen-right.svg") right -135px bottom 375px  no-repeat;
                background-size: 400px !important;
            }
        }

        .offerings{
            .offering-block-content{
                h3{
                    p{
                        font-size: var(--font-h2-34) !important;
                        font-family: var(--font-base-bold);
                        .number{
                            font-family: var(--base-font-family);
                        }
                        .content{
                            font-family: var(--font-base-bold);
                        }
    
                    }
                }
                .bestseller{
                    font-size: 42px !important;
                }
            }
            .offer-row{
                position: relative !important;
                overflow: hidden;
                &::after {
                    content: '';
                    position: absolute;
                    // background-image: url("../../Assets/img/Prelogin/bootcamp.png");
                    background-repeat: no-repeat;
                }
            }
            .row.offer-row:nth-child(2){
                &::after {
                    // background-image: url("../../Assets/img/Prelogin/bootcamp.png");
                    right: 20px;
                    top: 220px;
                    width: 230px;
                    height: 124px;
                    background-size: 80%;
                }
            }
            .row.offer-row:nth-child(3){
                &::after {
                    // background-image: url("../../Assets/img/Prelogin/new-mom.png");
                    top: 215px;
                    width: 145px;
                    height: 150px;
                    background-size: 80%;
                    left: 30px;
                }
            }
            .row.offer-row:nth-child(4){
                &:after {
                    // background-image: url("../../Assets/img/Prelogin/lactation-const-icon.png");
                    top: 155px;
                    width: 202px;
                    height: 195px;
                    background-size: 80%;
                    right: 30px;
                }
            }
            .row.offer-row:nth-child(5){
                &::after {
                    // background-image: url("../../Assets/img/Prelogin/day-time-icon.png");
                    top: 130px;
                    width: 215px;
                    height: 217px;
                    background-size: 90%;
                    left: 10px;
                }
            }
            .row.offer-row:nth-child(6){
                &:after {
                    // background-image: url("../../Assets/img/Prelogin/sleep-consult-icon.png");
                    top: 160px;
                    width: 112px;
                    height: 158px;
                    background-size: 90%;
                    right: 55px;
                }
            }
            .row.offer-row:nth-child(7){
                &::after {
                    // background-image: url("../../Assets/img/Prelogin/live-event-icon.png");
                    top: 200px;
                    width: 165px;
                    height: 168px;
                    background-size: 90%;
                    left: 30px;
                }
            }
        }

        .sponsor-container {
            background: url("../../Assets/img/Prelogin/Sponsor-main.svg") no-repeat left -25px top -40px;
            background-size: 265px;
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

    .prelogin{
        .we-seen {
            .all-seen-row{
                    background: url("../../Assets/img/homepage/seen-left.svg") left -30px top 63px no-repeat, url("../../Assets//img/homepage//seen-right.svg") right -85px bottom 375px  no-repeat;
                    background-size: 300px !important;
            }
        }
        
        .offerings{
            .offering-block-content{
                h3{
                    p{
                        font-size: var(--font-h2-34) !important;
                        font-family: var(--font-base-bold);
                        .number{
                            font-family: var(--base-font-family);
                        }
                        .content{
                            font-family: var(--font-base-bold);
                        }
    
                    }
                }
                .bestseller{
                    font-size: 42px !important;
                }
            }
            .offer-row{
                position: relative !important;
                overflow: hidden;
                &::after {
                    content: '';
                    position: absolute;
                    // background-image: url("../../Assets/img/Prelogin/bootcamp.png");
                    background-repeat: no-repeat;
                }
            }
            .row.offer-row:nth-child(2){
                &::after {
                    // background-image: url("../../Assets/img/Prelogin/bootcamp.png");
                    right: -9px;
                    top: 135px;
                    width: 181px;
                    height: 79px;
                    background-size: 80%;
                }
            }
            .row.offer-row:nth-child(3){
                &::after {
                    // background-image: url("../../Assets/img/Prelogin/new-mom.png");
                    top: 117px;
                    width: 141px;
                    height: 164px;
                    background-size: 73%;
                    left: 16px;
                }
            }
            .row.offer-row:nth-child(4){
                &:after {
                    // background-image: url("../../Assets/img/Prelogin/lactation-const-icon.png");
                    top: 104px;
                    width: 169px;
                    height: 242px;
                    background-size: 80%;
                    right: -10px;
                }
            }
            .row.offer-row:nth-child(5){
                &::after {
                    // background-image: url("../../Assets/img/Prelogin/day-time-icon.png");
                    top: 53px;
                    width: 195px;
                    height: 210px;
                    background-size: 90%;
                    left: -9px;
                }
            }
            .row.offer-row:nth-child(6){
                &:after {
                    // background-image: url("../../Assets/img/Prelogin/sleep-consult-icon.png");
                    top: 70px;
                    width: 90px;
                    height: 125px;
                    background-size: 80%;
                    right: 10px;
                }
            }
            .row.offer-row:nth-child(7){
                &::after {
                    // background-image: url("../../Assets/img/Prelogin/live-event-icon.png");
                    top: 140px;
                    width: 137px;
                    height: 122px;
                    background-size: 80%;
                    left: 30px;
                }
            }
        }

        section.corporate {
            .corporate-container{
                .row{
                    .corporate-container-main{
                        min-height: 482px;
                    }
                }
            }
            .corporate-program{
                width: 75%;
            }
        }

        .sponsor-container {
            background: url("../../Assets/img/Prelogin/Sponsor-main.svg") no-repeat left -3px top 0px;
            background-size: 180px;
        }
    
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .prelogin{
        section.banner{
            background-image: url("../../Assets/img/Prelogin/Group 1741.png");
            aspect-ratio: 343 / 500;
            min-height: auto;
        }

        .all-sponsor-container {
            .all-sponsor {
                .sponsor-logo-container {
                    width: 50%;
                    // padding: 1rem 0;
                }
            }
        }

        .unswaddled{
            color:#fff;
            &::before {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0px;
            }

            .row{
                &::before {
                    background-image: none;
                }
            }
             

            .unswaddled-img{
                img{
                    width: 100% !important;
                }
            }

            .unswaddled-content{
                color:var(--white);
                h2{
                    color:var(--white);
                    width:100% !important;
                }
                p.cursive, p{
                    color:var(--white);
                }
                .pink-btn-small, .pink-btn-small-outline{
                    width:80% !important;
                }
                .pink-btn-small{
                    background: var(--white) !important;
                    color:var(--monthly-clr) !important; 
                    margin-bottom: 20px;
                    border:solid 2px transparent; 
                }
                .pink-btn-small:hover{
                    background: var(--monthly-clr) !important;
                    color:var(--white) !important;
                    border:solid 2px var(--monthly-clr);
                }

                .pink-btn-small-outline{
                    background: var(--monthly-clr) !important;
                    color:var(--white) !important; 
                    border:solid 2px var(--white); 
                }
                .pink-btn-small-outline:hover{
                    background: var(--white) !important;
                    color:var(--monthly-clr) !important;
                    border:solid 2px var(--monthly-clr);
                }
            }
        }

        .offerings{
            .row.offer-row:nth-child(2){
                    &::after {
                    right: 30px;
                    bottom: unset;
                    width: 170px;
                    top: 255px;
                    height: 163px;
                    background-size: 100%;
                    overflow: hidden;
                }
           }

           .row.offer-row:nth-child(3){
                    &::after {
                    left: unset;
                    bottom: unset;
                    top: 227px;
                    width: 140px;
                    height: 140px;
                    background-size: 90%;
                    right: 20px;
                }
            }

            .row.offer-row:nth-child(4){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 210px;
                    width: 140px;
                    height: 180px;
                    background-size: 100%;
                    right: 22px;
                }
            }
            .row.offer-row:nth-child(5){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 195px;
                    width: 140px;
                    height: 180px;
                    background-size: 100%;
                    right: 22px;
                }
            }
            .row.offer-row:nth-child(6){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 185px;
                    width: 140px;
                    height: 173px;
                    background-size: 85%;
                    right: 30px;
                }
            }
            .row.offer-row:nth-child(7){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 210px;
                    width: 140px;
                    height: 180px;
                    background-size: 100%;
                    right: 22px;
                }
            }

        }

        .services{
            .section-header{
                p.w-50{
                    width:90% !important;
                }
            }

            
        }

        .service-block{
            img{
                border-radius: 74px;
                overflow: hidden;
                cursor:pointer
            }
            img:hover{
                box-shadow: 0px 4px 14px rgba(148,148,148,0.4);
                -webkit-box-shadow: 0px 4px 14px rgba(148,148,148,0.4);
                -moz-box-shadow: 0px 4px 14px rgba(148,148,148,0.4);
            }
        }

       
        .we-seen {
            .all-seen-row{
                background: url("../../Assets/img/homepage/seen-left.svg") left -30px top 63px no-repeat, url("../../Assets//img/homepage//seen-right.svg") right -85px bottom 375px  no-repeat;
                background-size: 200px !important;
                
                p.font-80{
                font-size: 48px !important;
                line-height: 46px;
                padding: 0 50px !important;
                }
            }
        }

        .carousel-services{
            .carousel-indicators{
                margin: 2rem auto 2rem auto;
            }
            
            .carousel-indicators  [data-bs-target].active {
                opacity: 1;
                background-color: var(--secondary-clr) !important;
            }
        
            .carousel-indicators [data-bs-target]{
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: var(--grey-shade-3) !important;
            }
       }

       section.corporate {
        .corporate-container {
            .row{
                .corporate-container-main{
                    min-height: 400px;
                }
            }
        }
        
        // .corporate-program{
        //     position: relative !important;
        //     margin-top: 225px;
        //     width: 100%;
        //     border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius) !important;
        // }
        }
    }
}

@media (max-width: 575.98px) {

}

@media (max-width: 575.98px) {
    .prelogin{
        // .section-header{
        //     h2.home-section-title {
        //         font-size: 40px !important;
        //     }
        // }

        .offerings{
            .row.offer-row:nth-child(2){
                    &::after {
                    right: 30px;
                    bottom: unset;
                    width: 170px;
                    top: 255px;
                    height: 163px;
                    background-size: 100%;
                    overflow: hidden;
                }
           }

           .row.offer-row:nth-child(3){
                    &::after {
                    left: unset;
                    bottom: unset;
                    top: 227px;
                    width: 140px;
                    height: 140px;
                    background-size: 90%;
                    right: 20px;
                }
            }

            .row.offer-row:nth-child(4){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 210px;
                    width: 140px;
                    height: 180px;
                    background-size: 100%;
                    right: 22px;
                }
            }
            .row.offer-row:nth-child(5){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 195px;
                    width: 140px;
                    height: 180px;
                    background-size: 100%;
                    right: 22px;
                }
            }
            .row.offer-row:nth-child(6){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 185px;
                    width: 140px;
                    height: 173px;
                    background-size: 85%;
                    right: 30px;
                }
            }
            .row.offer-row:nth-child(7){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 210px;
                    width: 140px;
                    height: 180px;
                    background-size: 100%;
                    right: 22px;
                }
            }

        }

        .we-seen {
            .all-seen-row{
                    background: url("../../Assets/img/homepage/seen-left.svg") left -30px top 63px no-repeat, url("../../Assets//img/homepage//seen-right.svg") right -85px bottom 375px  no-repeat;
                    background-size: 200px !important;
                    p.font-80{
                        font-size: 40px !important;
                        display: block !important;
                        padding: 0 0px !important;
                        line-height: 54px !important;
                    }
            }
        }

        section.corporate {
            .corporate-container{
                .row {
                    .corporate-container-main {
                        min-height: unset;
                    }
                }
            }
            .corporate-program{
                position: relative !important;
                margin-top: 180px;
                width: 100%;
                border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius) !important;
            }
        }

        .sponsor-container {
            background: url("../../Assets/img/Prelogin/Sponsor-main.svg") no-repeat left -80px top 0px;
            background-size: 154px;
        }
    }
    
}

@media (max-width: 479.98px) {
    .prelogin{
        .offerings{
            .row.offer-row:nth-child(2){
                    &::after {
                    right: 0px;
                    bottom: unset;
                    width: 170px;
                    top: 255px;
                    height: 163px;
                    background-size: 100%;
                    overflow: hidden;
                }
           }

           .row.offer-row:nth-child(3){
                    &::after {
                    left: unset;
                    bottom: unset;
                    top: 215px;
                    width: 140px;
                    height: 140px;
                    background-size: 90%;
                    right: -10px;
                }
            }

            .row.offer-row:nth-child(4){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 205px;
                    width: 140px;
                    height: 180px;
                    background-size: 94%;
                    right: -20px;
                }
            }
            .row.offer-row:nth-child(5){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 195px;
                    width: 140px;
                    height: 180px;
                    background-size: 96%;
                    right: -27px;
                }
            }
            .row.offer-row:nth-child(6){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 225px;
                    width: 95px;
                    height: 130px;
                    background-size: 85%;
                    right: -5px;
                }
            }
            .row.offer-row:nth-child(7){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 225px;
                    width: 130px;
                    height: 170px;
                    background-size: 100%;
                    right: -10px;
                }
            }

        }

        .we-seen {
            .all-seen-row{
                background: url("../../Assets/img/homepage/seen-left.svg") left -65px top 63px no-repeat, url("../../Assets//img/homepage//seen-right.svg") right -85px bottom 375px  no-repeat;
                background-size: 200px !important;
                p.font-80{
                    font-size: 36px !important;
                    display: block !important;
                    padding: 0 0px !important;
                    line-height: 38px !important;
                }
            }
        }
        // .we-seen {
        //     .all-seen-row{
        //        p.font-80{
        //             font-size: 40px !important;
        //             display: block !important;
        //         }
        //     }
        // }
        section.corporate {
            .corporate-container {

                .corporate-program{
                    margin-top: 150px;
                }
            }
        }
    }
    
}

@media (max-width: 419.98px) {
.prelogin{
    .offerings{
        .row.offer-row:nth-child(2){
                &::after {
                    right: -21px;
                    bottom: unset;
                    width: 170px;
                    top: 193px;
                    height: 99px;
                    background-size: 100%;
                    overflow: hidden;
            }
       }

       .row.offer-row:nth-child(3){
            &::after {
                left: unset;
                bottom: unset;
                top: 150px;
                width: 115px;
                height: 115px;
                background-size: 88%;
                right: -14px;
            }
        }

        .row.offer-row:nth-child(4){
            &::after {
                left: unset;
                bottom: unset;
                top: 155px;
                width: 140px;
                height: 180px;
                background-size: 95%;
                right: -25px;
            }
        }
        .row.offer-row:nth-child(5){
            &::after {
                left: unset;
                bottom: unset;
                top: 145px;
                width: 130px;
                height: 180px;
                background-size: 100%;
                right: -20px;
            }
        }
        .row.offer-row:nth-child(6){
            &::after {
                left: unset;
                bottom: unset;
                top: 160px;
                width: 100px;
                height: 173px;
                background-size: 85%;
                right: -10px;
            }
        }
        .row.offer-row:nth-child(7){
            &::after {
                left: unset;
                bottom: unset;
                top: 160px;
                width: 120px;
                height: 150px;
                background-size: 100%;
                right: -15px;
            }
        }

    }

    .we-seen {
        .all-seen-row{
            background: url("../../Assets/img/homepage/seen-left.svg") left -30px top 70px no-repeat, url("../../Assets//img/homepage//seen-right.svg") right -85px bottom 375px  no-repeat;
            background-size: 115px !important;
            p.font-80{
                font-size: 32px !important;
                display: block !important;
                line-height: 40px !important;
            }
        }
    }
}

}

@media (max-width: 359.98px) {
   
    .prelogin{
        section.corporate {
            .corporate-container {
                .corporate-program{
                    margin-top: 125px;
                }
            }
        }

        .section-header{
            h2.home-section-title {
                font-size: 33px !important;
            }
        }
        
        .offerings{
            .row.offer-row:nth-child(2){
                    &::after {
                        right: -21px;
                        bottom: unset;
                        width: 170px;
                        top: 193px;
                        height: 99px;
                        background-size: 100%;
                        overflow: hidden;
                }
           }
    
           .row.offer-row:nth-child(3){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 150px;
                    width: 115px;
                    height: 115px;
                    background-size: 88%;
                    right: -14px;
                }
            }
    
            .row.offer-row:nth-child(4){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 155px;
                    width: 140px;
                    height: 180px;
                    background-size: 95%;
                    right: -25px;
                }
            }
            .row.offer-row:nth-child(5){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 145px;
                    width: 130px;
                    height: 180px;
                    background-size: 100%;
                    right: -20px;
                }
            }
            .row.offer-row:nth-child(6){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 160px;
                    width: 100px;
                    height: 173px;
                    background-size: 85%;
                    right: -10px;
                }
            }
            .row.offer-row:nth-child(7){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 160px;
                    width: 120px;
                    height: 150px;
                    background-size: 100%;
                    right: -15px;
                }
            }
    
        }

        .we-seen {
            .all-seen-row{
                background: url("../../Assets/img/homepage/seen-left.svg") left -40px top 95px no-repeat, url("../../Assets//img/homepage//seen-right.svg") right -85px bottom 375px  no-repeat;
                background-size: 115px !important;
                p.font-80{
                    font-size: 32px !important;
                    display: block !important;
                    line-height: 40px !important;
                }
            }
        }

        .offerings{
            .row.offer-row:nth-child(2){
                    &::after {
                        right: -44px;
                        bottom: unset;
                        width: 170px;
                        top: 160px;
                        height: 99px;
                        background-size: 100%;
                        overflow: hidden;
                }
           }
    
           .row.offer-row:nth-child(3){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 130px;
                    width: 110px;
                    height: 110px;
                    background-size: 86%;
                    right: -15px;
                }
            }
    
            .row.offer-row:nth-child(4){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 120px;
                    width: 140px;
                    height: 157px;
                    background-size: 95%;
                    right: -30px;
                }
            }
            .row.offer-row:nth-child(5){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 95px;
                    width: 130px;
                    height: 142px;
                    background-size: 100%;
                    right: -20px;
                }
            }
            .row.offer-row:nth-child(6){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 130px;
                    width: 86px;
                    height: 100px;
                    background-size: 85%;
                    right: -10px;
                }
            }
            .row.offer-row:nth-child(7){
                &::after {
                    left: unset;
                    bottom: unset;
                    top: 130px;
                    width: 110px;
                    height: 110px;
                    background-size: 100%;
                    right: -13px;
                }
            }
    
        }
    }
}

@media (max-width: 319.98px) {
    .we-seen {
        .all-seen-row{
            background: url("../../Assets/img/homepage/seen-left.svg") left -40px top 95px no-repeat, url("../../Assets//img/homepage//seen-right.svg") right -85px bottom 375px  no-repeat;
            background-size: 115px !important;
            p.font-80{
                font-size: 32px !important;
                display: block !important;
                line-height: 40px !important;
            }
        }
    }
}

