@import "../../../global.css";
@import "../../../global-componant.css";

.support-Group{
    color:var(--primary-clr);
    
    .about_description{
        p{
            font-size: var(--font-20);
            color:var(--primary-clr);
        }
    }

    .stage-offering-header{
        .section-header {
            h2.home-section-title{
                font-size: var(--font-50) !important;
                color:var(--primary-clr);
                font-weight: 400;
            }
        }
    
        .stage-description{
            color: var(--primary-clr);
        }
    }

    .offering-main{
        .offering-block-content{
            h3{
                font-size: var(--font-50);
                font-family: var(--font-sbold);
                color:var(--primary-text);
                margin-bottom: 1.2rem;
                span.offer-number{
                    font-family: var(--base-font-family);
                    color: var(--secondary-clr);
                }
            }
        }
        .service-feature-img{
            border-radius: 14px;
            overflow: hidden;
            img{
                border-radius: 14px;
            }
        }
    }

    .question-main{
        .question-inner{
            background: var(--pastel-purple) url('../../../Assets/img/Promises/story-sec-main.svg') right 0px bottom 0px no-repeat;
            // background: var(--pastel-purple);
            background-repeat: no-repeat;
            // background-position: 0 -10px;
            background-size: cover;
            border-radius: 14px;
            .sec-caption{
                max-width: 600px;
                margin-right: auto;
                margin-left: auto;
                text-align: center;
                h2{
                    color: var(--white);
                    font-family: var(--base-font-family);
                    font-size: var(--font-h2-34);
                    margin-bottom: 15px;
                    padding-bottom: 0;
                }
                p{
                    color: var(--white);
                }
            }
    
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .support-Group{
        .offering-main{
            .offering-block-content{
                h3{
                font-size: 45px;
                }
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .support-Group{
        .offering-main{
            .offering-block-content{
                margin-right: 1rem!important;
                h3{
                font-size: 34px;
                }
            }

            .service-feature-img{
                margin-right: 1rem!important;
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .support-Group{
        .become-member{
            .container{
                padding: 0 !important;
                .become-member-container{
                    background-size: cover;
                    text-align: center;
                    padding-left: 30px !important;
                    padding-right: 30px !important;
                }
            }
        }

        .unswaddled{
            color:#fff;
            &::before {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0px;
            }

            .row{
                &::before {
                    background-image: none;
                }
            }
             

            .unswaddled-img{
                img{
                    width: 100% !important;
                }
            }

            .unswaddled-content{
                color:var(--white);
                h2{
                    color:var(--white);
                    width:100% !important;
                }
                p.cursive, p{
                    color:var(--white);
                }
                .pink-btn-small, .pink-btn-small-outline{
                    width:80% !important;
                }
                .pink-btn-small{
                    background: var(--white) !important;
                    color:var(--monthly-clr) !important; 
                    margin-bottom: 20px;
                    border:solid 2px transparent; 
                }
                .pink-btn-small:hover{
                    background: var(--monthly-clr) !important;
                    color:var(--white) !important;
                    border:solid 2px var(--monthly-clr);
                }

                .pink-btn-small-outline{
                    background: var(--monthly-clr) !important;
                    color:var(--white) !important; 
                    border:solid 2px var(--white); 
                }
                .pink-btn-small-outline:hover{
                    background: var(--white) !important;
                    color:var(--monthly-clr) !important;
                    border:solid 2px var(--monthly-clr);
                }
            }
        }

    }
}
