.paymentMethodMain {
  margin-right: 1rem;
}
.paymentMethodEmpty {
  min-height: 210px;
}
.paymentMethodsAddButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1199.98px) {
  .paymentMethodMain {
    margin-right: 0;
  }
}
