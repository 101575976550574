.OnboardingVideoModal {
  padding: 40px 20px 20px;
}

.OnboardingVideoModalControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.OnboardingVideoModalControlsBtn {
  min-width: 108px;
  margin-left: 10px;
}

.OnboardingVideoModalControlsBtnDone {
  background-color: #20c997 !important;
  border: solid 2px #20c997 !important;

  &:hover,
  &.active {
    background: var(--white) !important;
    color: #20c997 !important;
  }
}
