.landingDescriptionMain {
  max-width: 1038px;
  margin: 0 auto;
}

.landingDescriptionMainYSpace {
  margin: 104px auto 120px;
}

.landingDescriptionParagraph {
  font-family: var(--base-font-family);
  color: var(--primary-clr);
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  text-align: center;

  .strong {
    font-weight: 500;
  }

  a {
    color: var(--secondary-clr);
    font-family: var(--font-base-bold);
    text-decoration: underline;
}
}

.landingDescriptionParagraph:not(:first-of-type) {
  margin-top: 20px;
}

@media (max-width: 991.98px) {
  .landingDescriptionMain {
    max-width: 615px;
  }
  .landingDescriptionMainYSpace {
    margin: 34px auto 50px;
  }
  .landingDescriptionParagraph {
    font-size: 16px;
    line-height: 24px;
  }
  .landingDescriptionParagraph:not(:first-of-type) {
    margin-top: 16px;
  }
}

@media (max-width: 575.98px) {
    .landingDescriptionMain {
        max-width: 315px;
      }
}