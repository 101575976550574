.GiftMembershipsMain {
  padding: 37px 30px 0;
}
.GiftMembershipsBanner {
  margin: 0 auto;
  padding: 25px 37px 60px 37px;
  max-width: 1299px;
  width: 100%;
  display: block;
  border-radius: 14px;
  background-image: url("./GCMembershipBanner.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.GiftMembershipsLogo {
  width: 130px;
}
.GiftMembershipsImage {
  margin-right: 70px;
  width: 100%;
  max-width: 402px;
}

.GiftMembershipsContentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.GiftMembershipsText {
  max-width: 452px;
  width: 100%;

  h1 {
    color: #fff;
    font-family: Isidora Sans;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    text-align: left;
    padding: 0;

    .strong {
      font-weight: 700;
    }
  }

  p {
    color: #fff;
    font-family: Isidora Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 30px;
    padding: 0;
  }
}

.GiftMembershipsButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  button {
    width: fit-content;
    height: 40px;
    padding: 0 20px 4px;
  }
}

@media (max-width: 1199.98px) {
  .GiftMembershipsMain {
    padding: 37px 16px 0;
  }
  .GiftMembershipsButtons {
    flex-direction: column;
    button {
      width: 100%;
      margin-top: 8px;
    }
  }
}

@media (max-width: 991.98px) {
  .GiftMembershipsImage {
    margin-right: 30px;
    max-width: 302px;
  }
}

@media (max-width: 767.98px) {
  .GiftMembershipsContentWrapper {
    flex-direction: column;
  }

  .GiftMembershipsText {
    margin-top: 30px;
  }

  .GiftMembershipsBanner {
    padding: 25px 16px 30px;
  }

  .GiftMembershipsImage {
    margin: 0;
  }
}
