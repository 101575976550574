.serviceCardWrapper {
  max-width: 1179px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 30px 30px;
  grid-template-areas: ". . .";
  justify-items: center;
  * {
    box-sizing: border-box;
  }

  @media (max-width: 1260px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
    max-width: 776px;
  }

  @media (max-width: 880px) {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
    max-width: 100%;
    padding: 0 16px;
    gap: 16px 0px;
  }
}

.serviceCard {
  max-width: 373px;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 10px 30px;
  position: relative;
  background-color: #fff;
  border-radius: 20px;

  img {
    border-radius: 14px;
    overflow: hidden;
    width: 100%;
  }

  h3 {
    margin-top: 20px;
    font-family: "Isidora Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #30426d;
  }

  p {
    font-family: "Isidora Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-top: 22px;
  }

  button {
    margin-top: 22px;
  }
}
.serviceCardOverlayTitle {
  background: #4cc4b0;
  border-radius: 3px;
  padding: 3px 10px 5px;
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  width: fit-content;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
}
