.default-plan{
    background: url('../../Assets/img/course/Course-billling-bg.svg') 100px top no-repeat;
    .payment-details {
        border: 1px solid var(--grey-shade-3)!important;
        border-radius: 14px;
        .monthbox-head{
            h4{
                color:var(--primary-clr);
                font-size: var(--font-20);
                font-family: var(--font-base-bold);
            }
            .pCost{
                color:var(--primary-clr);
                font-size: var(--font-24) !important;
                font-weight: bold;
            }
        }

        .monthbox-details{
            padding: 0px 30px 0 30px !important;
            h1, h2, h3, h4, h5, h6{
                margin-top: 0;
                padding: 0;
                font-weight: bolder;
                text-align: start;
            }
            h3{
                font-size: 20px;
            }
        
            p{
                line-height: 18px;
                margin-bottom: 10px !important;
                a{
                    color:var(--secondary-clr);
                    font-weight: bold;
                    text-decoration: underline;
                }
            }
        }

        .apply-couponcode {
            .apply-heading{
                margin-top: 1.5rem;
                font-family: var(--font-base-bold);
            }
            
            
            
        }
        .coupon-applied{
            border: solid 2px var(--secondary-clr);
            border-radius: 14px;
            h6 {
                font-family: var(--font-base-bold);
                font-size: 18px;
                line-height: 34px;
                color: var(--secondary-clr);
                margin: 0;
            }
            a{
                color:var(--primary-clr);
               font-family: var(--font-base-bold);
                text-decoration: underline;
            }
            .disc-amount{
                font-family: var(--font-base-bold);
            }
        }
    }
    .bill-details{
        border: solid 1px var(--grey-shade-3);
         h6 {
            background-color: var(--primary-clr)!important;
            border-radius: 10px 10px 0 0;
         }
         .payspan, .pay-total{
            font-family: var(--font-base-bold) !important;
         }
    }
}

