.bhCoursesLayerTextWrapper {
  position: relative;
}
.bhCoursesLayerCurlyLayer {
  position: absolute;
  left: -364px;
  top: 0;
  z-index: 1;
  width: 558px;
  top: 40px;

  @media (max-width: 1199.98px) {
    display: none;
  }
}

.bhCoursesLayerText {
  position: absolute;
  left: calc(100% + 15px);
  font-family: var(--respondent-medium);
  color: #b693c8;
  white-space: nowrap;
  font-style: italic;
  font-size: 50px;
  bottom: -10px;

  @media (max-width: 991.98px) {
    position: relative;
    bottom: 0;
    left: 0;
    display: inline-block;
  }

  @media (max-width: 767.98px) {
    font-size: 26px;
    bottom: 5px;
  }
}

.bhCoursesCoursesCurly {
  font-family: var(--respondent-medium);
  font-size: 100px;
  color: #50ccb8;
  transform: rotate(-6deg);
  margin-top: 40px;

  @media (max-width: 767.98px) {
    font-size: 50px;
    margin-top: 15px;
  }
}

.bhCourses {
  max-width: 1179px;
  width: 100%;
  margin: 40px auto 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: ". . .";
  justify-items: center;

  > div {
    max-width: 385px;
  }

  @media (max-width: 1260px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
    max-width: 776px;
  }

  @media (max-width: 880px) {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
    max-width: 100%;
    padding: 0 16px;
    gap: 16px 0px;
  }
}
