.preLoginNursesSupportWrapper {
  padding: 0 16px;
  @media (max-width: 991.98px) {
    padding: 0;
  }
}
.preLoginNursesSupport {
  border-radius: 14px;
  max-width: 1380px;
  width: 100%;
  min-height: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #30426d;
  background-image: url(./backgroundCover.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  padding: 38px 30px 43px;

  img {
    object-fit: contain;
    max-width: 438px;
    width: 100%;
    margin-right: 64px;
  }

  .preLoginNursesSupportContent {
    width: 100%;
    max-width: 474px;
  }

  .heading3 {
    font-family: "Isidora Sans medium";
    font-size: 42px !important;
    line-height: 44px;
    color: #fff;
    text-align: left;
    margin: 0;
    padding: 0;

    span {
      font-family: "Isidora Sans bold";
    }
  }

  ol {
    list-style: none;
    counter-reset: item;
    margin-top: 26px;
  }
  li {
    counter-increment: item;
    margin-bottom: 5px;
    margin-top: 16px;
    position: relative;
    color: #fff;
    font-family: "Isidora Sans medium";
    font-size: 18px;
    line-height: 24px;

    span {
      font-family: "Isidora Sans bold";
    }
  }
  li:before {
    content: counter(item) ".";
    color: #4cc3b0;
    font-family: "Isidora Sans medium";
    font-size: 18px;
    line-height: 24px;
    margin-right: 10px;
    display: block;
    position: absolute;
    left: -30px;
  }

  @media (max-width: 991.98px) {
    border-radius: 0;
    flex-direction: column;
    background-image: none;
    img {
      margin: 0;
    }

    .preLoginNursesSupportContent {
      margin-top: 38px;
      max-width: 600px;
    }

    .heading3 {
      text-align: center;
      font-size: 34px !important;
      line-height: 42px;
    }
  }
}
