.ownersStory {
  display: flex;
  margin-bottom: 101px;
  margin-top: 71px;
  overflow: hidden;
}

.ownersStoryPictureWrapper {
  width: 658px;
  min-height: 650px;
  position: relative;
  padding-left: 158px;
  margin-right: 154px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__layer {
    width: 482px;
    border-radius: 14px;
    background-color: var(--pink);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
  &__curlyLine {
    position: absolute;
    top: 30px;
  }
  &__picture {
    width: 500px;
    height: 500px;
    background-image: url(./owners.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    flex-shrink: 0;
  }
}

.ownersStoryTextWrapper {
  width: 50%;
  font-size: 24.0652px;
  line-height: 39px;
  color: #30426d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--base-font-family);
  max-width: 554px;
  margin-right: 24px;
}

@media (max-width: 1399.98px) {
  .ownersStoryPictureWrapper {
    width: 608px;
    padding-left: 108px;
    margin-right: 80px;
  }
}

@media (max-width: 1199.98px) {
  .ownersStoryPictureWrapper {
    width: 540px;
    padding-left: 40px;
    margin-right: 40px;
  }
}

@media (max-width: 991.98px) {
  .ownersStoryPictureWrapper {
    width: 540px;
    padding-left: 30px;
    margin-right: 40px;
    &__picture {
      width: 400px;
      height: 400px;
    }

    &__layer {
      width: 300px;
    }
  }
}

@media (max-width: 767.98px) {
  .ownersStory {
    display: flex;
    flex-direction: column;
    background-color: var(--pink);
    margin-bottom: 0;
    margin-top: 49px;
  }
  .ownersStoryPictureWrapper {
    width: 100%;
    margin-right: 0;
    padding: 40px 40px 0;
    min-height: auto;

    &__picture {
      box-sizing: border-box;
      width: 100%;
      height: auto;
      aspect-ratio: 1;
    }

    &__curlyLine {
      top: 0;
      left: -30px;
      width: 145px;
      height: 180px;
    }

    &__layer {
      width: 100%;
    }
  }

  .ownersStoryTextWrapper {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    padding: 40px 40px 57px;
    text-align: center;
    max-width: 395px;
    margin: 0 auto;
  }
}
