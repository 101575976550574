.bestServicesTitle {
  color: #30426d;
  font-family: Isidora Sans;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  margin: 96px auto 80px;
  padding: 0 16px;
  max-width: 760px;
  width: 100%;
}

.bestServicesSupport {
  padding: 0 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 102px 102px;
  grid-template-areas: ". .";
  justify-items: center;
  * {
    box-sizing: border-box;
  }
}

.bestServicesSupportAlignToEnd {
  justify-content: flex-end;
}
.bestServicesSupportAlignToStart {
  justify-content: flex-start;
}

.bestServicesSupportAlignToEnd,
.bestServicesSupportAlignToStart {
  display: flex;
  width: 100%;

  .bestServicesSupportWrapper {
    flex-direction: column;
    max-width: 449px;
    width: 100%;
  }

  h3 {
    font-family: Isidora Sans;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    padding: 0;
  }

  div {
    margin-top: 20px;
    font-family: Isidora Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #4d4d4d;
  }

  .flex {
    margin: 0;
  }

  .bestServicesBtn {
    width: fit-content;
    margin-top: 16px;
    margin-right: 8px;
    color: #fff;
    border-radius: 40px;
    height: 40px;
    padding: 0 10px;

    &:hover {
      background-color: #fff;
    }

    &--yellow {
      background-color: #f5cf70;
      border: solid 1px #f5cf70;
      &:hover {
        color: #f5cf70;
      }
    }
    &--green {
      background-color: #4cc4b0;
      border: solid 1px #4cc4b0;
      &:hover {
        color: #4cc4b0;
      }
    }
    &--purple {
      background-color: #b693c8;
      border: solid 1px #b693c8;
      &:hover {
        color: #b693c8;
      }
    }
  }
}

.bestServicesSupportText {
  max-width: 449px;
}

.bestServicesSupportImgGroupWrapper {
  width: 100%;
  max-width: 427px;
  max-height: 308px;
  object-fit: contain;
  position: relative;

  .bestServicesSupportImgGroup {
    width: 100%;
  }

  .bestServicesSupportImgLayer {
    width: 47%;
    position: absolute;
    left: -23%;
    top: 60%;
  }
}

.bestServicesSupportImgSleepWrapper {
  width: 100%;
  max-width: 507px;
  object-fit: contain;
  position: relative;

  .bestServicesSupportImgSleep {
    width: 100%;
  }

  .bestServicesSupportImgLayer {
    width: 37%;
    position: absolute;
    left: -18%;
    top: -18%;
  }
}

@media (max-width: 991.98px) {
  .bestServicesSupport {
    padding: 0 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: fit-content fit-content fit-content fit-content;
    gap: 50px 16px;
    grid-template-areas:
      "bestServicesSupport-a"
      "bestServicesSupport-b"
      "bestServicesSupport-d"
      "bestServicesSupport-c"
      "bestServicesSupport-e"
      "bestServicesSupport-f";
    justify-items: center;
    .bestServicesSupport-a {
      grid-area: bestServicesSupport-a;
    }
    .bestServicesSupport-b {
      grid-area: bestServicesSupport-b;
    }
    .bestServicesSupport-c {
      grid-area: bestServicesSupport-c;
    }
    .bestServicesSupport-d {
      grid-area: bestServicesSupport-d;
    }
    .bestServicesSupport-e {
      grid-area: bestServicesSupport-e;
    }
    .bestServicesSupport-f {
      grid-area: bestServicesSupport-f;
    }
  }

  .bestServicesSupportAlignToEnd,
  .bestServicesSupportAlignToStart {
    flex-direction: column;
    align-items: center;
  }

  .bestServicesSupportImgGroupWrapper {
    .bestServicesSupportImgLayer {
      left: auto;
      right: 0;
      bottom: 0;
    }
  }
  .bestServicesSupportImgSleepWrapper {
    .bestServicesSupportImgLayer {
      top: auto;
      left: auto;
      right: 0;
      bottom: -30px;
    }
  }
}

@media (max-width: 1199.98px) {
  .bestServicesSupportImgGroupWrapper {
    .bestServicesSupportImgLayer {
      top: 50%;
    }
  }
}
