.lockAskANurse {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #30426d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &__central {
    max-width: 480px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__title {
    font-size: 42px;
    line-height: 56px;
    margin: 0;
    font-family: var(--base-font-family);
    color: #fff;

    &--green {
      color: #4cc3b0;
    }
  }

  &__description {
    font-family: var(--base-font-family);
    font-size: var(--font-14);
    word-break: break-word;
    color: #fff;
  }
}
