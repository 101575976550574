.receivedGiftCardsCopyMain {
  display: flex;
  align-items: center;
}

.receivedGiftCardsGiftedFrom {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #f57d7d;
  font-family: var(--base-font-family);
}

.receivedGiftCardsAmount {
  display: flex;
  align-items: center;

  div {
    margin-right: 10px;
    font-family: var(--font-base-bold);
    color: var(--secondary-clr);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
  }
}

.receivedGiftCardsCopyMain {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}