.statistics {
  background: url(../../Assets/img/sponsors/ourAudience.svg) #30426d no-repeat;
  border-radius: 14px;
  background-position: left top;
  color: var(--white);
  padding-top: 60px;
  padding-bottom: 100px;

  h2 {
    color: var(--white);
  }
  .statics {
    .count {
      font-size: 67px;
      font-family: var(--font-base-bold);
    }
    .value {
      font-size: 77px;
      font-family: var(--Respondent-Bold);
    }
    .sm-follorers {
      color: var(--monthly-clr);
    }
    .nurture-member {
      color: var(--halfyearly-clr);
    }
    .email-subscriber {
      color: var(--orange);
    }
    .podcast-download {
      color: var(--quaterly-clr);
    }
    .webinars-view {
      color: var(--pink-lt);
    }
  }
  .stats-head {
    margin-top: -50px;
  }
}
