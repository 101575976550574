@import "../../../global.css";
@import "../../../global-componant.css";

.post-login{
    .members-banner{
        .postLoginBanner{
            img {
                width: 6vw;
            }

            h1{
                text-align: left;
                font-weight: var(--font-wt500);
                padding-bottom: 0;
                color:var(--white);
                font-size: 3.62vw !important;
                line-height: 4.2vw;

                .strong{
                    color:var(--secondary-clr);
                    font-weight: var(--font-wt700);
                }
            }
            p{
                margin-top: 4.5vw !important;
                max-width: 33.3vw;
                font-size: 1.449vw;
                line-height: 2.6vw;
            }
        }
        .member-info{
            // height: 110px;
            
           .profile-info{
            // border-right: solid 1px #949494;
            width: 50%;
                h3{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 26px;
                    color: var(--primary-clr);
                }
                .edit-profile{
                    font-weight: var(--font-wt700);
                    color: var(--secondary-clr);
                    text-decoration: underline;
                    cursor: pointer;
                }
                .profile-pic-container{
                    // position: absolute;
                    // left: 0;
                    // top: 8px;
                    // transform: translate(0%, 0%);
                    .profile-pic{
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-color: lightgrey;
                    }
                }
                .profile-progress{
                    width: 100px !important;
                    height: 100px !important;
                    .MuiCircularProgress-circle{
                        stroke-width:1.5;
                        color: var(--secondary-clr);
                    }
                }
           } 
           
           .random-messages{
                width:50%;
                h3{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 26px;
                    color:var(--primary-clr);
                }
           }
        }
        .recommended-classes{
            .recommended-classes-inner{
                .video-container-inner{
                    min-height: 260px !important;
                    img{
                        min-height: 260px !important;
                        width:100%;
                    }
                    .support-thumb{
                        background: url('../../../Assets/img/homepage/Support-group.jpg') center center;
                        background-size: cover;
                    }
    
                    .sleep-consult{
                        background: url('../../../Assets/img/homepage/sleep-consult-ft.jpg') center center;
                        background-size: cover;
                    }
                    .nurse-consult{
                        background: url('../../../Assets/img/homepage/sleep-consult-ft.jpg') center center;
                        background-size: cover;
                    }
                }
                
            }
        }
    }
        
    
    .get-ready{
        background: var(--bg-paleyellow-clr) url(../../../Assets/img//homepage/get-ready-bg.svg) no-repeat;
        background-position: right 50px;
        .section-heading{
            color: var(--primary-clr);
        }
        // .card{
        //     border-radius: 20px;
        //     box-shadow: 0px 4px 14px rgba(148, 148, 148, 0.1);
        //     border: none;
        //     min-height: 500px;
        //     .progress-bar-container{
        //         .progress{
        //             height: 5px;
        //             margin-bottom: 10px;
        //         }
        //     }
        //     .buy-now{
        //         background: var(--primary-clr);
        //         border-radius: 50px;
        //         min-width: 130px;
        //         padding: 150x 30px;
        //         color: var(--white);
        //         font-size: var(--font-16);
        //         font-family: var(--font-sbold);
        //         border: none;
        //         // margin-top: 25px;
        //     }
        //     .video-container-inner{
        //         padding: 10px;
        //         position: relative;
        //         height: 200px;
        //         overflow-y: hidden;
        //         img{
        //             border-radius: 14px;
        //             // margin: 15px;
        //             width: 100%;
        //             height: auto;
        //         }
        //         .tags-likes{
        //             position: absolute;
        //             top:10px;
        //             width: calc(100% - 40px);
        //             // padding: 10px;
        //             margin: 10px;
        //             .tags-row{
        //                 position: absolute;
        //                 top:0px;
        //             }
        //             .fav-container{
        //                 width:50px;
        //                 height: 50px;
        //                 border-radius: 50%;
        //                 position: absolute;
        //                 right: 0;
        //                 top: -5px;
        //                 background: #FFFFFF;
        //                 box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        //                 display: flex;
        //                 align-items: center;
        //                 justify-content: center;
        //                 img{
        //                     width: 100%;
        //                     height: 23px;
        //                     cursor: pointer;
        //                 }
        //             }
        //         }
        //     }
        //     .card-title{
        //         margin-bottom: 0;
        //         color: var(--primary-clr);
        //         font-size: var(--font-20);
        //         font-family: var(--font-sbold);
        //         display: -webkit-box;
        //         -webkit-line-clamp: 2;
        //         -webkit-box-orient: vertical;
        //         overflow: hidden;
        //         margin-right: .5rem;
        //         min-height: 48px;
        //     }
        //     .card-body+.card-body{
        //         padding-top: 0.25rem;
        //     }
        //     .card-body{
        //         .like
        //         {
        //             min-width: 60px;
        //             display: flex;
        //             align-items: center;
        //             color: var(--primary-clr);
        //             img{
        //                 margin-right: 5px;
        //                 cursor: pointer;
        //             }
        //         }
        //         .event-time{
        //             font-size: var(--font-14);
        //             font-family: var(-font-sbold);
        //             color: --secondary-clr;
        //             color: var(--secondary-clr);
        //         }
        //         .card-text{
        //             margin-bottom: 0;
        //             display: -webkit-box;
        //             -webkit-line-clamp: 5;
        //             -webkit-box-orient: vertical;
        //             overflow: hidden;
        //         }
        //         .view-details{
        //             margin: 10px 0;
        //             color: var(--secondary-clr);
        //             font-family: var(--font-sbold);
        //             font-size: var(--font-14);
        //             cursor: pointer;
        //         }
        //         .course-price{
        //             color: var(--primary-clr);
        //             font-family: var(--font-sbold);
        //             font-size: var(--font-26);
        //         }
        //         .card-footer{
        //             position: absolute;
        //             left: 0px;
        //             bottom: 15px;
        //             width: 100%;
        //             border: 0;
        //             background-color: transparent;
        //         }
        //     }
        // }
    }
    
    .Upcoming-event-container{
        background: var(--secondary-clr) url('../../../Assets/img/homepage/upcoming-events-bg.svg') no-repeat;
        background-position: right bottom;
        border-radius: 14px;
        margin-left: 15px;
        margin-right: 15px;
        // .card{
        //     border-radius: 20px;
        //     box-shadow: 0px 4px 14px rgba(148, 148, 148, 0.1);
        //     border: none;
        //     min-height: 500px;
        //     .progress-bar-container{
        //         .progress{
        //             height: 5px;
        //             margin-bottom: 10px;
        //         }
        //     }
        //     .video-getstarted{
        //         background: var(--primary-clr);
        //         border-radius: 50px;
        //         min-width: 130px;
        //         padding: 150x 30px;
        //         color: var(--white);
        //         font-size: var(--font-16);
        //         font-family: var(--font-sbold);
        //         // margin-top: 25px;
        //     }
        //     .video-container-inner{
        //         img{
        //             border-radius: 14px;
        //             // margin: 15px;
        //             width: 100%;
        //             height: auto;
        //         }
        //         padding: 10px;
        //         position: relative;
        //         height: 200px;
        //         overflow-y: hidden;
        //         .tags-likes{
        //             position: absolute;
        //             top:10px;
        //             width: calc(100% - 40px);
        //             // padding: 10px;
        //             margin: 10px;
        //             .tags-row{
        //                 position: absolute;
        //                 top:0px;
        //             }
        //             .fav-container{
        //                 width:50px;
        //                 height: 50px;
        //                 border-radius: 50%;
        //                 position: absolute;
        //                 right: 0;
        //                 top: -5px;
        //                 background: #FFFFFF;
        //                 box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        //                 display: flex;
        //                 align-items: center;
        //                 justify-content: center;
        //                 img{
        //                     width: 100%;
        //                     height: 23px;
        //                     cursor: pointer;
        //                 }
        //             }
        //         }
        //     }
        //     .card-title{
        //         margin-bottom: 0;
        //         color: var(--primary-clr);
        //         font-size: var(--font-20);
        //         font-family: var(--font-sbold);
        //         display: -webkit-box;
        //         -webkit-line-clamp: 2;
        //         -webkit-box-orient: vertical;  
        //         overflow: hidden;
        //         margin-right: .5rem;
        //         min-height: 48px;
        //     }
        //     .card-body+.card-body{
        //         padding-top: 0.25rem;
        //     }
        //     .card-body{
        //         .like
        //         {
        //             min-width: 60px;
        //             display: flex;
        //             align-items: center;
        //             img{
        //                 margin-right: 5px;
        //                 cursor: pointer;
        //             }
        //         }
        //         .event-time{
        //             font-size: var(--font-14);
        //             font-family: var(-font-sbold);
        //             color: --secondary-clr;
        //             color: var(--secondary-clr);
        //         }
        //         .card-text{
        //             margin-bottom: 0;
        //             display: -webkit-box;
        //             -webkit-line-clamp: 5;
        //             -webkit-box-orient: vertical;  
        //             overflow: hidden;
        //         }
        //         .card-footer{
        //             position: absolute;
        //             left: 0px;
        //             bottom: 15px;
        //             width: 100%;
        //             border: 0;
        //             background-color: transparent;
        //         }
        //     }
        // }
    }
    
    .recommended-classes{
        .section-heading{
            color: var(--primary-clr);
        }
        .card{
            border-radius: 20px;
            box-shadow: 0px 4px 14px rgba(148, 148, 148, 0.1);
            border: none;
            min-height: 500px;
            .progress-bar-container{
                .progress{
                    height: 5px;
                    margin-bottom: 10px;
                }
            }
            .video-container-inner{
                padding: 10px;
                position: relative;
                height: 260px;
                overflow-y: hidden;
                border-radius: 14px;
                img{
                    border-radius: 14px;
                    // margin: 15px;
                    width: 100% !important;
                    min-height: 230px;
                    display: flex;
                }

                // img{
                //     min-height: 260px !important;
                //     width:100%;
                // }
                .support-thumb{
                    background: url('../../../Assets/img/homepage/Support-group.jpg') center center;
                    background-size: cover;
                    width:100%;
                }

                .sleep-consult{
                    background: url('../../../Assets/img/homepage/sleep-consult-ft.jpg') center center;
                    background-size: cover;
                }
                .nurse-consult{
                    background: url('../../../Assets/img/homepage/consult-nurse.jpg') center center;
                    background-size: cover;
                }
                
            }
            .card-title{
                margin-bottom: 0;
                color: var(--primary-clr);
                font-size: var(--font-20);
                font-family: var(--font-sbold);
                overflow: hidden;
                max-width: 350px;
                text-overflow: ellipsis;
                min-height: 30px;
            }
            .card-body+.card-body{
                padding-top: 0.25rem;
            }
            .card-body{
                .card-text{
                    P{
                        margin-bottom: 0;
                        display: -webkit-box;
                        -webkit-line-clamp: 5;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        min-height: 105px;
                    }
                }
                .card-footer{
                    position: absolute;
                    left: 0px;
                    bottom: 15px;
                    width: 100%;
                    border: 0;
                    background-color: transparent;
                }
            }
            .instructor-dtls{
                .instructor-profile{
                    border-radius: 50%;
                    width: 52px;
                    height: 52px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: lightgrey;
                }
                .instructor-name{
                    p{
                        font-size: 14px;
                        line-height: 18px;
                        color:var(--black);
                        margin:0;
                    }
                }
            }
        }
    }
    
    .become-member-container{
        background: url(../../../Assets/img/course/become-member.png) no-repeat;
        // background-size: cover;
        border-radius: 14px;
        background-size: cover;
        .become-meber-captions{
            h3{
                color:var(--white);
                font-family: var(--base-font-family);
                font-size: var(--font-h2-34);
                font-weight: 300;
                span{
                    display: block;
                    font-weight: 700;
                    color: var(--secondary-clr);
                }
            }
            p{
                font-size: var(--font-14);
                font-weight: 300;
                color: var(--white);
                margin: 0;
            }
            .price-dtls{
                .pvalue{
                    color:var(--white);
                    font-family: var(--font-sbold);
                    font-size: var(--font-h1-42);
                    font-weight: 900;
                    font-weight: var(--font-wt900);
                }
            }
        }
    }
    
    .latest-news{
        font-family: var(--base-font-family);
        .section-heading {
            color: var(--primary-clr);
            text-align: left;
        }
        .featured-blog-main{
            cursor: pointer;
            .featured-blog-img{
                img{
                    border-radius: 14px;
                }
            }
        }
        .blog-details{
            .blog-date{
                font-weight: var(--font-wt500);
                font-size: 12px;
                line-height: 16px;
                color:var(--primary-text);
            }
            .blog-title{
                h3{
                    font-weight: var(--font-wt700);
                    font-size:18px;
                    line-height: 24px;
                    color: var(--primary-clr);
                    overflow: hidden;
                    max-width: 700px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width:100%;
                }
            }
            .blog-description{
                p{
                    margin-bottom: 0;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
        .blog-list{
            cursor: pointer;
            .blog-thumb-main{
                width: 30%;
                .blog-thumb{
                    width:100%;
                    border-radius: 10px;
                    overflow: hidden;
                    height: 105px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    // img{
                    //     border-radius: 14px;
                    // }
                }
            }
            .blog-details{
                width:70%;
                .blog-date{
                    font-weight: var(--font-wt500);
                    font-size: 12px;
                    line-height: 16px;
                    color:var(--primary-text);
                }
                .blog-title{
                    h3{
                        font-weight: var(--font-wt700);
                        font-size: 20px;
                        line-height: 24px;
                        color: var(--primary-clr);
                        overflow: hidden;
                        max-width: 500px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width:100%;
                    }
                }
                .blog-description{
                    p{
                        margin-bottom: 0;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }
        }
        .blog-list + .blog-list{
            margin-top: 20px;
        }
    }

    .ongoing-course{
        // background: var(--bg-paleyellow-clr) url(../../../Assets/img//homepage/get-ready-bg.svg) no-repeat;
        // background-position: right 50px;
        .section-heading{
            color: var(--primary-clr);
        }
        // .card{
        //     border-radius: 20px;
        //     box-shadow: 0px 4px 14px rgba(148, 148, 148, 0.1);
        //     border: none;
        //     min-height: 550px;
        //     .progress-bar-container{
        //         .progress{
        //             height: 5px;
        //             margin-bottom: 10px;
        //         }
        //     }
        //     .buy-now{
        //         background: var(--primary-clr);
        //         border-radius: 50px;
        //         min-width: 130px;
        //         padding: 150x 30px;
        //         color: var(--white);
        //         font-size: var(--font-16);
        //         font-family: var(--font-sbold);
        //         border: none;
        //         // margin-top: 25px;
        //     }
        //     .video-container-inner{
        //         padding: 10px;
        //         position: relative;
        //         height: 200px;
        //         overflow-y: hidden;
        //         img{
        //             border-radius: 14px;
        //             // margin: 15px;
        //             width: 100%;
        //             height: auto;
        //         }
        //         .tags-likes{
        //             position: absolute;
        //             top:10px;
        //             width: calc(100% - 40px);
        //             // padding: 10px;
        //             margin: 10px;
        //             .tags-row{
        //                 position: absolute;
        //                 top:0px;
        //             }
        //             .fav-container{
        //                 width:50px;
        //                 height: 50px;
        //                 border-radius: 50%;
        //                 position: absolute;
        //                 right: 0;
        //                 top: -5px;
        //                 background: #FFFFFF;
        //                 box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        //                 display: flex;
        //                 align-items: center;
        //                 justify-content: center;
        //                 img{
        //                     width: 100%;
        //                     height: 23px;
        //                     cursor: pointer;
        //                 }
        //             }
        //         }
        //     }
        //     .card-title{
        //         margin-bottom: 0;
        //         color: var(--primary-clr);
        //         font-size: var(--font-20);
        //         font-family: var(--font-sbold);
        //         display: -webkit-box;
        //         -webkit-line-clamp: 2;
        //         -webkit-box-orient: vertical;
        //         overflow: hidden;
        //         margin-right: .5rem;
        //         min-height: 48px;
        //     }
        //     .card-body+.card-body{
        //         padding-top: 0.25rem;
        //     }
        //     .card-body{
        //         .like
        //         {
        //             min-width: 60px;
        //             display: flex;
        //             align-items: center;
        //             color: var(--primary-clr);
        //             img{
        //                 margin-right: 5px;
        //                 cursor: pointer;
        //             }
        //         }
        //         .event-time{
        //             font-size: var(--font-14);
        //             font-family: var(-font-sbold);
        //             color: --secondary-clr;
        //             color: var(--secondary-clr);
        //         }
        //         .card-text{
        //             margin-bottom: 0;
        //             display: -webkit-box;
        //             -webkit-line-clamp: 5;
        //             -webkit-box-orient: vertical;
        //             overflow: hidden;
        //         }
        //         .view-details{
        //             margin: 10px 0;
        //             color: var(--secondary-clr);
        //             font-family: var(--font-sbold);
        //             font-size: var(--font-14);
        //             cursor: pointer;
        //         }
        //         .course-price{
        //             color: var(--primary-clr);
        //             font-family: var(--font-sbold);
        //             font-size: var(--font-26);
        //         }
        //         .card-footer{
        //             position: absolute;
        //             left: 0px;
        //             bottom: 15px;
        //             width: 100%;
        //             border: 0;
        //             background-color: transparent;
        //         }
        //     }
        // }
    }
    
}

.free-consult{
    .recorder-session-ntf-block{
        background: var(--pastel-purple) url(../../../Assets/img/homepage/free-consult.svg);
        background-repeat: no-repeat;
        background-position: 0 -10px;
        background-size: cover;
        border-radius: 14px;
        .sec-caption{
            max-width: 600px;
            margin-right: auto;
            margin-left: auto;
            text-align: center;
            h2{
                color: var(--white);
                font-family: var(--base-font-family);
                font-size: var(--font-h2-34);
                margin-bottom: 15px;
                padding-bottom: 0;
            }
            p{
                color: var(--white);
            }
        }

    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    .post-login{
        .members-banner{
            .member-info{
                // height: 240px;
                .profile-info{
                    width:100% !important;
                    border-right: none;
                }
            }
            .banner{
                h1{
                    min-width: unset;
                }
            }
        }
        .Upcoming-event-container{
            border-radius:0;
        }
    }
    

    .random-messages{
        width:100% !important;
    }
 }

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .paddingLR-0{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .margineLR-0{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .post-login{
        .members-banner{
            .postLoginBanner{
                position: relative;
                padding-top: 9vw;
                img {
                    width: 14.57vw;
                    position: absolute;
                    top: 6vw;
                    left: 8vw
                }
                h1{
                    font-size: 7.58vw !important;
                    line-height: 10.49vw;
                    text-align: center;
                }
                p{
                    font-size: 4.66vw !important;
                    line-height: 6.96vw;
                    max-width: 71.34vw;
                    margin-top: 5vw !important;
                }
            }

            .member-info{
                // height: 240px;
                .profile-info{
                    width:100% !important;
                    border-right: none;
                    border-bottom: solid 1px #949494;
                    padding-bottom: 20px !important;
                    margin-bottom: 10px !important;
                }
            }
        }
        .Upcoming-event-container{
            border-radius:0;
            background-image: unset;
        }

        .get-ready{
            background-image: unset;
        }

        .latest-news{
            .blog-list {
                .blog-details {
                    width:100%;
                }
            }
        }
    }

    .random-messages{
        width:100% !important;
    }

    .service-block:nth-child(odd) {
        margin-bottom: 0 !important;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .post-login{
        .members-banner{
            .banner{
                background-position-x: 68%;
            }
        }
        .members-banner{
            .member-info{
                // height: 240px;
            }
        }
        .Upcoming-event-container{
            border-radius:0;
        }
        .latest-news{
            .blog-list-container{
                width:100% !important;
                margin-top: 20px;
                .blog-list{
                    .blog-thumb {
                        
                    }
                }
            }
        }
        .featured-blog-main{
            width:100% !important;
        }
        
    }
    
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .post-login{
        .latest-news{
            .blog-list {
                .blog-thumb {
                    width: 135px;
                }
                .blog-details{
                    .blog-title{
                        h3{
                            max-width: 350px;
                        }
                    }
                }
            }
        }
    }
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {  }
