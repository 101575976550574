.GiftCardBannerWrapper {
  padding: 37px 30px 0;
  margin: 0 auto;
  max-width: 1440px;
  position: relative;

  .GiftCardBanner {
    width: 100%;
    display: block;
  }
  .GiftCardBannerMobile {
    display: none;
  }
}
.GiftCardBannerTextWrapper {
  padding: 37px 142px 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
}
.GiftCardBannerText {
  max-width: 428px;
  width: 100%;

  h1 {
    color: #f57d7d;
    font-family: var(--Respondent-Bold);
    font-size: 80px !important;
    margin: 0 0 16px;
    padding: 0;
  }

  .pOne {
    color: #fff;
    font-family: Isidora Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin: 0;
    padding: 0;
  }

  .pTwo {
    font-size: 18px;
    font-weight: 500;
    color: #c1c1c1;
  }
}

.GiftCardBannerTitleMobile {
  display: none;
}

@media (max-width: 767.98px) {
  .GiftCardBannerWrapper {
    .GiftCardBanner {
      display: none;
    }
    .GiftCardBannerMobile {
      width: 100%;
      display: block;
    }
  }

  .GiftCardBannerTitleMobile {
    display: block;
    color: #30426D;
    font-family: var(--respondent-medium);
    font-size: 40px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    padding: 20px 0 4px;
  }
  .GiftCardBannerWrapper {
    padding: 0 10px;
  }
  .GiftCardBannerTextWrapper {
    padding: 12px 18px;
    align-items: flex-start;
    width: 44vw;
  }
  .GiftCardBannerText {
    h1,
    .pOne {
      display: none;
    }

    .pTwo {
      color: #fff;
      font-family: Isidora Sans;
      font-size: 3.8vw;
      font-style: normal;
      font-weight: 600;
    }
  }
}
