.BUMPHeroWrapper {
  background-image: url(../../assets/bumpHero.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fbf8ef;
  display: flex;
  justify-content: flex-end;
  padding: 115px 51px 51px;
  position: relative;
}

.BUMPHeroContent {
  max-width: calc(646px + 131px);
  width: 100%;
  display: flex;
  flex-direction: column;

  &__logo {
    background-image: url(../../assets/bumpMainLogo.png);
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    max-width: 342px;
    width: 100%;
    aspect-ratio: calc(545 / 105);
  }

  &__text {
    max-width: 646px;
    margin-top: 47px;
    font-family: "Isidora Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #30426d;
  }

  &__strong-text {
    font-family: var(--font-base-bold);
    font-size: 22px;
  }

  &__btn {
    width: fit-content;
    margin-top: 20px;
  }
}

.BUMPHeroCode {
  padding: 20px 54.5px;
  border-radius: 23px;
  background: #f5cf70;
  width: fit-content;
  align-self: flex-end;

  h2 {
    margin: 0;
    padding: 0;
    font-family: var(--font-base-bold);
    font-style: normal;
    font-size: 65px;

    line-height: 80px;
    color: #30426d;
  }

  p {
    font-family: "Isidora Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 48.542px;
    line-height: 115.47%;
    color: #30426d;
  }
  strong {
    font-family: var(--font-base-bold);
    text-transform: uppercase;
  }
}

@media screen and (max-width: 767px) {
  .BUMPHeroWrapper {
    background-position: bottom right;
    background-size: 115%;
    padding: 14px 36px 63px;
  }

  .BUMPHeroContent {
    text-align: center;
    align-items: center;

    &__logo {
      margin-top: 50px;
    }

    &__text {
      margin-top: 53px;
      font-size: 14px;
      line-height: 23px;
      color: #30426d;
    }

    &__strong-text {
      font-family: var(--font-base-bold);
      line-height: 23px;
      font-size: 14px;
    }

    &__btn {
      display: none;
    }
  }

  .BUMPHeroCode {
    padding: 10px 20px;
    align-self: center;
    text-align: left;

    h2 {
      font-size: 30px;
      line-height: 30px;
    }

    p {
      font-size: 18px;
      line-height: 16px;
      margin-top: 10px;
    }
  }

  .BUMPHeroContentCheckOut {
    display: none;
  }
}
