.bhUnswaddled {
  max-width: 1380px;
  padding: 0 26px;
  width: 100%;
  margin: 78px auto 95px;
  box-sizing: border-box;
}
@media (max-width: 767.98px) {
  .bhUnswaddled {
    padding: 0;
  }
}
