@import "../../../global.css";
@import "../../../global-componant.css";

.sleepConsultation{   
    .section-header {
        h2.home-section-title{
            font-size: var(--font-50) !important;
            color:var(--primary-clr);
            font-weight: 400;
        }
    }

    .offering-main{
        .offering-block-content{
            h3{
                font-size: var(--font-50);
                font-family: var(--base-font-family);
                font-weight: bold;
                color:var(--primary-text);
                margin-bottom: 1.2rem;
                span.offer-number{
                    font-family: var(--base-font-family);
                    font-weight:400;
                    // color: var(--secondary-clr);
                }
            }
        }

        .offer-number1{
            .offer-number{
                color:var(--secondary-clr);
            }
        }

        .offer-number2{
            .offer-number{
                color:var(--halfyearly-clr);
            }
        }

        .service-feature-img{
            border-radius: 14px;
            overflow: hidden;
            img{
                border-radius: 14px;
            }
        }
    }

    .become-member-container{
        background: url(../../../Assets/img/course/become-member.png) no-repeat;
        // background-size: cover;
        border-radius: 14px;
        background-size: cover;
        .become-meber-captions{
            h3{
                color:var(--white);
                font-family: var(--base-font-family);
                font-size: var(--font-h2-34);
                font-weight: 300;
                span{
                    display: block;
                    font-weight: 700;
                    color: var(--secondary-clr);
                }
            }
            p{
                font-size: var(--font-14);
                font-weight: 300;
                color: var(--white);
                margin: 0;
                .strong{
                    font-weight: bold;
                    color:var(--white);
                }
            }
            .price-dtls{
                .pvalue{
                    color:var(--white);
                    font-family: var(--font-sbold);
                    font-size: var(--font-h1-42);
                    font-weight: 900;
                    font-weight: var(--font-wt900);
                }
            }
            .become-member-pointers{
                ol{
                    padding: 0;
                }
                li{
                    list-style-type: none;
                    
                    .li-counter{
                        font-size: 16px;
                        color:var(--secondary-clr) !important; 
                        font-family: var(--font-base-bold);
                        padding-right: 10px;
                        display: table-cell;
                    }
                    p{
                        font-size: 14px;
                        display: table;
                    }
                }
            }
        }
    }

    .question-main{
        .question-inner{
            background: var(--pastel-purple) url('../../../Assets/img/Promises/story-sec-main.svg') right 0px bottom 0px no-repeat;
            // background: var(--pastel-purple);
            background-repeat: no-repeat;
            // background-position: 0 -10px;
            background-size: cover;
            border-radius: 14px;
            .sec-caption{
                max-width: 600px;
                margin-right: auto;
                margin-left: auto;
                text-align: center;
                h2{
                    color: var(--white);
                    font-family: var(--base-font-family);
                    font-size: var(--font-h2-34);
                    margin-bottom: 15px;
                    padding-bottom: 0;
                }
                p{
                    color: var(--white);
                }
            }
    
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .sleepConsultation{
        .offering-main{
            .offering-block-content{
                h3{
                font-size: 45px;
                }
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .sleepConsultation{
        .offering-main{
            .offering-block-content{
                margin-right: 1rem!important;
                h3{
                font-size: 34px;
                }
            }

            .service-feature-img{
                margin-right: 1rem!important;
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .sleepConsultation{ 
        .section-header {
            h2.home-section-title{
                font-size: var(--font-50) !important;
                color:var(--primary-clr);
                font-weight: 400;
            }
        }

        .become-member{
            .container{
                padding: 0 !important;
                .become-member-container{
                    background-size: cover;
                    text-align: center;
                    padding-left: 30px !important;
                    padding-right: 30px !important;
                }
            }
        }

       
    }
}