.magnetHeroWrapper {
  padding: 0 23.79px;

  &__curlyLayer {
    border-radius: 33.6912px;
    position: relative;
    overflow: hidden;
    background-color: #30426d;

    &:before,
    &:after {
      content: " ";
      display: block;
      background-image: url("./curlyLine.svg");
      width: 341px;
      height: 460px;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
    }

    &:before {
      transform: rotate(5deg);
      top: -18px;
      left: 0;
    }
    &:after {
      right: -50px;
      bottom: -60%;
    }
  }

  &__imageLayer {
    background-repeat: no-repeat;
    background-size: auto;
    min-height: 486px;
    width: 100%;
    position: relative;
    z-index: 1;
    background-position-x: 20%;
    background-position-y: bottom;
    display: flex;
    justify-content: flex-end;
  }

  &__textWrapper {
    min-width: 50%;
    padding: 16px 90px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3,
    p,
    h1 {
      max-width: 534px;
      padding: 0;
      margin: 0;
      text-shadow: 2px 1px 2px #30426d;
    }

    h3 {
      color: var(--white);
      font-family: var(--respondent-medium);
      font-style: italic;
      font-weight: 700;
      font-size: 60.9853px;
      line-height: 57px;
    }
    p {
      color: var(--white);
      font-family: "Isidora Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 26.4717px;
      line-height: 34px;
    }
    h1 {
      color: var(--white);
      text-align: left;
      font-family: "Isidora Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 62px !important;
      line-height: 72px;
    }

    .teal {
      color: var(--teel);
    }
  }

  &__mobileImg {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .magnetHeroWrapper {
    padding: 0 16px;

    &__curlyLayer {
      &:before,
      &:after {
        width: 30vw;
        height: 30vw;
      }

      &:before {
        transform: rotate(-5deg);
        top: 0vw;
        left: -5.5vw;
      }
      &:after {
        transform: rotate(3deg);
        width: 46vw;
        height: 62vw;
        right: -6vw;
        bottom: -17vw;
      }
    }

    &__imageLayer {
      background: none !important;
      flex-direction: column;
      min-height: auto;
    }

    &__textWrapper {
      width: 100%;
      padding: 33px 23px 0;
      justify-content: flex-start;

      h3,
      p,
      h1 {
        text-align: center;
        max-width: 100%;
      }

      h3 {
        font-size: 36px;
        line-height: 36px;
      }
      p {
        font-size: 14px;
        line-height: 26px;
      }
      h1 {
        font-size: 26px !important;
        line-height: 30px;
        max-width: 241px;
        margin: 0 auto;
      }
    }
    &__mobileImg {
      display: block;
      max-width: 244px;
      margin: 3px auto 0;
      object-fit: contain;
      transform: translateX(10%);
    }
  }
}
