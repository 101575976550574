.updateTariffModalPopUpWindow {
  padding: 0 !important;

  .modal-dialog {
    max-width: 864px !important;
  }

  .modal-content {
    border-radius: 14px;
    background-color: transparent;
  }
}

.updateTariffModalPopUp {
  padding: 64px 36px 36px;
  box-shadow: 0px 15px 58px rgba(0, 0, 0, 0.13) !important;
  background-color: #fff;
  border-radius: 14px;
  border: none !important;
  text-align: center;

  h2,
  h3,
  p {
    font-family: "Isidora Sans";
    font-style: normal;
    color: #30426d;
    padding: 0;
    margin: 0;
  }

  h2 {
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
  }

  h3 {
    font-weight: 600;
    font-size: 26px;
    line-height: 42px;
    padding-top: 30px;
    max-width: 582px;
    margin: 0 auto;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px;
  }

  @media (max-width: 767.98px) {
    padding: 33px;
  }

  @media (max-width: 575.98px) {
    padding: 33px 16px;
  }
}

.updateTariffModalBenefits {
  width: fit-content;
  margin: 0 auto;
  padding: 0;
}

.updateTariffModalBenefitsLi {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 500;
  color: #4d4d4d;
  list-style-type: none;
  padding-left: 30px;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
  text-align: start;

  &:before {
    content: url(./downgrageBenefit.svg);
    width: 18px;
    height: 18px;
    display: block;
    position: absolute;
    left: 0;
  }

  &--positive {
    &:before {
      content: url(./upgradeBenefit.svg);
    }
  }

  &--price {
    text-decoration: line-through;
    font-family: var(--font-base-bold);
  }
}

.updateTariffModalMembershipCard {
  width: fit-content;
  min-width: 364px;
  margin: 0 auto;
  text-align: left;
  margin-top: 34px;

  h3 {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    font-family: var(--base-font-family);
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    color: #fff;
    margin: 0;
    width: 100%;
    padding: 20px 43px;
  }
  &--green {
    background-color: #4cc3b0;
  }
  &--red {
    background-color: #f57d7d;
  }

  @media (max-width: 575.98px) {
    min-width: fit-content;
  }
}

.updateTariffModalMembershipCardContent {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  border: 1px solid #f57d7d;
  border-top: 0;
  padding: 10px 22px 33px;

  &__pricing {
    display: flex;
    align-items: flex-start;
    padding-left: 26px;
  }

  &__price {
    font-family: var(--font-base-bold);
    font-size: 44px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px;
    margin-right: 8px;
    color: #30426d;

    &--lineThrough {
      font-family: Isidora Sans;
      font-weight: 400;
      text-decoration: line-through;
    }
  }

  &__frequency {
    margin-top: 4px;
    font-family: Isidora Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: #30426d;
    line-height: 26px;
  }

  &__sum {
    padding-left: 26px;
    font-family: Isidora Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #4d4d4d;
    margin-top: 11px;

    &--price {
      font-family: var(--font-base-bold);
    }
  }

  &__line {
    border-bottom: 1px solid #989898;
    margin-top: 20px;
  }
}

.updateTariffModalButtons {
  margin-top: 26px;

  &__confirm {
    margin-right: 40px;
  }
  &__close {
    background-color: #4cc3b0 !important;
    border-color: #4cc3b0 !important;

    &:hover {
      color: #4cc3b0 !important;
    }
  }

  @media (max-width: 767.98px) {
    button {
      width: 100%;
      margin-top: 16px;
    }
  }
}
