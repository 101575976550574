.legal {
  strong {
    font-family: var(--font-base-bold);
  }

  h1,
  h2,
  h3 {
    color: #51cdb8;
  }
}
