.join-now {
  max-width: 1178px;
  background: url(../../Assets/img/joinNow.png) no-repeat;
  background-size: cover;
  background-position: center;
  aspect-ratio: 1178 / 540;
  border-radius: 16px;
  padding: 77px 100px;
  margin: 0 auto;

  @media (max-width: 991.98px) {
    padding: 38px 50px;
  }

  @media (max-width: 767.98px) {
    padding: 22px 40px;
    display: flex;
    align-items: flex-end;
    background-position: 95%;
    aspect-ratio: 343 / 319;
    h3,
    p,
    .JoinNowBtnWrapper {
      display: none;
    }
  }
}

.join-now-title {
  margin-top: 30px;
  color: var(--white);
  font-family: var(--base-font-family);
  font-size: 42px;
  font-weight: 300;
  margin-bottom: 0;
  span {
    display: block;
    font-weight: 700;
    color: var(--secondary-clr);
  }
}
.join-now-p {
  font-size: 16px;
  font-weight: 300;
  color: var(--white);
  margin-top: 20px;
  max-width: 325px;
  margin-bottom: 0;
}
.join-now-mobile {
  display: none;

  @media (max-width: 767.98px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    .join-now-title,
    .join-now-p {
      color: var(--primary-clr);
      text-align: center;
    }

    .join-now-title {
      font-size: 34px;
    }
  }
}
